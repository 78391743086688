import { ThunksFabric } from "data/entities/ThunksFabric"
import { transactionSelectors } from "data/entities/transaction/selectors"
import { ParentEnum } from "data/entities/enums"

import { entityName } from "../consts"

export const transactionThunks = new ThunksFabric({
  entityName: entityName.transactions,
  selectors: transactionSelectors,
  parent: ParentEnum.Subscription,
}).build()
