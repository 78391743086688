import React from "react"
import { Link as RouterLink } from "react-router-dom"
import styled from "styled-components"

export const StyledLink = styled(RouterLink)`
  margin: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.primary};

  &:hover {
    text-decoration: underline;
  }
`

export const Link = React.forwardRef((props, ref) => {
  return <StyledLink {...props} ref={ref} />
})
