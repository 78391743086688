import React from "react"
import { useContextSelector } from "use-context-selector"

import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"

import { numFormat } from "view/helpers"

import * as S from "./styled"

export const MauWidget = ({ value = 0 }) => {
  const plan = useContextSelector(BillingContext, billingSelectors.currenPlan)

  return (
    <S.Wrapper>
      <S.Title>Monthly Active Users</S.Title>
      <S.Value>
        {value} <span>/ {numFormat(plan?.limit?.mau)}</span>
      </S.Value>
    </S.Wrapper>
  )
}
