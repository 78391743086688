import React from "react"
import { useDispatch } from "react-redux"

import { ListItemIcon, MenuItem, Popover } from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined"
import LinkIcon from "@material-ui/icons/Link"

import { appHistory } from "infrastructure/application/history"

import { guideThunks } from "data/entities/guide/thunks"

import { useOpenable } from "view/hooks/application/useOpenable"
import { IconButton } from "view/components/application/UserInfo/styled"
import { SvgIcon } from "view/uikit/base/SvgIcon"
import { paths } from "view/router/paths"

export const SubMenu = ({ onDelete }) => {
  const dispatch = useDispatch()

  const { open, onClose, onOpen, anchorEl } = useOpenable(false)

  const handlerClone = async (e, id) => {
    e.stopPropagation()
    await dispatch(guideThunks.clone(id))
  }

  const handlerDelete = (e) => {
    e.stopPropagation()

    onClose()
    onDelete(e)
  }

  const handlerChangeUrl = (e) => {
    e.stopPropagation()

    appHistory.push(paths.settings())
  }

  return (
    <>
      <IconButton onClick={onOpen} size="small">
        <SvgIcon component={MoreVertIcon} />
      </IconButton>
      <Popover open={open} onClose={onClose} anchorEl={anchorEl}>
        {/* <MenuItem onClick={(e) => handlerClone(e, guide._id)}> */}
        {/*  <ListItemIcon> */}
        {/*    <ContentCopyOutlinedIcon fontSize="small" /> */}
        {/*  </ListItemIcon> */}
        {/*  <span>Clone</span> */}
        {/* </MenuItem> */}
        <MenuItem onClick={handlerChangeUrl}>
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <span>Change url</span>
        </MenuItem>
        <MenuItem onClick={handlerDelete}>
          <ListItemIcon>
            <DeleteForeverOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <span>Delete</span>
        </MenuItem>
      </Popover>
    </>
  )
}
