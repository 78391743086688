import { createSlice } from "@reduxjs/toolkit"
import { logout } from "data/entities/identity/thunks"
import { entityName } from "data/entities/consts"
import {
  userCreateByUid,
  userGetByUid,
  userUpdateProfile,
} from "data/entities/user/thunks"

const initialState = {
  init: false,
  isAuth: false,
  uid: null,
  displayName: "",
  email: null,
}

export const identitySlice = createSlice({
  name: entityName.identity,
  initialState,
  reducers: {
    setIdentityInit: (state, action) => {
      state.init = true
    },
  },
  extraReducers: {
    [userCreateByUid.fulfilled]: (state, action) => {
      const { organizations, ...rest } = action.payload
      return {
        ...rest,
        init: true,
        isAuth: true,
      }
    },
    [userGetByUid.fulfilled]: (state, action) => {
      const { organizations, ...rest } = action.payload
      return {
        ...rest,
        init: true,
        isAuth: true,
      }
    },
    [userUpdateProfile.fulfilled]: (state, action) => {
      state.name = action.payload.name
      state.email = action.payload.email
    },
    [logout.fulfilled]: (state, action) => {
      return initialState
    },
  },
})

export const { setIdentityInit } = identitySlice.actions
