import { createReducer } from "@reduxjs/toolkit"

import { initialState } from "data/entities/hotspotGroup/consts"
import { ReducersFabric } from "data/entities/ReducersFabric"
import { logout } from "data/entities/identity/thunks"
import { hotspotThunks } from "data/entities/hotspotGroup/thunks"
import { hotspotGroupActions } from "data/entities/hotspotGroup/actions"

export const hotspotGroupReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: hotspotGroupActions,
    thunks: hotspotThunks,
    initState: initialState,
  }).build()

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
