import React from "react"
import { useSelector } from "react-redux"

import { applicationSelectors } from "data/entities/application/selectors"

import { SvgIcon } from "view/uikit/base/SvgIcon"
import { ReactComponent as CheckAllIcon } from "./assets/check-all.svg"
import { ReactComponent as CachedIcon } from "./assets/cached.svg"
import * as S from "./styled"

export const SaveIndicator = () => {
  const saveIndicator = useSelector(applicationSelectors.saveIndicator)

  const text = saveIndicator ? "Saving" : "Saved"
  const icon = saveIndicator ? (
    <SvgIcon
      component={CachedIcon}
      width={24}
      height={24}
      viewBox="0 0 24 24"
    />
  ) : (
    <SvgIcon
      component={CheckAllIcon}
      width={24}
      height={24}
      viewBox="0 0 17 17"
    />
  )

  return (
    <S.Wrapper saving={saveIndicator}>
      {icon}
      <S.Label>{text}</S.Label>
    </S.Wrapper>
  )
}
