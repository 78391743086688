import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"

import { userFetchList } from "data/entities/user/thunks"
import { adapter } from "data/entities/user/selectors"
import { entityName } from "data/entities/consts"

const initialState = adapter.getInitialState()

export const userSlice = createSlice({
  name: entityName.user,
  initialState,
  reducers: {},
  extraReducers: {
    [userFetchList.fulfilled]: (state, action) => {
      adapter.setAll(state, action)
    },
    [logout.fulfilled]: () => initialState,
  },
})
