import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"
import { organizationSelectors } from "data/entities/organization/selectors"

export const FetchBillingAccountContainer = () => {
  const organization = useSelector(organizationSelectors.current)

  const updateBilling = useContextSelector(
    BillingContext,
    billingSelectors.update
  )

  useEffect(() => {
    if (!organization) return

    // TODO блокировать загрузку всего кабинета пока не получим биллинг аккаунт со статусом
    if (organization?.billingAccount) {
      updateBilling({
        billingAccount: organization.billingAccount,
        subscription: organization?.subscription || null,
      })
    }
  }, [organization])

  return null
}
