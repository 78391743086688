import axios from "axios"
import { pmAuth, pmHandler } from "domain/application/postMessages"

const { REACT_APP_API_URL } = process.env

export const apiService = axios.create({
  baseURL: REACT_APP_API_URL,
})

apiService.getSource = () => {
  return axios.CancelToken.source()
}

apiService.interceptors.request.use(
  async (config) => {
    const token = await sessionStorage.getItem("auth")

    if (token) config.headers.Authorization = `Bearer ${token}`

    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime()

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiService.interceptors.response.use(
  (response) => {
    // console.log(
    //   `Execution time for: ${response.config.url} - ${
    //     new Date().getTime() - response.config.meta.requestStartedAt
    //   } ms`
    // )

    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      return pmHandler(pmAuth, "auth:refreshToken")
        .then(async (token) => {
          await sessionStorage.setItem("auth", token)

          return apiService(originalRequest)
        })
        .catch((e) => {
          return Promise.reject(e)
        })
    }

    return Promise.reject(error)
  }
)
