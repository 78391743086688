import styled from "styled-components"
import { palette } from "view/themes/palette"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Widgets = styled.div`
  display: flex;

  & > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const ColWidgets = styled.div`
  padding: 1rem;
  padding-left: 0;

  & > div {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ColChart = styled.div`
  flex-grow: 1;
`

export const Cell = styled.div`
  width: 30%;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 1rem;
`

export const Row = styled.div`
  display: flex;

  ${Cell} {
    &:last-child {
      padding-right: 0;
    }
  }
`

export const ChartHeader = styled.div`
  margin-bottom: 1rem;
`

export const ChartTitle = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${palette.grey700};
`
export const Values = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TotalPeriod = styled.span`
  font-size: 1.4rem;
  margin-right: 0.5rem;
  font-weight: 500;
`
export const PrevPeriod = styled.span`
  font-size: 1rem;
  color: ${palette.grey500};
`

export const Diff = styled.div`
  font-size: 1.2rem;
  color: ${({ diff }) =>
    diff > 0 ? palette.success : diff < 0 ? palette.error : "black"};
`

export const ChartBody = styled.div`
  height: 300px;
`
