import React from "react"
import { Switch } from "react-router-dom"

import { MainLayout } from "view/components/application/__layouts/MainLayout"
import { AuthLayout } from "view/components/application/__layouts/AuthLayout"

import { paths } from "view/router/paths"
import { Route } from "view/router/Route"

import { LoginPage } from "view/pages/identity/LoginPage"
import { SignupPage } from "view/pages/identity/SignupPage"
import { NotFoundPage } from "view/pages/application/NotFoundPage"
import { ProfilePage } from "view/pages/user/ProfilePage"
import { BillingPage } from "view/pages/billing/BillingPage"
import { UsersPage } from "view/pages/user/UsersPage"
import { ForgotPage } from "view/pages/identity/ForgotPage"
import { SignupCompletePage } from "view/pages/identity/SignupCompletePage"
import { GuideListPage } from "view/pages/entities/GuideListPage"
import { HotspotListPage } from "view/pages/entities/HotspotListPage"
import { ThemeListPage } from "view/pages/entities/ThemeListPage"
import { ThemeItemPage } from "view/pages/entities/ThemeItemPage"
import { ProjectSettingsPage } from "view/pages/project/ProjectSettingsPage"
import { TransactionListPage } from "view/pages/billing/TransactionListPage"
import { DashboardPage } from "view/pages/analytics/DashboardPage"
import { ChecklistListPage } from "view/pages/entities/ChecklistListPage"
import { InvoiceListPage } from "view/pages/billing/InvoiceListPage"

export const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        isPrivate
        path={paths.home()}
        // TODO dashboard
        component={DashboardPage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      {/* <Route */}
      {/*  exact */}
      {/*  path={paths.inviteConfirm()} */}
      {/*  component={InviteConfirmPage} */}
      {/*  layout={AuthLayout} */}
      {/* /> */}
      <Route
        isIdentity
        path={paths.login()}
        component={LoginPage}
        layout={AuthLayout}
      />
      <Route
        isIdentity
        path={paths.signup()}
        component={SignupPage}
        layout={AuthLayout}
      />
      <Route
        isIdentity
        path={paths.forgot()}
        component={ForgotPage}
        layout={AuthLayout}
      />
      <Route
        isPrivate
        path={paths.signupComplete()}
        component={SignupCompletePage}
        layout={AuthLayout}
      />
      <Route
        isPrivate
        path={paths.profile()}
        component={ProfilePage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      {/* BILLING */}
      <Route
        isPrivate
        path={paths.billing()}
        component={BillingPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      <Route
        isPrivate
        path={paths.invoices()}
        component={InvoiceListPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      <Route
        isPrivate
        path={paths.transactions()}
        component={TransactionListPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      {/* <Route */}
      {/*  isPrivate */}
      {/*  path={paths.feedback()} */}
      {/*  component={FeedbackPage} */}
      {/*  layoutProps={{ */}
      {/*    withoutAppBar: true, */}
      {/*  }} */}
      {/* /> */}
      <Route
        isPrivate
        path={paths.users()}
        component={UsersPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      {/* <Route */}
      {/*  isPrivate */}
      {/*  path={paths.orgSettings(":id")} */}
      {/*  component={OrgSettingsPage} */}
      {/*  layoutProps={{ */}
      {/*    withoutAppBar: true, */}
      {/*  }} */}
      {/* /> */}
      <Route
        isPrivate
        exact
        path={paths.productTours()}
        component={GuideListPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      <Route
        isPrivate
        exact
        path={paths.hotspots()}
        component={HotspotListPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      <Route
        isPrivate
        exact
        path={paths.checklists()}
        component={ChecklistListPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      {/* <Route */}
      {/*  isPrivate */}
      {/*  exact */}
      {/*  path={paths.segments()} */}
      {/*  component={SegmentListPage} */}
      {/*  layoutProps={{ */}
      {/*    withoutAppBar: true, */}
      {/*  }} */}
      {/* /> */}

      {/* <Route */}
      {/*  isPrivate */}
      {/*  exact */}
      {/*  path={paths.segmentCreate()} */}
      {/*  component={SegmentCreatePage} */}
      {/*  layoutProps={{ */}
      {/*    withoutAppBar: true, */}
      {/*  }} */}
      {/* /> */}

      <Route
        isPrivate
        exact
        path={paths.themes()}
        component={ThemeListPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        exact
        path={paths.themeItem(":id")}
        component={ThemeItemPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      {/* <Route */}
      {/*  isPrivate */}
      {/*  exact */}
      {/*  path={paths.projects()} */}
      {/*  component={ProjectListPage} */}
      {/*  layoutProps={{ */}
      {/*    withoutAppBar: true, */}
      {/*  }} */}
      {/* /> */}

      <Route
        isPrivate
        exact
        path={paths.settings()}
        component={ProjectSettingsPage}
        layoutProps={{
          withoutAppBar: true,
        }}
      />
      <Route component={NotFoundPage} />
    </Switch>
  )
}
