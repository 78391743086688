import React from "react"
import { useDispatch, useSelector } from "react-redux"

import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined"
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined"
import Popover from "@material-ui/core/Popover"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"

import { projectSelectors } from "data/entities/project/selectors"

import { useOpenable } from "view/hooks/application/useOpenable"
import { ProjectCreateModal } from "view/modals/project/ProjectCreateModal"
import { Button } from "view/uikit/base/Button"
import { Avatar } from "view/uikit/common/Avatar"
import { setCurrentProject } from "data/entities/project/thunks"
import * as S from "./styled"

export const ProjectSelector = () => {
  const dispatch = useDispatch()

  const { anchorEl, open, onOpen, onClose } = useOpenable()

  const projects = useSelector(projectSelectors.selectAll)
  const currentProject = useSelector(projectSelectors.currentFull)

  const modal = useOpenable(false)

  const changeCurrentProject = (e, id) => {
    dispatch(setCurrentProject(id))
    onClose()
  }

  const handlerNewProject = (e) => {
    modal.onOpen(e)
    onClose()
  }

  // const getUrl = (url) => {
  //   if (!url) return ""
  //
  //   return url // .replace("https://", "")
  // }

  return (
    <>
      <S.Wrapper onClick={onOpen}>
        <Avatar name={currentProject?.name || ""} />

        <S.TopProfile>
          <S.Name>
            <S.UserName>{currentProject?.name || ""}</S.UserName>
            {/* <S.Domain>{getUrl(currentProject?.url)}</S.Domain> */}
          </S.Name>

          <S.IconButton>
            {open ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}
          </S.IconButton>
        </S.TopProfile>
      </S.Wrapper>

      <Popover
        id="project-selector"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* <S.SubTitle> */}
        {/*  <Avatar name="My Project" /> */}
        {/*  <S.Text>My Project</S.Text> */}
        {/* </S.SubTitle> */}

        <S.GroupLink>
          {projects.map((p) => (
            <S.MenuItem
              key={p._id}
              onClick={(e) => changeCurrentProject(e, p._id)}
            >
              {p.name}
            </S.MenuItem>
          ))}
          <S.MenuItemButton onClick={handlerNewProject}>
            <Button startIcon={<AddOutlinedIcon />} variant="text">
              Add new projects
            </Button>
          </S.MenuItemButton>
        </S.GroupLink>
      </Popover>

      <ProjectCreateModal {...modal} />
    </>
  )
}
