import { createReducer } from "@reduxjs/toolkit"

import { initialState } from "data/entities/billing/consts"
import {
  billingFetchPlanList,
  refetchSubscription,
  updateSubscription,
} from "data/entities/billing/thunks"
import { userCreateByUid, userGetByUid } from "data/entities/user/thunks"
import { logout } from "data/entities/identity/thunks"
import {
  setUpdatingSubscription,
  unsetSubscription,
} from "data/entities/billing/actions"

export const billingReducer = createReducer(initialState, (builder) => {
  builder.addCase(unsetSubscription.type, (state, action) => {
    state.plan = null
    state.subscription.paddle = null
  })

  builder.addCase(setUpdatingSubscription.type, (state, action) => {
    state.isUpdatingSubscription = true
  })

  builder.addCase(billingFetchPlanList.fulfilled, (state, action) => {
    state.plans = action.payload
  })

  builder.addCase(updateSubscription.pending, (state, action) => {
    state.isUpdatingSubscription = true
  })

  builder.addCase(updateSubscription.fulfilled, (state, action) => {
    state.isUpdatingSubscription = false
    state.plan = action.payload.plan
    state.subscription = action.payload.subscription
  })

  builder.addCase(updateSubscription.rejected, (state, action) => {
    state.isUpdatingSubscription = false
  })

  builder.addCase(userCreateByUid.fulfilled, (state, action) => {
    state.plan = action.payload.plan
    state.subscription = action.payload.subscription
  })

  builder.addCase(refetchSubscription.fulfilled, (state, action) => {
    state.plan = action.payload.plan
    state.subscription = action.payload.subscription
    state.isUpdatingSubscription = false
  })

  builder.addCase(refetchSubscription.rejected, (state, action) => {
    state.isUpdatingSubscription = false
  })

  builder.addCase(userGetByUid.fulfilled, (state, action) => {
    state.plan = action.payload.plan
    state.subscription = action.payload.subscription
  })

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
