import isEmail from "validator/es/lib/isEmail"

export const validate = ({ email, password }) => {
  const errors = {}

  if (!email) {
    errors.email = "Email required!"
  } else if (!isEmail(email)) {
    errors.email = "This email is invalid!"
  }

  if (!password) {
    errors.password = "Password required!"
  } else if (password.length < 8)
    errors.password = "Password must be 8 characters or longer!"

  return errors
}
