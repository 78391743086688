import styled from "styled-components"

export const SubTitle = styled("div")`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`

export const Submenu = styled("div")`
  a {
    margin-right: 0.5rem;
  }
`
