import { createEntityAdapter } from "@reduxjs/toolkit"
import { entityName } from "data/entities/consts"
import { denormalize } from "normalizr"
import { getEntities, guideEntity, organizationEntity } from "data/schema"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity._id,
})

const entity = entityName.organizations

export const organizationSelectors = {
  ...adapter.getSelectors((state) => state[entity]),

  // TODO сделать как везде возвращать только ИД
  // current: (state) => state[entity].current,

  current: (state) => {
    return denormalize(
      state[entity].current,
      organizationEntity,
      getEntities(state)
    )
  },
}
