import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"
import { entityName } from "data/entities/consts"
import { ThunksFabric } from "data/entities/ThunksFabric"
import { invoiceSelectors } from "data/entities/invoice/selectors"
import { ParentEnum } from "data/entities/enums"
import { organizationSelectors } from "data/entities/organization/selectors"

export const organizationThunks = new ThunksFabric({
  entityName: entityName.organizations,
  selectors: organizationSelectors,
  parent: null,
}).build()

export const fetchOrganization = createAsyncThunk(
  `${entityName.organizations}/fetch`,
  async (id, { rejectWithValue }) => {
    try {
      const result = await apiService.get(`/organizations/${id}`)

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const organizationUpdate = createAsyncThunk(
  `${entityName.projects}/update`,
  async ({ name, orgId }, { rejectWithValue }) => {
    try {
      const result = await apiService.put(`/organizations/${orgId}`, {
        name,
      })

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
