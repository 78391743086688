import React, { memo, useEffect, useRef, useState } from "react"

import { TextField } from "view/uikit/base/TextField"
import * as S from "./styled"

export const InputEOP = memo(
  ({
    label,
    value,
    onChange,
    onSave,
    autoFocus = false,
    rows = 1,
    fullWidth = true,
    onlyDigit = false,
    ...rest
  }) => {
    const inputRef = useRef()

    const [currentValue, setCurrentValue] = useState(value)
    const [timer, setTimer] = useState()

    useEffect(() => {
      setCurrentValue(value)
    }, [value])

    const handleChange = async (e) => {
      const reg = /^-?\d*\.?\d*$/
      const newValue =
        // onlyDigit
        // ? reg.test(e.target.value)
        // : // Number(e.target.value)
        e.target.value

      if (onlyDigit && Number.isNaN(newValue)) return
      // if (newValue === value) return

      setCurrentValue(newValue)

      if (onChange) onChange(newValue)

      if (timer) clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          if (onSave) onSave(newValue)
        }, 300)
      )
    }

    // useEffect(() => {
    //   if (!currentValue) return
    //   if (!onlyDigit) return
    //
    //   // для автофокуса при редактировании, чтобы фокус не сбрасывался
    //   if (inputRef.current) {
    //     inputRef.current.selectionStart = currentValue.length
    //     inputRef.current.scrollTop = inputRef.current.scrollHeight
    //   }
    // }, [])

    return (
      <S.Wrapper>
        <TextField
          label={label}
          autoFocus={autoFocus}
          multiline={rows > 1}
          fullWidth={fullWidth}
          rows={rows}
          type={onlyDigit ? "number" : "string"}
          inputRef={inputRef}
          value={currentValue}
          onChange={handleChange}
          {...rest}
        />
      </S.Wrapper>
    )
  }
)
