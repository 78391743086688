import { createEntityAdapter } from "@reduxjs/toolkit"
import { denormalize } from "normalizr"

import { entityName } from "data/entities/consts"
import { getEntities, transactionEntity } from "data/schema"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity._id,
})

const entity = entityName.transactions

export const transactionSelectors = {
  ...adapter.getSelectors((state) => state[entity]),

  selectAll: (state) => {
    return denormalize(
      state[entity].ids,
      [transactionEntity],
      getEntities(state)
    )
  },

  isLoading: (state) => state[entity].isLoading,
  pagging: (state) => state[entity].pagging,
  filter: (state) => state[entity].filter,
  sort: (state) => state[entity].sort,
}
