import { configureStore } from "@reduxjs/toolkit"

import { rootReducer } from "data/rootReducer"

const loggerMiddleware = (storeAPI) => (next) => (action) => {
  // console.log("LOGGER >", action.type, action)

  return next(action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(loggerMiddleware),
})

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("data/rootReducer").default
    store.replaceReducer(newRootReducer)
  })
}
