import { io } from "socket.io-client"

export class WebSocketClient {
  client = null

  constructor(token) {
    this.client = io(process.env.REACT_APP_WS_URL, {
      path: "/ws/socket.io",
      auth: {
        token,
      },
    })

    this.client.on("connect", () => {})

    this.client.on("disconnect", () => {})
  }

  connect() {}

  disconnect() {}

  send(type, msg) {
    this.client.emit(type, msg)
  }

  subscribe(type, callback) {
    this.client.on(type, callback)
  }

  unsubscribe(type) {
    this.client.removeAllListeners(type)
  }
}
