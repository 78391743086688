import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { guideThunks } from "data/entities/guide/thunks"
import { guideSelectors } from "data/entities/guide/selectors"
import { organizationSelectors } from "data/entities/organization/selectors"

import { useNotice } from "view/hooks/application/useNotice"
import { projectSelectors } from "data/entities/project/selectors"

export const GuideRefetchContainer = () => {
  const dispatch = useDispatch()
  const { errorNotice } = useNotice()

  const pid = useSelector(projectSelectors.current)
  const organization = useSelector(organizationSelectors.current)

  const sort = useSelector(guideSelectors.sort)
  const filter = useSelector(guideSelectors.filter)
  const pagging = useSelector(guideSelectors.pagging)

  useEffect(() => {
    if (!organization) return undefined

    const promise = dispatch(guideThunks.fetchList())

    ;(async () => {
      const result = await promise

      if (result.type === guideThunks.fetchList.rejected().type) {
        if (result.error.message !== "Aborted") {
          errorNotice("Server error!")
        }
      }
    })()

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    pid,
    organization?._id,
    sort,
    filter.status,
    filter.search,
    pagging.limit,
    pagging.page,
  ])

  return null
}
