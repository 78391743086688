import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"

export const dataProvider = {
  _combineUrl: (url, params = {}) => {
    return `${process.env.REACT_APP_API_URL}${url}`
  },

  getEntity: (url, params, options) => {
    const urlWithParams = dataProvider._combineUrl(url, params)

    return apiService.get(urlWithParams).then(({ data }) => {
      const value = options?.mapper ? options?.mapper(data) : data

      return {
        data: value || null,
      }
    })
  },

  getList: (url, params, options) => {
    const urlWithParams = dataProvider._combineUrl(url, params)

    return apiService
      .get(urlWithParams)
      .then(({ data }) => {
        const items = options?.mapper
          ? options?.mapper(data?.items)
          : data?.items

        return {
          data: items || [],
          total: data?.total || 0,
        }
      })
      .catch((err) => {
        errorService.send(err)
      })
  },

  delete: (url, data, options) => {
    return apiService.delete(
      `${process.env.REACT_APP_API_URL}/admin/v1/${url}`,
      {
        data,
      }
    )
  },
}
