import { combineReducers } from "@reduxjs/toolkit"

import { applicationReducer } from "data/entities/application/reducers"
import { billingReducer } from "data/entities/billing/reducers"
import { guideReducer } from "data/entities/guide/reducers"
import { identitySlice } from "data/entities/identity"
import { modalSlice } from "data/entities/modal"
import { organizationReducer } from "data/entities/organization/reducers"
import { projectReducer } from "data/entities/project/reducers"
import { segmentReducer } from "data/entities/segment/reducers"
import { themeReducer } from "data/entities/theme/reducers"
import { transactionReducer } from "data/entities/transaction/reducers"
import { userSlice } from "data/entities/user"
import { hotspotGroupReducer } from "data/entities/hotspotGroup/reducers"
import { invoiceReducer } from "data/entities/invoice/reducers"

export const rootReducer = combineReducers({
  application: applicationReducer,
  billing: billingReducer,
  guides: guideReducer,
  hotspotGroups: hotspotGroupReducer,
  identity: identitySlice.reducer,
  invoices: invoiceReducer,
  modal: modalSlice.reducer,
  organizations: organizationReducer,
  projects: projectReducer,
  segments: segmentReducer,
  themes: themeReducer,
  transactions: transactionReducer,
  user: userSlice.reducer,
})
