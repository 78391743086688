import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { organizationSelectors } from "data/entities/organization/selectors"
import { projectThunks } from "data/entities/project/thunks"

import { useNotice } from "view/hooks/application/useNotice"

export const ProjectRefetchContainer = () => {
  const dispatch = useDispatch()
  const { errorNotice } = useNotice()

  const organization = useSelector(organizationSelectors.current)

  useEffect(() => {
    if (!organization) return undefined

    const promise = dispatch(projectThunks.fetchList())

    ;(async () => {
      const result = await promise

      if (result.type === projectThunks.fetchList.rejected().type) {
        if (result.error.message !== "Aborted") {
          errorNotice("Server error!")
        }
      }
    })()

    return () => {
      promise.abort()
    }
  }, [dispatch, organization?._id])

  return null
}
