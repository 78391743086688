import styled from "styled-components"

export const Wrapper = styled.div`
  text-align: center;
  padding: 1rem;
`

export const WrapperIcon = styled.div`
  text-align: center;
  padding: 1rem 0;
`
