import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Chip } from "@material-ui/core"

import { formatDate } from "infrastructure/utils/date"

import { pmLocal, PMTypeEnum } from "domain/application/postMessages"
import { extensionService } from "domain/application/ExtensionService"

import { initFilter } from "data/entities/guide/consts"
import { GuideStatusEnum } from "data/entities/guide/enums"
import { identitySelectors } from "data/entities/identity/selectors"
import { ModalEnum } from "data/entities/modal"
import { transactionSelectors } from "data/entities/transaction/selectors"
import { transactionActions } from "data/entities/transaction/actions"
import { transactionThunks } from "data/entities/transaction/thunks"

import { Loader } from "view/uikit/base/Loader"
import { Button } from "view/uikit/base/Button"
import { SubMenu } from "view/pages/entities/GuideListPage/GridRows/SubMenu"
import { useOpenable } from "view/hooks/application/useOpenable"
import { ExtensionInstallModal } from "view/modals/application/ExtensionInstallModal"
import { useModal } from "view/modals/useModal"
import { ConfirmModal } from "view/modals/application/ConfirmModal"

import FirstItem from "./first-item.svg"
import NotMatch from "./not-match.svg"
import * as S from "./styled"

export const GridRows = ({ items, isLoading, onCreate }) => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState()
  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  const filter = useSelector(transactionSelectors.filter)
  const { currentProjectUrl } = useSelector(identitySelectors.clientState)

  const modal = useOpenable(false)

  const openInBuilder = async (e, guide) => {
    e.stopPropagation()

    const isInstalled = await extensionService.checkInstall()

    if (!isInstalled) modal.onOpen({ currentTarget: true })

    pmLocal.send(PMTypeEnum.OpenEditor, {
      url: currentProjectUrl,
      guideId: guide._id,
    })
  }

  const clearFilter = async () => {
    await dispatch(transactionActions.clearFilter())
  }

  const handlerDelete = (e, id) => {
    e.stopPropagation()

    setDeleteId(id)
    openConfirm(e)
  }

  const confirmedDelete = async (e) => {
    e.stopPropagation()
    await dispatch(transactionThunks.delete(deleteId))
    setDeleteId()
  }

  if (isLoading)
    return (
      <S.Empty>
        <Loader />
      </S.Empty>
    )

  if (!items.length) {
    if (!filter.search && filter.status === initFilter.status) {
      return (
        <S.Empty>
          <img src={FirstItem} alt="No transactions." />
        </S.Empty>
      )
    }

    return (
      <S.Empty>
        <img src={NotMatch} alt="No transactions match your filters." />
        <div>
          Filter applied, some transactions are hidden.
          <Button variant="text" onClick={clearFilter}>
            Clear filter
          </Button>
        </div>
      </S.Empty>
    )
  }

  return (
    <>
      <S.List>
        {items.map((i) => (
          <S.Item key={i._id}>
            <S.Left>
              <S.Name>
                <span>{i.name}</span>
                <Chip
                  label={i.status}
                  color={
                    i.status === GuideStatusEnum.Published
                      ? "primary"
                      : "default"
                  }
                />
              </S.Name>
              <S.Created>
                <span>Created: {formatDate(i.created)}</span>
                <span>Last updated: {formatDate(i.updated)}</span>
              </S.Created>
            </S.Left>
            <S.Right>
              <S.OpenBuilder
                variant="outlined"
                onClick={(e) => openInBuilder(e, i)}
              >
                Open in builder
              </S.OpenBuilder>

              <SubMenu guide={i} onDelete={(e) => handlerDelete(e, i._id)} />
            </S.Right>
          </S.Item>
        ))}
      </S.List>

      <ExtensionInstallModal {...modal} />

      <ConfirmModal
        title="Confirm guide delete"
        text="Are you sure you want to delete this guide?"
        onAccept={confirmedDelete}
        labelAccept="Delete"
      />
    </>
  )
}
