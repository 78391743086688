import React, { memo, useEffect } from "react"
import { Button, ButtonGroup } from "@material-ui/core"

import {
  PlanGroupEnum,
  PlanTypeEnum,
  SubscriptionPeriodEnum,
} from "domain/billing/enums"

import { StripeSubscribeButton } from "view/pages/billing/BillingPage/PlanList/StripeSubscribeButton"
import { LimitsBlock } from "view/pages/billing/BillingPage/PlanList/LimitsBlock"

import { Loader } from "view/uikit/base/Loader"
import * as S from "./styled"

const displayPeriod = (plan) => {
  if (plan?.oneTime) {
    if (plan.oneTime.duration === -1) return "forever"
  }

  if (plan?.subscription) {
    if (plan.subscription.period === PlanGroupEnum.Monthly) return "month"
    if (plan.subscription.period === PlanGroupEnum.Yearly) return "year"
  }

  return ""
}

const getPrice = (plan) => {
  if (plan?.oneTime) return plan.oneTime.price.amount / 100
  if (plan?.subscription) return plan.subscription.price.amount / 100
  return 0
}

const sortByPrice = (a, b) => {
  const priceA = getPrice(a)
  const priceB = getPrice(b)

  if (priceA < priceB) return -1
  if (priceA > priceB) return 1

  return 0
}

export const PlanList = memo(
  ({ isLoading, showLTD, period, setPeriod, plan, plans }) => {
    useEffect(() => {
      if (!plan) return

      const getInitTabPeriod = (p) => {
        if (plan?.subscription?.period === SubscriptionPeriodEnum.Monthly)
          return PlanGroupEnum.Monthly

        if (plan?.subscription?.period === SubscriptionPeriodEnum.Yearly)
          return PlanGroupEnum.Yearly

        if (plan?.type === PlanTypeEnum.OneTime) return PlanGroupEnum.LTD

        return PlanGroupEnum.Monthly
      }

      setPeriod(getInitTabPeriod(plan))
    }, [plan])

    const filteredPlans = plans.filter((p) => {
      if (period === PlanGroupEnum.LTD) {
        return p?.oneTime?.duration === -1
      }

      return p?.subscription?.period === period
    })

    // if (!filteredPlans.length) return null

    filteredPlans.sort(sortByPrice)

    return (
      <>
        <S.Header>
          <h3>Plan options</h3>
          <ButtonGroup size="small">
            <Button
              variant={
                period === PlanGroupEnum.Monthly ? "contained" : "outlined"
              }
              color={period === PlanGroupEnum.Monthly ? "primary" : "default"}
              onClick={() => setPeriod(PlanGroupEnum.Monthly)}
            >
              Monthly
            </Button>
            <Button
              variant={
                period === PlanGroupEnum.Yearly ? "contained" : "outlined"
              }
              color={period === PlanGroupEnum.Yearly ? "primary" : "default"}
              onClick={() => setPeriod(PlanGroupEnum.Yearly)}
            >
              Yearly
            </Button>
            {showLTD && (
              <Button
                variant={
                  period === PlanGroupEnum.LTD ? "contained" : "outlined"
                }
                color={period === PlanGroupEnum.LTD ? "primary" : "default"}
                onClick={() => setPeriod(PlanGroupEnum.LTD)}
              >
                LTD
              </Button>
            )}
          </ButtonGroup>
        </S.Header>

        {isLoading && (
          <S.LoaderWrapper>
            <Loader />
          </S.LoaderWrapper>
        )}

        {!isLoading && !!filteredPlans.length && (
          <S.PlanWrapper>
            {filteredPlans.map((p) => (
              <S.WrapperPlan key={p.id}>
                <S.Plan current={plan?.id === p.id}>
                  <S.Name>{p.name}</S.Name>

                  <S.Price>
                    <span>${getPrice(p)}</span> / {displayPeriod(p)}
                  </S.Price>

                  <LimitsBlock limits={p.limit} />

                  <S.ButtonWrapper>
                    <StripeSubscribeButton plan={p} currentPlan={plan} />
                  </S.ButtonWrapper>
                </S.Plan>
              </S.WrapperPlan>
            ))}
          </S.PlanWrapper>
        )}
      </>
    )
  }
)
