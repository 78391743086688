import React, { memo } from "react"

import { PageHeader } from "view/uikit/common/PageHeader"
import { PlanList } from "view/pages/billing/BillingPage/PlanList"
import { CurrentPlan } from "view/pages/billing/BillingPage/CurrentPlan"
import { PlanListContainer } from "view/pages/billing/BillingPage/PlanList/container"
import { Link } from "view/uikit/base/Link"
import { paths } from "view/router/paths"

import * as S from "./styled"

export const BillingPage = memo(() => {
  return (
    <>
      <PageHeader
        title="Billing"
        button={
          <S.Submenu>
            {/* <Link to={paths.transactions()}>Transactions</Link> */}
            <Link to={paths.invoices()}>Invoices</Link>
          </S.Submenu>
        }
      />

      <CurrentPlan />

      <br />

      <PlanListContainer Component={PlanList} />
    </>
  )
})
