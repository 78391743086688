import moment from "moment"
import {
  DateFilterEnum,
  DateIntervalEnum,
} from "view/pages/analytics/DashboardPage/enums"

export const formatStartDate = (start) =>
  `${moment(start).format("YYYY-MM-DD")} 00:00:00`

export const formatEndDate = (end) =>
  `${moment(end).format("YYYY-MM-DD")} 23:59:59`

export const getRange = (dateRange, intervalPeriod) => {
  const [start, end] = dateRange
  const [num, interval] = intervalPeriod
  const momentStart = moment(start)
  const momentEnd = moment(end).add(1, "minute")

  const diff = momentEnd.diff(momentStart, interval) / num

  const range = []
  for (let i = 0; i < diff; i++) {
    range.push(
      moment(start)
        .add(num * i, interval)
        .format("YYYY-MM-DD HH:mm:ss")
    )
  }

  return range
}

export const getDiffDays = (dateRange) => {
  const [start, end] = dateRange
  const momentStart = moment(start)
  const momentEnd = moment(end)

  return momentEnd.diff(momentStart, "days")
}

export const getIntervalByRange = (dateRange) => {
  const diff = getDiffDays(dateRange)

  if (diff <= 3) return DateIntervalEnum.Hour
  if (diff > 3 && diff <= 7) return DateIntervalEnum.Day
  if (diff > 7 && diff <= 32) return DateIntervalEnum.Day
  if (diff > 32 && diff <= 100) return DateIntervalEnum.Week
  return DateIntervalEnum.Month
}

export const formatDate = (date) => {
  if (!date) return ""
  return new Date(date).toLocaleDateString("en-EN")
}

export const formatTime = (date) => {
  if (!date) return ""
  return new Date(date).toLocaleTimeString("en-EN")
}

export const humanizeDate = (date) => {
  const delta = Math.round((+new Date() - +new Date(date)) / 1000)

  const minute = 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7

  if (delta < 30) {
    return "just then"
  }
  if (delta < minute) {
    return `${delta} seconds ago`
  }
  if (delta < 2 * minute) {
    return "a minute ago"
  }
  if (delta < hour) {
    return `${Math.floor(delta / minute)} minutes ago`
  }
  if (Math.floor(delta / hour) === 1) {
    return "1 hour ago"
  }
  if (delta < day) {
    return `${Math.floor(delta / hour)} hours ago`
  }
  if (delta < day * 2) {
    return "yesterday"
  }
  if (delta < week) {
    return `${Math.floor(delta / day)} day ago`
  }

  return `${formatDate(date)} ${formatTime(date)}`
}

export const rangeToString = (dateRange) => {
  const [start, end] = dateRange
  const momentStart = moment(start)
  const momentEnd = moment(end)

  const diffDays = momentEnd.diff(momentStart, "days")
  const diffMonth = momentEnd.diff(momentStart, "month")
  const diffYear = momentEnd.diff(momentStart, "year")

  let startFormat = "DD"
  if (diffMonth) startFormat = "DD MMM"
  if (diffYear) startFormat = "DD MMM YYYY"

  if (diffDays === 0) return `${moment(end).format("DD MMM YYYY")}`

  return `${moment(start).format(startFormat)} - ${moment(end).format(
    "DD MMM YYYY"
  )}`
}

export const getDateStartOfMonth = () => {
  return `${moment().startOf("month").format("YYYY-MM-DD")} 00:00:00`
}

export const getDateEndOfMonth = () => {
  return `${moment().endOf("month").format("YYYY-MM-DD")} 23:59:59`
}

export const getDateStartToday = () => {
  return `${moment().format("YYYY-MM-DD")} 00:00:00`
}

export const getDateEndToday = () => {
  return `${moment().format("YYYY-MM-DD")} 23:59:59`
}

export const getRangeByFilter = (filter) => {
  switch (filter) {
    case DateFilterEnum.Today:
      return [
        `${moment().format("YYYY-MM-DD")} 00:00:00`,
        `${moment().format("YYYY-MM-DD")} 23:59:59`,
      ]

    case DateFilterEnum.Yesterday:
      return [
        `${moment().subtract(1, "days").format("YYYY-MM-DD")} 00:00:00`,
        `${moment().subtract(1, "days").format("YYYY-MM-DD")} 23:59:59`,
      ]

    case DateFilterEnum.Week:
      return [
        `${moment().subtract(6, "days").format("YYYY-MM-DD")} 00:00:00`,
        `${moment().format("YYYY-MM-DD")} 23:59:59`,
      ]

    case DateFilterEnum.Month:
      return [
        `${moment().subtract(1, "month").format("YYYY-MM-DD")} 00:00:00`,
        `${moment().format("YYYY-MM-DD")} 23:59:59`,
      ]

    case DateFilterEnum.Quarter:
      return [
        `${moment().subtract(3, "month").format("YYYY-MM-DD")} 00:00:00`,
        `${moment().format("YYYY-MM-DD")} 23:59:59`,
      ]

    case DateFilterEnum.Year:
      return [
        `${moment().subtract(1, "year").format("YYYY-MM-DD")} 00:00:00`,
        `${moment().format("YYYY-MM-DD")} 23:59:59`,
      ]

    default:
      break
  }

  return `${moment().format("YYYY-MM-DD")} 00:00:00`
}

export const getPrevPeriod = (dateRange) => {
  const [start, end] = dateRange

  const diff = getDiffDays(dateRange)

  const newStart = moment(start)
  const newEnd = moment(start)

  return [
    `${newStart.subtract(diff + 1, "days").format("YYYY-MM-DD")} 00:00:00`,
    `${newEnd.subtract(1, "days").format("YYYY-MM-DD")} 23:59:59`,
  ]
}

export const getIntervalPeriod = (interval) => {
  if (interval === DateIntervalEnum.Hours_4) return [4, DateIntervalEnum.Hour]
  if (interval === DateIntervalEnum.Minutes_15)
    return [15, DateIntervalEnum.Minute]

  return [1, interval]
}
