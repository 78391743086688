import React from "react"
import { useContextSelector } from "use-context-selector"

import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined"
import IconButton from "@material-ui/core/IconButton"

import { Button } from "view/uikit/base/Button"
import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"

import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"
import { AccountTypeEnum } from "domain/billing/enums"
import * as S from "./styled"

export const UpgradeButton = ({ open }) => {
  const isTrial = useContextSelector(BillingContext, billingSelectors.isTrial)

  const handlerClick = () => {
    appHistory.push(paths.billing())
  }

  if (!isTrial) return null

  if (!open) {
    return (
      <S.WrapperIcon>
        <IconButton color="primary" onClick={handlerClick}>
          <StarBorderOutlinedIcon />
        </IconButton>
      </S.WrapperIcon>
    )
  }

  return (
    <S.Wrapper>
      <Button
        fullWidth
        variant="outlined"
        size="large"
        startIcon={<StarBorderOutlinedIcon />}
        onClick={handlerClick}
      >
        Upgrade
      </Button>
    </S.Wrapper>
  )
}
