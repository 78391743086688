import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { humanizeDate } from "infrastructure/utils/date"

import { initFilter } from "data/entities/guide/consts"
import { invoiceSelectors } from "data/entities/invoice/selectors"
import { invoiceActions } from "data/entities/invoice/actions"

import { Loader } from "view/uikit/base/Loader"
import { Button } from "view/uikit/base/Button"

import { Link } from "view/uikit/base/Link"
import { CopyButton } from "view/uikit/base/CopyButton"
import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"
import { LinkExt } from "view/uikit/base/LinkExt"
import FirstItem from "./first-item.svg"
import NotMatch from "./not-match.svg"
import * as S from "./styled"

export const GridRows = ({ items, isLoading, onCreate }) => {
  const dispatch = useDispatch()

  const filter = useSelector(invoiceSelectors.filter)

  const clearFilter = async () => {
    await dispatch(invoiceActions.clearFilter())
  }

  if (isLoading)
    return (
      <S.Empty>
        <Loader />
      </S.Empty>
    )

  if (!items.length) {
    if (!filter.search && filter.status === initFilter.status) {
      return (
        <S.Empty>
          <img src={FirstItem} alt="No invoices." />
          <div>
            <S.EmptyText>You don`t have invoices yet.</S.EmptyText>
            <Button
              size="large"
              onClick={() => appHistory.push(paths.billing())}
            >
              Back to billing page
            </Button>
          </div>
        </S.Empty>
      )
    }

    return (
      <S.Empty>
        <img src={NotMatch} alt="No invoices match your filters." />
        <div>
          Filter applied, some invoices are hidden.
          <Button variant="text" onClick={clearFilter}>
            Clear filter
          </Button>
        </div>
      </S.Empty>
    )
  }

  return (
    <>
      <S.Table>
        <S.HeadRow key="head">
          <S.Cell>Date</S.Cell>
          <S.Cell>Amount</S.Cell>
          <S.Cell>Status</S.Cell>
          <S.Cell>Receipt</S.Cell>
          <S.CellId>ID</S.CellId>
        </S.HeadRow>
        {items.map((i) => (
          <S.Row key={i._id}>
            <S.Cell>{humanizeDate(i.created)}</S.Cell>
            <S.Cell>{i.amount}</S.Cell>
            <S.Cell>{i.status}</S.Cell>
            <S.Cell>
              <S.LinkWrapper>
                <LinkExt to={i.receiptPdf} target="_blank">
                  Download
                </LinkExt>
                <LinkExt to={i.receiptWeb} target="_blank">
                  Web
                </LinkExt>
              </S.LinkWrapper>
            </S.Cell>
            <S.Cell>
              <S.Id>
                {i._id} <CopyButton copyText={i._id} />
              </S.Id>
            </S.Cell>
          </S.Row>
        ))}
      </S.Table>
    </>
  )
}
