import { createReducer } from "@reduxjs/toolkit"

import { ReducersFabric } from "data/entities/ReducersFabric"
import { initialState } from "data/entities/segment/consts"
import { segmentActions } from "data/entities/segment/actions"
import { segmentThunks } from "data/entities/segment/thunks"

export const segmentReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: segmentActions,
    thunks: segmentThunks,
    initState: initialState,
  }).build()
})
