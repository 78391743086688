import transitions from "@material-ui/core/styles/transitions"

export const transactionLeaving = (props) =>
  transitions.create(props, {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  })

export const transactionEntering = (props) =>
  transitions.create(props, {
    easing: transitions.easing.sharp,
    duration: transitions.duration.enteringScreen,
  })
