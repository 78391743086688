import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  list: {
    data: [],
    isLoading: false,
    error: null,
  },
}

export const PlanContext = createContext(initState)
export const PlanProvider = createProvider(PlanContext, initState)
