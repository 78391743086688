import { createEntityAdapter } from "@reduxjs/toolkit"

import { denormalize } from "normalizr"
import { projectEntity, getEntities } from "data/schema"
import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({ selectId: (entity) => entity._id })

const entity = entityName.projects

export const projectSelectors = {
  ...adapter.getSelectors((state) => state[entity]),
  selectAll: (state) => {
    return denormalize(state[entity].ids, [projectEntity], getEntities(state))
  },
  current: (state) => {
    return state[entity].current
  },
  currentFull: (state) => {
    return denormalize(state[entity].current, projectEntity, getEntities(state))
  },
  isLoading: (state) => state[entity].isLoading,
  pagging: (state) => state[entity].pagging,
  filter: (state) => state[entity].filter,
  sort: (state) => state[entity].sort,
}
