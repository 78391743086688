import React, { useState } from "react"
import { useContextSelector } from "use-context-selector"

import { PlanGroupEnum } from "domain/billing/enums"
import { PlanContext } from "domain/billing/plans/PlanContext"
import { planSelectors } from "domain/billing/plans/planSelectors"
import { FetchListContainer } from "domain/core/FetchListContainer"
import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"

export const PlanListContainer = ({ Component }) => {
  const billingAccount = useContextSelector(
    BillingContext,
    billingSelectors.billingAccount
  )
  const plan = useContextSelector(BillingContext, billingSelectors.currenPlan)
  const { data: plans, isLoading } = useContextSelector(
    PlanContext,
    planSelectors.list
  )

  const [period, setPeriod] = useState(PlanGroupEnum.Monthly)

  return (
    <>
      <FetchListContainer
        url="/billing/plans"
        context={PlanContext}
        selectors={planSelectors}
      />
      <Component
        showLTD={!!billingAccount?.showLtd}
        period={period}
        isLoading={isLoading}
        setPeriod={setPeriod}
        plan={plan}
        plans={plans}
      />
    </>
  )
}
