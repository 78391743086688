import React, { memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { Grid } from "@material-ui/core"

import { projectSelectors } from "data/entities/project/selectors"

import { PageHeader } from "view/uikit/common/PageHeader"
import { Box } from "view/uikit/base/Box"
import { Button } from "view/uikit/base/Button"
import { ProjectSettingForm } from "view/forms/project/ProjectSettingForm"
import { DeleteProject } from "view/pages/project/ProjectSettingsPage/DeleteProject"
import * as S from "./styled"

export const ProjectSettingsPage = memo(() => {
  const pid = useSelector(projectSelectors.current)
  const [copied, setCopied] = useState(false)

  const code = `<!-- mintlike.app pixel -->
<script type="text/javascript" >(function(w,d,t,s){k=d.createElement(t);a=d.getElementsByTagName(t)[0];k.async=1;k.src=s;a.parentNode.insertBefore(k,a);w.MintLike={};w.MintLike.PID="${pid}"})(window, document, "script", "${process.env.REACT_APP_STATS_URL}/pixel.js");</script>
<!-- /mintlike.app pixel -->`

  useEffect(() => {
    if (!copied) return undefined

    const timer = setTimeout(() => {
      setCopied(false)
    }, 1000)

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [copied])

  return (
    <S.Wrapper>
      <PageHeader title="Project settings" />

      <Grid container spacing={4}>
        <Grid item xs={5}>
          <Box title="Container snippet">
            <div>
              <p>
                Copy the following JavaScript and paste it as close to the
                opening &lt;head&gt; tag as possible on every page of your
                website.
              </p>

              <S.Code value={code} onChange={() => {}} disabled rows={7} />
            </div>

            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
              <Button>{copied ? "👍 Copied!" : "Copy to clipboard"}</Button>
            </CopyToClipboard>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box title="Container settings">
            <ProjectSettingForm />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <DeleteProject />
        </Grid>
      </Grid>

      {/* <Button variant="contained" color="primary" size="small"> */}
      {/*  Resend verification email */}
      {/* </Button> */}

      {/* <div>Автоматически определить емайл сервер</div> */}

      {/* <Button variant="contained" color="primary" size="small"> */}
      {/*  Open Gmail */}
      {/* </Button> */}
    </S.Wrapper>
  )
})
