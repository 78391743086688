import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { extensionService } from "domain/application/ExtensionService"
import { setExtensionInstalled } from "data/entities/application/actions"
import { pmLocal, PMTypeEnum } from "domain/application/postMessages"

export const CheckExtensionContainer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    extensionService.checkInstall().then((isInstalled) => {
      if (isInstalled) dispatch(setExtensionInstalled())
    })
  }, [])

  useEffect(() => {
    const handler = () => {
      dispatch(setExtensionInstalled())
    }

    pmLocal.subscribe(PMTypeEnum.ExtensionInstalled, handler)

    return () => {
      pmLocal.unsubscribe(PMTypeEnum.ExtensionInstalled, handler)
    }
  }, [])
  return null
}
