import { createReducer } from "@reduxjs/toolkit"

import { initialState } from "data/entities/organization/consts"
import { logout } from "data/entities/identity/thunks"
import { ReducersFabric } from "data/entities/ReducersFabric"
import { organizationActions } from "data/entities/organization/actions"
import { organizationThunks } from "data/entities/organization/thunks"

export const organizationReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: organizationActions,
    thunks: organizationThunks,
    initState: initialState,
  }).build()

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
