import { useCallback, useState } from "react"

export const useOpenable = (defaultState = null) => {
  const [anchorEl, setAnchorEl] = useState(defaultState)

  const onOpen = useCallback((e) => setAnchorEl(e.currentTarget), [])
  const onClose = useCallback(() => setAnchorEl(null), [])

  return { open: !!anchorEl, anchorEl, onOpen, onClose }
}
