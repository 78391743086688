import { createEntityAdapter } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
})

const entity = entityName.billing

export const billingSelectors = {
  ...adapter.getSelectors((state) => state[entity]),

  plan: (state) => state[entity].plan,
  plans: (state) => state[entity].plans,
  subscription: (state) => state[entity].subscription,
  isUpdatingSubscription: (state) => state[entity].isUpdatingSubscription,
}
