import React from "react"
import { useDispatch } from "react-redux"

import MoreVertIcon from "@material-ui/icons/MoreVert"
import { ListItemIcon, MenuItem, Popover } from "@material-ui/core"
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined"

import { themeThunks } from "data/entities/theme/thunks"

import { useOpenable } from "view/hooks/application/useOpenable"
import { SvgIcon } from "view/uikit/base/SvgIcon"
import { IconButton } from "view/components/application/UserInfo/styled"

export const SubMenu = ({ theme, onDelete }) => {
  const dispatch = useDispatch()

  const { open, onClose, onOpen, anchorEl } = useOpenable(false)

  const handlerClone = async (e, id) => {
    e.stopPropagation()
    await dispatch(themeThunks.clone(id))
  }

  const handlerOpen = (e) => {
    e.stopPropagation()

    onOpen(e)
  }

  const handlerDelete = (e) => {
    e.stopPropagation()

    onClose()
    onDelete(e)
  }

  const handlerClose = (e) => {
    e.stopPropagation()

    onClose(e)
  }

  return (
    <>
      <IconButton onClick={handlerOpen} size="small">
        <SvgIcon component={MoreVertIcon} />
      </IconButton>

      <Popover
        open={open}
        onClose={handlerClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {/* <MenuItem onClick={(e) => handlerClone(e, guide._id)}> */}
        {/*  <ListItemIcon> */}
        {/*    <ContentCopyOutlinedIcon fontSize="small" /> */}
        {/*  </ListItemIcon> */}
        {/*  <span>Clone</span> */}
        {/* </MenuItem> */}
        {theme.type !== "system" && (
          <MenuItem onClick={handlerDelete}>
            <ListItemIcon>
              <DeleteForeverOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <span>Delete</span>
          </MenuItem>
        )}
      </Popover>
    </>
  )
}
