import React from "react"
import { Field } from "react-final-form"

import { FormControl } from "@material-ui/core"

import { InputField } from "view/uikit/formFields/InputField"
import { FormError } from "view/uikit/common/FormError"
import { FormButtons } from "view/uikit/common/FormButtons"

import { Container } from "./container"
import * as S from "./styled"

const Component = ({ handleSubmit, submitError, submitting }) => {
  return (
    <S.Wrapper>
      <S.Typography variant="h6">Personal data</S.Typography>

      <S.Form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <Field component={InputField} name="name" label="Name" />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Field
            component={InputField}
            name="email"
            label="Email"
            disabled
            // InputProps={{
            //   endAdornment: values.email ? (
            //     <S.InputAdornment position="end" $emailVerified={emailVerified}>
            //       {emailVerified ? (
            //         <Tooltip title="Email verified!">
            //           <VerifiedUserOutlinedIcon />
            //         </Tooltip>
            //       ) : (
            //         <Tooltip title="Email not verified!">
            //           <BlockOutlinedIcon />
            //         </Tooltip>
            //       )}
            //     </S.InputAdornment>
            //   ) : null,
            // }}
          />
        </FormControl>

        {submitError && (
          <FormControl fullWidth margin="normal">
            <FormError>{submitError}</FormError>
          </FormControl>
        )}

        <FormButtons
          submitBtn={{
            disabled: submitting,
            title: "Update",
          }}
        />
      </S.Form>
    </S.Wrapper>
  )
}

export const ProfileForm = () => {
  return <Container component={Component} />
}
