import React, { useCallback } from "react"
import Button from "@material-ui/core/Button"
import * as S from "view/uikit/common/FormButtons/styled"

export const FormButtons = ({ cancelBtn, cbCancel, submitBtn, reset }) => {
  const handlerClose = useCallback(
    (e) => {
      if (reset) reset(e)
      if (cbCancel) cbCancel(e)
    },
    [cbCancel, reset]
  )

  return (
    <S.Wrapper>
      {cancelBtn && (
        <Button
          disabled={cancelBtn.disabled}
          color="default"
          variant="outlined"
          onClick={handlerClose}
        >
          {cancelBtn.title}
        </Button>
      )}
      {submitBtn && (
        <Button
          disabled={submitBtn.disabled}
          type="submit"
          color="primary"
          variant="contained"
        >
          {submitBtn.title}
        </Button>
      )}
    </S.Wrapper>
  )
}
