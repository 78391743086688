import React from "react"

import { CheckExtensionContainer } from "domain/application/CheckExtensionContainer"
import { ProjectRefetchContainer } from "domain/project/ProjectRefetchContainer"
import { FetchBillingAccountContainer } from "domain/billing/containers/FetchBillingAccountContainer"
import { WebSocketContainer } from "domain/application/WebSocketContainer"
// import { SetCurrentProjectContainer } from "domain/projects/SetCurerntProjectContainer"

export const ContainersAfterInit = () => (
  <>
    {/* <SetCurrentProjectContainer /> */}
    <CheckExtensionContainer />
    <FetchBillingAccountContainer />
    <ProjectRefetchContainer />
    <WebSocketContainer />
  </>
)
