import { adapter } from "data/entities/project/selectors"
import { SortEnum } from "data/enums/SortEnum"

export const initFilter = {
  search: "",
  status: "",
}

export const initialState = adapter.getInitialState({
  isLoading: false,
  current: null,
  sort: SortEnum.DateCreate,
  filter: initFilter,
  pagging: {
    total: 1,
    page: 1,
    limit: 100,
  },
})
