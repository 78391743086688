import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import { TransactionRefetchContainer } from "domain/billing/containers/TransactionRefetchContainer"

import { transactionSelectors } from "data/entities/transaction/selectors"
import { transactionActions } from "data/entities/transaction/actions"

import { Grid } from "view/uikit/common/Grid"
import { GridFilter } from "view/uikit/common/Grid/GridFilter"
import { Button } from "view/uikit/base/Button"

import { transactionThunks } from "data/entities/transaction/thunks"
import { paths } from "view/router/paths"
import { GridRows } from "./GridRows"

const statusOptions = [
  {
    label: "Any",
    value: "",
  },
  {
    label: "Draft",
    value: 1,
  },
  {
    label: "Published",
    value: 2,
  },
  // {
  //   label: "Archived",
  //   value: GuideStatusEnum.Archived,
  // },
]

export const TransactionListPage = memo(() => {
  const dispatch = useDispatch()

  const filter = useSelector(transactionSelectors.filter)

  const dirtFilter = filter.status || filter.search

  const clearFilter = async () => {
    await dispatch(transactionActions.clearFilter())
  }

  const Filter = (
    <>
      <GridFilter
        name="status"
        label="Status"
        value={statusOptions.find((o) => o.value === filter.status)}
        options={statusOptions}
        setFilter={transactionActions.setFilter}
      />

      {dirtFilter && (
        <Button
          color="secondary"
          variant="text"
          onClick={clearFilter}
          startIcon={<HighlightOffIcon />}
        >
          Clear filters
        </Button>
      )}
    </>
  )

  return (
    <>
      <TransactionRefetchContainer />

      <Grid
        title="Transactions"
        backLink={paths.billing()}
        filter={Filter}
        GridRows={GridRows}
        thunks={transactionThunks}
        selectors={transactionSelectors}
        actions={transactionActions}
      />
    </>
  )
})
