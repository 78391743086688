import styled from "styled-components"
import { palette } from "view/themes/palette"
import { boxShadow } from "view/themes/mixins"

export const Wrapper = styled("div")`
  display: flex;
  margin-bottom: 3rem;

  & > h3 {
    font-weight: normal;
    font-size: 1.4rem;
    margin: 0;
    margin-bottom: 1rem;
  }
`

export const Name = styled("div")`
  font-size: 1.4rem;
  margin-bottom: 2rem;

  & > span {
    font-weight: bold;
  }
`

export const NextBill = styled("div")`
  font-size: 1rem;
  //font-weight: normal;
  color: ${palette.grey500};
  padding-top: 1rem;
  margin-bottom: 1rem;
  //margin-left: 2rem;
`

export const Block = styled("div")`
  display: flex;

  & > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Box = styled("div")`
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #fff;
  ${boxShadow()}
  background-color: ${palette.white};
`

export const Card = styled("div")`
  min-width: 12rem;
  padding: 1rem;
  background-color: ${palette.grey50};
  border-radius: 1rem;
  // border: 1px solid ${palette.grey200};
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`

export const Title = styled("div")`
  color: ${palette.grey500};
  margin-bottom: 1rem;
  font-size: 0.8rem;
`

export const Value = styled("div")`
  text-align: center;
  font-size: 1.8rem;

  span {
    font-size: 1.4rem;
    color: ${palette.grey500};
  }
`

export const Buttons = styled("div")`
  display: flex;
  flex-direction: row;

  button {
    margin-right: 1rem;
  }
`

export const Button = styled("div")`
  flex-grow: 1;
  text-align: right;
`

export const Limits = styled("div")`
  & > div {
    & > span {
      font-size: 1rem;
      color: ${palette.grey500};
    }
  }
`
