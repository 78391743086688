import styled from "styled-components"
import { Button } from "view/uikit/base/Button"

export const Empty = styled.div`
  height: 25rem;
  text-align: center;

  img {
    height: 20rem;
  }
`

export const List = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0 0 1px 0 rgb(15 51 77 / 25%), 0 2px 10px 0 rgb(51 51 79 / 2%);
  background-color: #fff;
  color: #515151;
`

export const Item = styled.div`
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #f2f2ff;
`

export const EmptyText = styled.div`
  margin-bottom: 16px;
`

export const Left = styled.div`
  flex-grow: 1;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled.div`
  font-size: 20px;
  margin-bottom: 16px;

  & > span {
    margin-right: 1rem;
  }
`

export const Status = styled.div``

export const Created = styled.div`
  font-size: 10px;

  & > span:first-child {
    margin-right: 1rem;
  }
`

export const Updated = styled.div`
  font-size: 10px;
`

export const OpenBuilder = styled(Button)`
  margin-right: 1rem;
`
