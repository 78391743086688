import React, { memo } from "react"
import { TablePagination } from "@material-ui/core"

import { useDispatch, useSelector } from "react-redux"

import * as S from "./styled"

export const GridFooter = memo(({ selector, setPage, setLimit, disabled }) => {
  const pagging = useSelector(selector.pagging)

  const dispatch = useDispatch()

  const handleChangePage = async (e, newPage) => {
    await dispatch(setPage(newPage + 1))
  }

  const handleChangeRowsPerPage = async (e) => {
    await dispatch(setLimit(parseInt(e.target.value, 10)))
  }

  return (
    <S.Wrapper>
      <TablePagination
        component="div"
        count={pagging.total}
        page={pagging.page - 1}
        rowsPerPageOptions={disabled ? [pagging.limit] : [10, 25, 50, 100]}
        rowsPerPage={pagging.limit}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </S.Wrapper>
  )
})
