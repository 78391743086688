import { schema, normalize } from "normalizr"
import { entityName } from "./entities/consts"

export const blockEntity = new schema.Entity(entityName.blocks)
export const stepEntity = new schema.Entity(entityName.steps, {
  blocks: [blockEntity],
})
export const guideEntity = new schema.Entity(
  entityName.guides,
  {
    steps: [stepEntity],
  },
  {
    idAttribute: "_id",
  }
)

export const hotspotGroupsEntity = new schema.Entity(
  entityName.hotspotGroups,
  {},
  {
    idAttribute: "_id",
  }
)

export const themeEntity = new schema.Entity(
  entityName.themes,
  {},
  {
    idAttribute: "_id",
  }
)

export const transactionEntity = new schema.Entity(
  entityName.transactions,
  {},
  {
    idAttribute: "_id",
  }
)

export const invoiceEntity = new schema.Entity(
  entityName.invoices,
  {},
  {
    idAttribute: "_id",
  }
)

export const organizationEntity = new schema.Entity(
  entityName.organizations,
  {},
  {
    idAttribute: "_id",
  }
)

export const projectEntity = new schema.Entity(
  entityName.projects,
  {},
  {
    idAttribute: "_id",
  }
)

export const segmentEntity = new schema.Entity(
  entityName.segments,
  {},
  {
    idAttribute: "_id",
  }
)

export const normalizes = {
  [entityName.guides]: (data) => normalize(data, [guideEntity]),
  [entityName.themes]: (data) => normalize(data, [themeEntity]),
  [entityName.hotspotGroups]: (data) => normalize(data, [hotspotGroupsEntity]),
  [entityName.segments]: (data) => normalize(data, [segmentEntity]),
  [entityName.projects]: (data) => normalize(data, [projectEntity]),
  [entityName.transactions]: (data) => normalize(data, [transactionEntity]),
  [entityName.organizations]: (data) => normalize(data, [organizationEntity]),
  [entityName.invoices]: (data) => normalize(data, [invoiceEntity]),
}

export const getEntities = (state) => ({
  [entityName.guides]: state[entityName.guides].entities,
  [entityName.themes]: state[entityName.themes].entities,
  [entityName.hotspotGroups]: state[entityName.hotspotGroups].entities,
  [entityName.segments]: state[entityName.segments].entities,
  [entityName.projects]: state[entityName.projects].entities,
  [entityName.transactions]: state[entityName.transactions].entities,
  [entityName.organizations]: state[entityName.organizations].entities,
  [entityName.invoices]: state[entityName.invoices].entities,
  // [entityName.steps]: state[entityName.steps].entities,
  // [entityName.blocks]: state[entityName.blocks].entities,
})
