import React, { useCallback } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { Button } from "@material-ui/core"

import { ModalEnum } from "data/entities/modal"

import { useModal } from "view/modals/useModal"
import { Transition } from "view/modals/Transition"

import * as S from "view/modals/application/ConfirmModal/styled"

export const ConfirmModal = ({
  type = ModalEnum.Confirm,
  title,
  text,
  onAccept,
  labelAccept = "Agree",
  labelCancel = "Cancel",
}) => {
  const { open, onClose } = useModal(type)

  const handlerAccept = useCallback(
    (e) => {
      onAccept(e)
      onClose()
    },
    [onAccept, onClose]
  )

  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <S.DialogActions>
        <Button
          onClick={onClose}
          size="small"
          color="default"
          variant="outlined"
        >
          {labelCancel}
        </Button>
        <Button
          onClick={handlerAccept}
          color="secondary"
          size="small"
          autoFocus
          variant="contained"
        >
          {labelAccept}
        </Button>
      </S.DialogActions>
    </Dialog>
  )
}
