import styled from "styled-components"
import { boxShadow } from "view/themes/mixins"
import { palette } from "view/themes/palette"

export const Wrapper = styled.div`
  background: #fff;
  padding: ${({ padding }) => `${padding}rem`};
  border-radius: 1rem;
  //box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);
  border: ${({ secondary, theme }) =>
    secondary ? `1px solid ${palette.error}` : "1px solid #fff"};
  ${boxShadow()}
`

export const Title = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: ${({ secondary, theme }) => (secondary ? palette.error : "inherit")};
`
