import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"

import { organizationSelectors } from "data/entities/organization/selectors"
import { normalizes } from "data/schema"
import { ParentEnum } from "data/entities/enums"
import { billingSelectors } from "data/entities/billing/selectors"
import { projectSelectors } from "data/entities/project/selectors"
import { entityName } from "data/entities/consts"

export class ThunksFabric {
  entityName

  selectors

  constructor({
    entityName: name,
    selectors,
    // от чего зависит сущность, по умолчанию от проекта
    parent = ParentEnum.Project,
  }) {
    this.entityName = name
    this.selectors = selectors
    this.parent = parent
  }

  build() {
    return {
      fetchList: this.fetchList(),
      fetchById: this.fetchById(),
      create: this.create(),
      update: this.update(),
      clone: this.clone(),
      delete: this.delete(),
    }
  }

  _getBaseUrl(id = null, state, rejectWithValue) {
    const org = organizationSelectors.current(state)
    const pid = projectSelectors.current(state)
    const subscription = billingSelectors.subscription(state)

    if (this.parent === ParentEnum.Organization && !org?._id) {
      rejectWithValue(new Error("OrgId not found"))
      return null
    }

    if (this.parent === ParentEnum.Project && !pid) {
      rejectWithValue(new Error("PID not found"))
      return null
    }

    let url = `/${this._mapEntityToUrl(this.entityName)}`

    if (id) url += `/${id}`

    if (this.parent === ParentEnum.Project) url += `?pid=${pid}`

    if (this.parent === ParentEnum.Organization) url += `?orgId=${org?._id}`

    if (this.parent === ParentEnum.Subscription)
      url += `?subscriptionId=${subscription?._id}`

    return url
  }

  _mapEntityToUrl(entity) {
    switch (entity) {
      case entityName.invoices:
        return "billing/invoices"
      case entityName.hotspotGroups:
        return "hotspot-groups"
      default:
        return entity
    }
  }

  fetchList() {
    return createAsyncThunk(
      `${this.entityName}/fetchList`,
      async (__, { getState, rejectWithValue, signal }) => {
        try {
          const state = getState()

          let url = this._getBaseUrl(null, state, rejectWithValue)

          const sort = this.selectors.sort(state)
          const filter = this.selectors.filter(state)
          const pagging = this.selectors.pagging(state)

          if (filter?.search) url += `&search=${encodeURI(filter.search)}`
          if (filter?.status) url += `&status=${filter.status}`
          if (pagging.page) url += `&page=${pagging.page}`
          if (pagging.limit) url += `&limit=${pagging.limit}`
          if (sort) url += `&sort=${sort}`

          const source = apiService.getSource()
          signal.addEventListener("abort", source.cancel)

          const result = await apiService.get(url, {
            cancelToken: source.token,
          })

          signal.removeEventListener("abort", source.cancel)

          const { items, ...rest } = result.data
          let itemsResult = []

          if (items.length) {
            const normalized = normalizes[this.entityName](items)
            itemsResult = normalized.entities[this.entityName] || []
          }

          return {
            items: itemsResult,
            ...rest,
          }
        } catch (e) {
          errorService.send(e)
          return rejectWithValue(e)
        }
      }
    )
  }

  fetchById() {
    return createAsyncThunk(
      `${this.entityName}/fetchById`,
      async (id, { getState, rejectWithValue, signal }) => {
        try {
          const state = getState()

          const url = this._getBaseUrl(id, state, rejectWithValue)

          const source = apiService.getSource()
          signal.addEventListener("abort", source.cancel)

          const result = await apiService.get(url, {
            cancelToken: source.token,
          })

          signal.removeEventListener("abort", source.cancel)

          return result.data
        } catch (e) {
          errorService.send(e)
          return rejectWithValue(e)
        }
      }
    )
  }

  create() {
    return createAsyncThunk(
      `${this.entityName}/create`,
      async (values, { getState, rejectWithValue }) => {
        try {
          const state = getState()

          const url = this._getBaseUrl(null, state, rejectWithValue)

          const result = await apiService.post(url, {
            ...values,
          })

          return result.data
        } catch (e) {
          errorService.send(e)
          return rejectWithValue(e)
        }
      }
    )
  }

  update() {
    return createAsyncThunk(
      `${this.entityName}/update`,
      async ({ id, values }, { getState, rejectWithValue }) => {
        try {
          const state = getState()

          const url = this._getBaseUrl(id, state, rejectWithValue)

          const result = await apiService.put(url, {
            ...values,
          })

          return result.data
        } catch (e) {
          errorService.send(e)
          return rejectWithValue(e)
        }
      }
    )
  }

  clone() {
    // return createAsyncThunk(
    //   `${this.entityName}/clone`,
    //   async (flowId, { rejectWithValue }) => {
    //     try {
    //       // let guides = []
    //       // const flowsStr = await storageService.getItem("guides")
    //       // if (flowsStr) {
    //       //   guides = JSON.parse(flowsStr)
    //       // }
    //       //
    //       // const flow = guides.find((f) => f.id === flowId)
    //       //
    //       // if (!flow) return {}
    //       //
    //       // const newFlow = {
    //       //   ...flow,
    //       //   id: Math.floor(Math.random() * 100000),
    //       // }
    //       //
    //       // const result = [...guides, newFlow]
    //       //
    //       // await storageService.setItem("guides", JSON.stringify(result))
    //       //
    //       // // для стора денормализуем
    //       // return {
    //       //   ...newFlow,
    //       //   steps: flow.steps.map((s) => s.id),
    //       // }
    //     } catch (e) {
    //       errorService.send(e)
    //       return rejectWithValue(e)
    //     }
    //   }
    // )
  }

  delete() {
    return createAsyncThunk(
      `${this.entityName}/delete`,
      async (id, { rejectWithValue, getState }) => {
        try {
          const state = getState()

          const url = this._getBaseUrl(id, state, rejectWithValue)

          await apiService.delete(url)

          return { id }
        } catch (e) {
          errorService.send(e)
          return rejectWithValue(e)
        }
      }
    )
  }
}
