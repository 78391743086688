import { palette } from "view/themes/palette"
import { lightTheme } from "../light"

export const darkTheme = {
  ...lightTheme,
  primary: palette.main,
  mainBg: palette.darkLight,
  button: {
    bg: palette.main,
    color: palette.white,
    shadowColor: palette.gray,

    contained: {
      disabled: {
        color: palette.gray,
      },
    },
  },
  saveIndicator: {
    color: palette.grey400,
    activeColor: palette.main,
  },
}
