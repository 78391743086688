import React from "react"
import { useSelector } from "react-redux"

import { appHistory } from "infrastructure/application/history"

import { identitySelectors } from "data/entities/identity/selectors"

import { SignupCompleteForm } from "view/forms/identity/SignupCompleteForm"
import { paths } from "view/router/paths"

export const SignupCompletePage = () => {
  return <SignupCompleteForm />
}
