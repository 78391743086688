import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { closeModal, openModal } from "data/entities/modal"

export const useModal = (modalEnum, initProps = null) => {
  const dispatch = useDispatch()

  const openedModal = useSelector((state) => state.modal)

  const onOpen = useCallback(
    (e, props) =>
      dispatch(
        openModal(modalEnum, {
          ...initProps,
          ...props,
        })
      ),
    [dispatch, modalEnum, initProps]
  )
  const onClose = useCallback(() => dispatch(closeModal()), [dispatch])

  return {
    open: openedModal.name === modalEnum,
    onOpen,
    onClose,
    openedModal,
    props: openedModal.props || {},
  }
}
