import { createAction } from "@reduxjs/toolkit"

export class ActionsFabric {
  entityName

  selectors

  constructor({ entityName }) {
    this.entityName = entityName
  }

  build() {
    return {
      setLimit: createAction(`${this.entityName}/setLimit`),
      setFilter: createAction(`${this.entityName}/setFilter`),
      clearFilter: createAction(`${this.entityName}/clearFilter`),
      setPage: createAction(`${this.entityName}/setPage`),
      setSort: createAction(`${this.entityName}/setSort`),
    }
  }
}
