import styled from "styled-components"
import { Typography as MuiTypography } from "@material-ui/core"
import { palette } from "view/themes/palette"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  & > svg {
    cursor: pointer;
    margin-right: 1rem;
    fill: ${palette.grey500};

    &:hover {
      fill: ${palette.grey900};
    }
  }
`

export const Typography = styled(MuiTypography)`
  margin-right: 2rem;
`
