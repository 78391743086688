import React from "react"

import * as S from "./styled"

export const SocialButtons = () => {
  return (
    <S.Wrapper>
      <S.IFrame
        height="45px"
        src={`${process.env.REACT_APP_HOST_AUTH}/social/light`}
      />
    </S.Wrapper>
  )
}
