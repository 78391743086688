export const DateFilterEnum = {
  Today: "today",
  Yesterday: "yesterday",
  Week: "week",
  Month: "month",
  Quarter: "quarter",
  Year: "year",
}

export const DateIntervalEnum = {
  Minute: "minute",
  Minutes_15: "minutes_15",
  Hour: "hour",
  Hours_4: "hours_4",
  Day: "day",
  Week: "week",
  Month: "month",
  Quarter: "quarter",
  Year: "year",
}
