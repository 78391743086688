import React from "react"
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"

import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown"

import { useOpenable } from "view/hooks/application/useOpenable"
import * as S from "./styled"

export const Accordion = ({ title, children }) => {
  const { open, onOpen, onClose } = useOpenable(false)

  // return (
  //   <MuiAccordion TransitionProps={{ unmountOnExit: true }}>
  //     <AccordionSummary
  //       expandIcon={<ChevronDownIcon />}
  //       // aria-controls="panel1a-content"
  //       // id="panel1a-header"
  //     >
  //       {title}
  //     </AccordionSummary>
  //     <AccordionDetails>{children}</AccordionDetails>
  //   </MuiAccordion>
  // )

  return (
    <S.Wrapper open={open}>
      <S.Title onClick={(e) => (open ? onClose() : onOpen(e))}>
        <h2>{title}</h2>
        <S.Icon>
          <ChevronDownIcon />
        </S.Icon>
      </S.Title>
      {open && <S.Content>{children}</S.Content>}
    </S.Wrapper>
  )
}
