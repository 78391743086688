import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Route as ReactRoute, Redirect } from "react-router-dom"

import { identitySelectors } from "data/entities/identity/selectors"
import { paths } from "view/router/paths"
import { MainLayout } from "view/components/application/__layouts/MainLayout"
import { ErrorBoundary } from "view/ErrorBoundary"

export const Route = ({
  component: Component,
  layout = MainLayout,
  layoutProps = {},
  path,
  exact = false,
  isPrivate = false,
  isIdentity = false,
}) => {
  const { isAuth, signupCompleted } = useSelector(identitySelectors.current)

  if (isIdentity && isAuth) return <Redirect to={paths.home()} />
  if (isPrivate && !isAuth) return <Redirect to={paths.login()} />

  // все приватные урлы кроме signupComplete редирекстим на 2ой шаг
  if (isPrivate && !signupCompleted && path !== paths.signupComplete())
    return <Redirect to={paths.signupComplete()} />

  // если открываем signupComplete, но уже прошли 2ой шаг
  if (path === paths.signupComplete() && signupCompleted)
    return <Redirect to={paths.home()} />

  const Layout = layout || Fragment

  return (
    <ReactRoute path={path} exact={exact}>
      <Layout {...layoutProps}>
        <ErrorBoundary>
          <Component />
        </ErrorBoundary>
      </Layout>
    </ReactRoute>
  )
}
