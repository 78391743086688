import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

import { Transition } from "view/modals/Transition"
import { GuideCreateForm } from "view/forms/guide/GuideCreateForm"

export const GuideCreateModal = ({ open, onClose }) => {
  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>Create product tour</DialogTitle>
      <DialogContent>
        <GuideCreateForm cbCancel={onClose} />
      </DialogContent>
    </Dialog>
  )
}
