import { adapter } from "data/entities/guide/selectors"
import { SortEnum } from "data/enums/SortEnum"

export const initFilter = {
  search: "",
  status: "",
}

export const initialState = adapter.getInitialState({
  isLoading: false,
  sort: SortEnum.DateUpdate,
  filter: initFilter,
  pagging: {
    total: 1,
    page: 1,
    limit: 10,
  },
})
