export const palette = {
  white: "#fff",
  gray: "#C4C4C4",
  grayLight: "#F8F7F7",
  dark: "#101012",
  darkLight: "#1C1C1E",
  main: "#08C5A3", // "#523ddb",
  success: "green",
  error: "#f50057",
  action: "#1CC8EE",

  sidebar: "#20262b",

  chart1: "rgba(8, 197, 163, 0.7)",
  chart1_border: "#08C5A3",
  chart2: "#AA7AE8",
  chart2_border: "#AA7AE8",
  chart3: "#659EE0",
  chart3_border: "#659EE0",
  chart4: "#FFA532",
  chart4_border: "#FFA532",
  chart5: "rgba(255, 79, 100, 0.7)",
  chart5_border: "#ff4f64",

  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey400: "#bdbdbd",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey800: "#424242",
  grey810: "#39393D",
  grey820: "#363636",
  grey840: "#2F2E31",
  grey900: "#212121",
  grey1000: "#101010",
}
