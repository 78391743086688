import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);
  width: 500px;
  text-align: center;
`

export const Form = styled.form`
  margin-bottom: 2rem;
`

export const Text = styled.div`
  padding: 0.5rem 0;
  font-size: 0.9rem;
  text-align: left;
  color: #adadad;
`
