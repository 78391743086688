import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { organizationSelectors } from "data/entities/organization/selectors"
import { themeThunks } from "data/entities/theme/thunks"
import { themeSelectors } from "data/entities/theme/selectors"
import { useNotice } from "view/hooks/application/useNotice"
import { projectSelectors } from "data/entities/project/selectors"

export const ThemeRefetchContainer = () => {
  const dispatch = useDispatch()

  const { errorNotice } = useNotice()

  const pid = useSelector(projectSelectors.current)
  // const organization = useSelector(organizationSelectors.current)
  const filter = useSelector(themeSelectors.filter)
  const sort = useSelector(themeSelectors.sort)
  const pagging = useSelector(themeSelectors.pagging)

  useEffect(() => {
    const promise = dispatch(themeThunks.fetchList())

    ;(async () => {
      const result = await promise

      if (result.type === themeThunks.fetchList.rejected().type) {
        if (result.error.message !== "Aborted") {
          errorNotice("Server error!")
        }
      }
    })()

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    pid,
    // organization._id,
    sort,
    pagging.limit,
    pagging.page,
    filter.search,
  ])

  return null
}
