import { createTheme } from "@material-ui/core"

import { darkTheme } from "view/themes/dark"
import { ThemeName } from "view/themes/enums"
import { lightTheme } from "view/themes/light"
import { fontFamily } from "view/themes/overrideMui/fontFamily"
import { MuiBadge } from "view/themes/overrideMui/ovverides/MuiBadge"
import { MuiButton } from "view/themes/overrideMui/ovverides/MuiButton"
import { MuiChip } from "view/themes/overrideMui/ovverides/MuiChip"
import { MuiDialogContent } from "view/themes/overrideMui/ovverides/MuiDialogContent"
import { MuiDrawer } from "view/themes/overrideMui/ovverides/MuiDrawer"
import { MuiFilledInput } from "view/themes/overrideMui/ovverides/MuiFilledInput"
import { MuiFormControl } from "view/themes/overrideMui/ovverides/MuiFormControl"
import { MuiFormHelperText } from "view/themes/overrideMui/ovverides/MuiFormHelperText"
import { MuiIconButton } from "view/themes/overrideMui/ovverides/MuiIconButton"
import { MuiInputBase } from "view/themes/overrideMui/ovverides/MuiInputBase"
import { MuiListItemIcon } from "view/themes/overrideMui/ovverides/MuiListItemIcon"
import { MuiMenuItem } from "view/themes/overrideMui/ovverides/MuiMenuItem"
import { MuiPopover } from "view/themes/overrideMui/ovverides/MuiPopover"
import { MuiSvgIcon } from "view/themes/overrideMui/ovverides/MuiSvgIcon"
import { MuiTab } from "view/themes/overrideMui/ovverides/MuiTab"
import { MuiTabs } from "view/themes/overrideMui/ovverides/MuiTabs"
import { MuiTypography } from "view/themes/overrideMui/ovverides/MuiTypography"
import { PrivateTabIndicator } from "view/themes/overrideMui/ovverides/PrivateTabIndicator"

export const getOverriddenMuiTheme = (themeName) => {
  const theme = themeName === ThemeName.Light ? lightTheme : darkTheme

  return createTheme({
    typography: {
      fontFamily,
    },
    palette: {
      background: {
        default: theme.mainBg,
      },
      primary: {
        main: theme.primary,
      },
    },
    overrides: {
      body: {
        fontFamily,
      },
      MuiBadge: MuiBadge(theme),
      MuiButton: MuiButton(theme),
      MuiChip: MuiChip(theme),
      MuiDialogContent: MuiDialogContent(theme),
      MuiDrawer: MuiDrawer(theme),
      MuiFilledInput: MuiFilledInput(theme),
      MuiFormControl: MuiFormControl(theme),
      MuiFormHelperText: MuiFormHelperText(theme),
      MuiIconButton: MuiIconButton(theme),
      MuiInputBase: MuiInputBase(theme),
      MuiListItemIcon: MuiListItemIcon(theme),
      MuiMenuItem: MuiMenuItem(theme),
      MuiPopover: MuiPopover(theme),
      MuiSvgIcon: MuiSvgIcon(theme),
      MuiTab: MuiTab(theme),
      MuiTabs: MuiTabs(theme),
      MuiTypography: MuiTypography(theme),
      PrivateTabIndicator: PrivateTabIndicator(theme),
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    zIndex: {
      mobileStepper: 999900,
      speedDial: 999905,
      appBar: 999910,
      drawer: 999920,
      modal: 999930,
      snackbar: 999940,
      tooltip: 999950,
    },
  })
}
