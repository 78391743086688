import styled from "styled-components"
import { palette } from "view/themes/palette"

export const Wrapper = styled.div`
  min-width: 12rem;
  padding: 1rem;
  background-color: ${palette.grey50};
  border-radius: 1rem;
  // border: 1px solid ${palette.grey200};
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`

export const Title = styled.div`
  color: ${palette.grey500};
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
`

export const Value = styled.div`
  text-align: center;
  font-size: 1.2rem;

  span {
    font-size: 1rem;
    color: ${palette.grey500};
  }
`
