import React from "react"
import { BillingProvider } from "domain/billing/BillingContext"
import { PlanProvider } from "domain/billing/plans/PlanContext"

const providerList = [[BillingProvider], [PlanProvider]]

export const Providers = ({ children }) => {
  return providerList.reduce((acc, item) => {
    const [Provider, props] = item

    if (props) return <Provider {...props}>{acc}</Provider>

    return <Provider>{acc}</Provider>
  }, children)
}
