import React, { useMemo } from "react"

import * as S from "./styled"

export const Avatar = ({ name, email, variant }) => {
  const text = useMemo(() => {
    try {
      if (name) {
        const parts = name.split(" ")

        if (parts.length >= 2) {
          return parts[0][0].toUpperCase() + parts[1][0].toUpperCase()
        }
        return name.slice(0, 2).toUpperCase()
      }
      if (email) return email?.slice(0, 2).toUpperCase()
      return "NA"
    } catch (e) {
      return "NA"
    }
  }, [name, email])

  return <S.Wrapper variant={variant}>{text}</S.Wrapper>
}
