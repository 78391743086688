import React, { useRef, useState } from "react"
import { SketchPicker } from "react-color"
import { Popover } from "@material-ui/core"

import { useOpenable } from "view/hooks/application/useOpenable"
import * as S from "./styled"

const DEFAULT_COLOR = "#777777"

export const ColorEOP = ({ label, color = DEFAULT_COLOR, onSave }) => {
  const [currentColor, setCurrentColor] = useState(color)
  const { open, onOpen, anchorEl, onClose } = useOpenable(false)
  const ref = useRef()

  const changeColor = (c) => {
    setCurrentColor(c.hex)
    if (onSave) onSave(c.hex)
  }

  return (
    <>
      <S.Wrapper onClick={onOpen}>
        <S.Fig color={currentColor} />
        <S.Text>
          {label && <S.Label>{label}</S.Label>}
          <div>{currentColor.toUpperCase()}</div>
        </S.Text>
      </S.Wrapper>
      <Popover
        ref={ref}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        disableEnforceFocus
      >
        <SketchPicker
          disableAlpha
          presetColors={[
            "#FFFFFF",
            "#FFAB91",
            "#FD5A5B",
            "#F06292",
            "#F94141",
            "#F57F17",
            "#F9A825",
            "#FDD835",

            "#9CCC65",
            "#66BB6A",
            "#26A69A",
            "#24C3E4",
            "#317FE3",
            "#1B50E0",
            "#7C7AE1",
            "#BA68C8",

            "#DDDDDD",
            "#CDCFD0",
            "#B1B4B5",
            "#95999B",
            "#797D80",
            "#37474F",
            "#1F2733",
            "#151515",
          ]}
          color={currentColor}
          onChange={changeColor}
          // onChangeComplete={changeColor}
        />
      </Popover>
    </>
  )
}
