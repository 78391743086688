import { createAsyncThunk } from "@reduxjs/toolkit"

import { storageService } from "infrastructure/services/StorageService"
import { delay } from "infrastructure/utils"
import { errorService } from "infrastructure/services/ErrorService"

import { guideSelectors } from "data/entities/guide/selectors"
import { ThunksFabric } from "data/entities/ThunksFabric"
import { entityName } from "../consts"

export const guideThunks = new ThunksFabric({
  entityName: entityName.guides,
  selectors: guideSelectors,
}).build()

export const guideSetProperty = createAsyncThunk(
  `${entityName.guides}/setProperty`,
  async ({ flowId, property, value }, { rejectWithValue }) => {
    try {
      const flowsStr = await storageService.getItem("flows")
      const flows = JSON.parse(flowsStr)

      const result = flows.map((f) => {
        if (f.id !== flowId) return f
        return {
          ...f,
          [property]: value,
        }
      })

      await delay()
      await storageService.setItem("flows", JSON.stringify(result))

      return {
        id: flowId,
        [property]: value,
      }

      // const result = await apiService.post(`/invites`, {
      //   orgId: organization._id,
      //   ...values,
      // })

      // const { _id, ...rest } = result.data
      // return {
      //   id: arr.length + 1,
      //   ...values,
      // }
    } catch (e) {
      errorService.send(e)
      return rejectWithValue(e)
    }
  }
)
