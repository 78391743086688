import { createAsyncThunk } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"
import { pmAuth, pmHandler } from "domain/application/postMessages"

export const signUpWithGoogle = createAsyncThunk(
  `${entityName.identity}/signUpWithGoogle`,
  async (props, { rejectWithValue }) => {
    try {
      return await pmHandler(pmAuth, "auth:loginWithGoogle")
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

// export const signUpWithGitHub = createAsyncThunk(
//   `${entityName.identity}/signUpWithGitHub`,
//   async (props, { rejectWithValue }) => {
//     try {
//       const provider = new firebase.auth.GithubAuthProvider()
//       provider.addScope("email")
//       return await firebase.auth().signInWithPopup(provider)
//     } catch (e) {
//       return rejectWithValue(e)
//     }
//   }
// )
//
// export const signUpWithYahoo = createAsyncThunk(
//   `${entityName.identity}/signUpWithYahoo`,
//   async (props, { rejectWithValue }) => {
//     try {
//       const provider = new firebase.auth.OAuthProvider("yahoo.com")
//       provider.addScope("email")
//       return await firebase.auth().signInWithPopup(provider)
//     } catch (e) {
//       return rejectWithValue(e)
//     }
//   }
// )
//
// export const signUpWithFacebook = createAsyncThunk(
//   `${entityName.identity}/signUpWithFacebook`,
//   async (props, { rejectWithValue }) => {
//     try {
//       const provider = new firebase.auth.FacebookAuthProvider()
//       provider.addScope("email")
//       return await firebase.auth().signInWithPopup(provider)
//     } catch (e) {
//       return rejectWithValue(e)
//     }
//   }
// )

export const logout = createAsyncThunk(
  `${entityName.identity}/logout`,
  async (props, { rejectWithValue }) => {
    try {
      await pmHandler(pmAuth, "auth:logout")
      return null
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
