import isEmail from "validator/es/lib/isEmail"

export const validate = ({ email, name }) => {
  const errors = {}

  if (!name) {
    errors.name = "Name required!"
  }

  if (!email) {
    errors.email = "Email required!"
  } else if (!isEmail(email)) {
    errors.email = "This email is invalid!"
  }

  return errors
}
