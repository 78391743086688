import { createReducer } from "@reduxjs/toolkit"
import {
  leftSidebarClosed,
  leftSidebarOpened,
  setApplicationInit,
  setApplicationLoaded,
  setClientState,
  setExtensionInstalled,
} from "data/entities/application/actions"
import { initialState } from "data/entities/application/consts"
import { themeSetProperty } from "data/entities/theme/thunks"
import { logout } from "data/entities/identity/thunks"

export const applicationReducer = createReducer(initialState, (builder) => {
  builder.addCase(setApplicationLoaded.type, (state, action) => {
    state.isLoaded = true
  })

  builder.addCase(setApplicationInit.type, (state, action) => {
    state.initApp = {
      ...state.initApp,
      ...action.payload,
    }
  })

  builder.addCase(setClientState.type, (state, action) => {
    state.clientState = {
      ...state.clientState,
      ...action.payload,
    }
  })

  builder.addCase(leftSidebarOpened.type, (state) => {
    state.clientState = {
      ...state.clientState,
      leftSidebarOpen: true,
    }
  })

  builder.addCase(setExtensionInstalled.type, (state) => {
    state.extensionInstalled = true
  })

  builder.addCase(leftSidebarClosed.type, (state) => {
    state.clientState = {
      ...state.clientState,
      leftSidebarOpen: false,
    }
  })

  // -----

  builder.addCase(themeSetProperty.pending, (state, action) => {
    state.saveIndicator = true
  })

  builder.addCase(themeSetProperty.fulfilled, (state, action) => {
    state.saveIndicator = false
  })

  builder.addCase(themeSetProperty.rejected, (state, action) => {
    state.saveIndicator = false
  })

  builder.addCase(logout.fulfilled, (state, action) => {
    return { ...initialState, isLoaded: true }
  })

  // builder.addMatcher(
  //   (action) => action.type.endsWith("/pending"),
  //   (state) => {
  //     state.saveIndicator = true
  //   }
  // )
  //
  // builder.addMatcher(
  //   (action) => action.type.endsWith("/rejected"),
  //   (state) => {
  //     state.saveIndicator = false
  //   }
  // )
  //
  // builder.addMatcher(
  //   (action) => action.type.endsWith("/fulfilled"),
  //   (state) => {
  //     state.saveIndicator = false
  //   }
  // )
})
