import styled from "styled-components"
import { boxShadow } from "view/themes/mixins"

export const Empty = styled.div`
  height: 25rem;
  text-align: center;

  img {
    height: 20rem;
  }
`

export const List = styled.div`
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  ${boxShadow}
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 1rem;
  border-bottom: 1px solid #f2f2ff;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.grid.item.hover.bg};
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled.div`
  font-size: 16px;
  margin-bottom: 16px;

  & > span {
    margin-right: 1rem;
  }

  & > div {
    margin-right: 0.5rem;
  }
`

export const Created = styled.div`
  font-size: 10px;

  & > span:first-child {
    margin-right: 1rem;
  }
`

export const Date = styled.span`
  font-size: 11px;
  margin-right: 16px;
  color: ${({ theme }) => theme.grid.content.date.color};
`
