import React from "react"

import { inFrame } from "infrastructure/utils"

import * as S from "./styled"

export const Loader = ({ color = "#00BA88", size = "small" }) => {
  return (
    <S.Wrapper dark={inFrame()}>
      <S.WrapperCube color={color}>
        <S.Cube1 />
        <S.Cube2 />
        <S.Cube4 />
        <S.Cube3 />
      </S.WrapperCube>
    </S.Wrapper>
  )
}
