import React from "react"
import { Tooltip as MuiTooltip } from "@material-ui/core"

export const Tooltip = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <MuiTooltip {...props} ref={ref}>
      <span>{children}</span>
    </MuiTooltip>
  )
})
