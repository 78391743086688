import styled from "styled-components"
import { palette } from "view/themes/palette"

export const Header = styled.div`
  font-weight: 500;
  //margin-bottom: 0.5rem;
  padding: 1rem;

  span {
    font-weight: normal;
    color: ${palette.grey500};
    font-size: 0.8rem;
  }
`

export const Widgets = styled.div`
  display: flex;

  & > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`
