import React, { memo, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { appHistory } from "infrastructure/application/history"

import { SegmentRefetchContainer } from "domain/segment/SegmentRefetchContainer"

import { segmentSelectors } from "data/entities/segment/selectors"
import { segmentActions } from "data/entities/segment/actions"

import { paths } from "view/router/paths"
import { Grid } from "view/uikit/common/Grid"
import { useOpenable } from "view/hooks/application/useOpenable"
import { PageHeader } from "view/uikit/common/PageHeader"

export const ChecklistListPage = memo(() => {
  return (
    <>
      <PageHeader
        title="Checklists"
        // button={<Link to={paths.transactions()}>View transactions</Link>}
      />
    </>
  )
})
