import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
`

export const Text = styled.div``

export const Fig = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
  border: 1px solid #9f9f9f;
  background-color: ${({ color }) => color};
`

export const Label = styled.div`
  font-size: 0.75rem;
  opacity: 0.54;
`
