import { createAction } from "@reduxjs/toolkit"
import { entityName } from "data/entities/consts"

export const setApplicationLoaded = createAction(
  `${entityName.application}/setApplicationLoaded`
)
export const setApplicationInit = createAction(
  `${entityName.application}/setApplicationInit`
)
export const setExtensionInstalled = createAction(
  `${entityName.application}/setExtensionInstalled`
)
export const setClientState = createAction(
  `${entityName.application}/setClientState`
)
export const leftSidebarOpened = createAction(
  `${entityName.application}/leftSidebarOpened`
)
export const leftSidebarClosed = createAction(
  `${entityName.application}/leftSidebarClosed`
)
