import { createEntityAdapter } from "@reduxjs/toolkit"

import { denormalize } from "normalizr"
import { getEntities, segmentEntity } from "data/schema"
import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({ selectId: (entity) => entity._id })

const entity = entityName.segments

export const segmentSelectors = {
  ...adapter.getSelectors((state) => state[entity]),
  selectAll: (state) => {
    return denormalize(state[entity].ids, [segmentEntity], getEntities(state))
  },
  isLoading: (state) => state[entity].isLoading,
  pagging: (state) => state[entity].pagging,
  sort: (state) => state[entity].sort,
  filter: (state) => state[entity].filter,
}
