import { createReducer } from "@reduxjs/toolkit"

import { initialState } from "data/entities/project/consts"
import { ReducersFabric } from "data/entities/ReducersFabric"
import { logout } from "data/entities/identity/thunks"
import { projectActions } from "data/entities/project/actions"
import { projectThunks, setCurrentProject } from "data/entities/project/thunks"
import { setClientState } from "data/entities/application/actions"
import { themeSetDefault } from "data/entities/theme/thunks"
import { adapter } from "data/entities/guide/selectors"

export const projectReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: projectActions,
    thunks: projectThunks,
    initState: initialState,
  }).build()

  builder.addCase(setClientState.type, (state, action) => {
    state.current = action.payload.currentProject
  })

  builder.addCase(setCurrentProject.fulfilled, (state, action) => {
    state.current = action.payload
  })

  builder.addCase(themeSetDefault.fulfilled, (state, action) => {
    const { pid, themeId } = action.payload

    adapter.updateOne(state, {
      id: pid,
      changes: {
        defaultThemeId: themeId,
      },
    })
  })

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
