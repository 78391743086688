import React, { useCallback, useState } from "react"
import { Form } from "react-final-form"

import { validate } from "view/forms/identity/SignupForm/validate"
import { pmAuth, pmHandler } from "domain/application/postMessages"
import { toFinalFormErrors } from "infrastructure/utils"

const initialValues = {}

export const Container = ({ component: Component }) => {
  const [disabled, setDisabled] = useState(false)

  const handlerSubmit = useCallback(async (values) => {
    try {
      setDisabled(true)
      const result = await pmHandler(pmAuth, "auth:signupWithEmail", {
        email: values.email,
        password: values.password,
      })

      return {}
    } catch (e) {
      setDisabled(false)
      return toFinalFormErrors(e)
    }
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component disabled={disabled} {...props} />}
    />
  )
}
