import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Chip } from "@material-ui/core"
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined"

import { formatDate } from "infrastructure/utils/date"

import { pmLocal, PMTypeEnum } from "domain/application/postMessages"

import { guideSelectors } from "data/entities/guide/selectors"
import { initFilter } from "data/entities/guide/consts"
import { HotspotStatusEnum } from "data/entities/hotspotGroup/enums"
import { guideActions } from "data/entities/guide/actions"
import { ModalEnum } from "data/entities/modal"

import { Loader } from "view/uikit/base/Loader"
import { Button } from "view/uikit/base/Button"
import { SubMenu } from "view/pages/entities/GuideListPage/GridRows/SubMenu"
import { useOpenable } from "view/hooks/application/useOpenable"
import { extensionService } from "domain/application/ExtensionService"
import { ExtensionInstallModal } from "view/modals/application/ExtensionInstallModal"
import { useModal } from "view/modals/useModal"
import { ConfirmModal } from "view/modals/application/ConfirmModal"

import { projectSelectors } from "data/entities/project/selectors"
import { hotspotThunks } from "data/entities/hotspotGroup/thunks"
import { entityName } from "data/entities/consts"
import FirstItem from "./first-item.svg"
import NotMatch from "./not-match.svg"
import * as S from "./styled"

export const GridRows = ({ items, isLoading, onCreate }) => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState()
  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  const filter = useSelector(guideSelectors.filter)
  const project = useSelector(projectSelectors.currentFull)

  const modal = useOpenable(false)

  const openInBuilder = async (e, hotspotGroup) => {
    e.stopPropagation()

    const isInstalled = await extensionService.checkInstall()

    if (!isInstalled) modal.onOpen({ currentTarget: true })

    pmLocal.send(PMTypeEnum.OpenEditor, {
      entity: entityName.hotspotGroups,
      url: project?.url,
      id: hotspotGroup._id,
    })
  }

  const clearFilter = async () => {
    await dispatch(guideActions.clearFilter())
  }

  const handlerDelete = (e, id) => {
    e.stopPropagation()

    setDeleteId(id)
    openConfirm(e)
  }

  const confirmedDelete = async (e) => {
    e.stopPropagation()
    await dispatch(hotspotThunks.delete(deleteId))
    setDeleteId()
  }

  if (isLoading)
    return (
      <S.Empty>
        <Loader />
      </S.Empty>
    )

  if (!items.length) {
    if (!filter.search && filter.status === initFilter.status) {
      return (
        <S.Empty>
          <img src={FirstItem} alt="No hotspots match your filters." />
          <div>
            <S.EmptyText>
              {/* Let`s make your first guide! */}
              First you need to create a hotspot.
              <br />
              {/* Guide is a chain of steps that your user will see. */}
            </S.EmptyText>
            <Button size="large" onClick={onCreate}>
              Create your first hotspot
            </Button>
          </div>
        </S.Empty>
      )
    }

    return (
      <S.Empty>
        <img src={NotMatch} alt="No hotspots match your filters." />
        <div>
          Filter applied, some guides are hidden.
          <Button variant="text" onClick={clearFilter}>
            Clear filter
          </Button>
        </div>
      </S.Empty>
    )
  }

  return (
    <>
      <S.List>
        {items.map((i) => (
          <S.Item key={i._id}>
            <S.Left>
              <S.Name>
                <span>{i.name}</span>
                <Chip
                  label={i.status}
                  color={
                    i.status === HotspotStatusEnum.Published
                      ? "primary"
                      : "default"
                  }
                />
              </S.Name>
              <S.Created>
                <span>Created: {formatDate(i.created)}</span>
                <span>Last updated: {formatDate(i.updated)}</span>
              </S.Created>
            </S.Left>
            <S.Right>
              <S.OpenBuilder
                variant="outlined"
                onClick={(e) => openInBuilder(e, i)}
                endIcon={<LaunchOutlinedIcon />}
              >
                Open in builder
              </S.OpenBuilder>

              <SubMenu guide={i} onDelete={(e) => handlerDelete(e, i._id)} />
            </S.Right>
          </S.Item>
        ))}
      </S.List>

      <ExtensionInstallModal {...modal} />

      <ConfirmModal
        title="Confirm hotspot delete"
        text="Are you sure you want to delete this hotspot?"
        onAccept={confirmedDelete}
        labelAccept="Delete"
      />
    </>
  )
}
