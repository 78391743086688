import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { ModalEnum } from "data/entities/modal"
import { projectThunks, setCurrentProject } from "data/entities/project/thunks"
import { projectSelectors } from "data/entities/project/selectors"

import { Box } from "view/uikit/base/Box"
import { useModal } from "view/modals/useModal"
import { ConfirmModal } from "view/modals/application/ConfirmModal"
import { Button } from "view/uikit/base/Button"

import { paths } from "view/router/paths"
import { appHistory } from "infrastructure/application/history"
import * as S from "./styled"

export const DeleteProject = () => {
  const dispatch = useDispatch()

  const project = useSelector(projectSelectors.currentFull)
  const projects = useSelector(projectSelectors.selectAll)

  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  const isLast = projects.length === 1

  const handlerDelete = (e) => {
    e.stopPropagation()

    openConfirm(e)
  }

  const confirmedDelete = async (e) => {
    e.stopPropagation()
    const result = await dispatch(projectThunks.delete(project._id))

    if (result.type === projectThunks.delete.fulfilled().type) {
      // user = result.payload
      const nextProject = projects.find((p) => p._id !== project._id)

      if (nextProject) dispatch(setCurrentProject(nextProject._id))

      appHistory.push(paths.productTours())
    }
  }

  return (
    <>
      <Box title="Danger zone" secondary>
        {isLast ? (
          <S.Text>You can`t delete the last project!</S.Text>
        ) : (
          <S.Text>
            Once deleted, it will be gone forever. Please be certain.
          </S.Text>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handlerDelete}
          disabled={isLast}
        >
          Delete project
        </Button>
      </Box>

      <ConfirmModal
        title="Are you ABSOLUTELY sure?"
        text={
          <>
            This action <b>CANNOT</b> be undone. This will permanently delete
            the <b>{project?.name}</b> project including all guides, themes and
            all other assets.
          </>
        }
        onAccept={confirmedDelete}
        labelAccept="Delete project"
      />
    </>
  )
}
