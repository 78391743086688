import { palette } from "view/themes/palette"

export const MuiBadge = (theme) => ({
  badge: {
    height: 16,
    fontSize: "0.6rem",
  },
  colorPrimary: {
    color: palette.main,
    backgroundColor: palette.sidebar,
  },
  anchorOriginTopRightRectangle: {
    transform: "scale(1) translate(105%, -10%)",
  },
})
