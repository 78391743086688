import { PMService } from "infrastructure/services/PMService"

export const PMTypeEnum = {
  // app
  SetPID: "setPID",
  OpenEditor: "openEditor",
  CheckExtension: "checkExtension",
  ExtensionInstalled: "extensionInstalled",

  // theme
  ThemeApply: "theme-apply",

  // step
  RenderStep: "render-step",
  // HideAllStep: "hide-all-step",
}

export const authFrame = document.getElementById("mintlike-auth")

export const pmAuth = new PMService(authFrame.contentWindow)
export const pmLocal = new PMService()

export const pmHandler = (pmService, type, payload) => {
  return new Promise((resolve, reject) => {
    pmService.send(type, payload)

    const timer = setTimeout(() => {
      reject(new Error("Timeout"))
      clear()
    }, 30000)

    const resolveHandler = (t, p) => {
      resolve(p)
      clear()
      if (timer) clearTimeout(timer)
    }

    const rejectHandler = (t, p) => {
      reject(p)
      clear()
      if (timer) clearTimeout(timer)
    }

    pmService.subscribe(`${type}:fulfilled`, resolveHandler)
    pmService.subscribe(`${type}:rejected`, rejectHandler)

    const clear = () => {
      pmService.unsubscribe(`${type}:fulfilled`, resolveHandler)
      pmService.unsubscribe(`${type}:rejected`, rejectHandler)
    }
  })
}
