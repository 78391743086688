import React, { memo } from "react"

import { Grid } from "@material-ui/core"

import { PageHeader } from "view/uikit/common/PageHeader"
import { ProfileForm } from "view/forms/user/ProfileForm"
import { ChangePasswordForm } from "view/forms/user/ChangePasswordForm"

const Page = () => {
  return (
    <>
      <PageHeader title="Profile" />

      <Grid container spacing={4}>
        <Grid item xs={4}>
          <ProfileForm />
        </Grid>
        {/* <Grid item xs={4}> */}
        {/*  <ChangePasswordForm /> */}
        {/* </Grid> */}
      </Grid>

      {/* <Button variant="contained" color="primary" size="small"> */}
      {/*  Resend verification email */}
      {/* </Button> */}

      {/* <div>Автоматически определить емайл сервер</div> */}

      {/* <Button variant="contained" color="primary" size="small"> */}
      {/*  Open Gmail */}
      {/* </Button> */}
    </>
  )
}

export const ProfilePage = memo(Page)
