import React from "react"

import { InitContainer } from "domain/application/InitContainer"
import { AuthContainer } from "domain/identity/AuthContainer"
import { useNotice } from "view/hooks/application/useNotice"
import { ActivateAccountContainer } from "domain/billing/containers/ActivateAccountContainer"
// import { LocalizationContainer } from "domain/application/LocalizationContainer"

export const Containers = () => {
  const { errorNotice } = useNotice()

  return (
    <>
      {/* <InitContainer /> */}
      {/* <ResetTokenContainer /> */}
      {/* <LocalizationContainer /> */}
      <ActivateAccountContainer>
        <AuthContainer errorNotice={errorNotice} />
      </ActivateAccountContainer>
    </>
  )
}
