import React from "react"
import { formatLimit } from "view/helpers"

import * as S from "./styled"

export const LimitsBlock = ({ limits }) => {
  return (
    <S.Benefits>
      <S.Benefit key="mau">
        <S.BenefitLabel>MAU</S.BenefitLabel>
        <S.BenefitValue>{formatLimit(limits.mau)}</S.BenefitValue>
      </S.Benefit>
      <S.Benefit key="prjects">
        <S.BenefitLabel>Projects</S.BenefitLabel>
        <S.BenefitValue>{formatLimit(limits.projects)}</S.BenefitValue>
      </S.Benefit>
      <S.Benefit key="product-tour">
        <S.BenefitLabel>Product tours</S.BenefitLabel>
        <S.BenefitValue>{formatLimit(limits.productTours)}</S.BenefitValue>
      </S.Benefit>
      <S.Benefit key="hotspots">
        <S.BenefitLabel>Hotspots</S.BenefitLabel>
        <S.BenefitValue>{formatLimit(limits.hotspots)}</S.BenefitValue>
      </S.Benefit>
      <S.Benefit key="themes">
        <S.BenefitLabel>Themes</S.BenefitLabel>
        <S.BenefitValue>{formatLimit(limits.themes)}</S.BenefitValue>
      </S.Benefit>
      <S.Benefit key="branding">
        <S.BenefitLabel>Remove branding</S.BenefitLabel>
        <S.BenefitValue>{formatLimit(limits.removeBranding)}</S.BenefitValue>
      </S.Benefit>
      <S.Benefit key="support">
        <S.BenefitLabel>Support</S.BenefitLabel>
        <S.BenefitValue>{limits.support}</S.BenefitValue>
      </S.Benefit>
      {/* {benefits.map((b) => ( */}
      {/*  <S.Benefit key={b.label}> */}
      {/*    <S.BenefitLabel>{b.label}</S.BenefitLabel> */}
      {/*    <S.BenefitValue>{b.value}</S.BenefitValue> */}
      {/*  </S.Benefit> */}
      {/* ))} */}
    </S.Benefits>
  )
}
