import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useContextSelector } from "use-context-selector"

import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"

import { getIntervalByRange, getRangeByFilter } from "infrastructure/utils/date"
import {
  DateFilterEnum,
  DateIntervalEnum,
} from "view/pages/analytics/DashboardPage/enums"

import { projectSelectors } from "data/entities/project/selectors"

import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"

import { DateFilter } from "view/pages/analytics/DashboardPage/DateFilter"
import { ProductTourFilter } from "view/pages/analytics/DashboardPage/ProductTourFilter"
import { GroupBy } from "view/pages/analytics/DashboardPage/GroupBy"
import { PageHeader } from "view/uikit/common/PageHeader"
import { SummaryCurrentPeriod } from "view/pages/analytics/DashboardPage/SummaryCurrentPeriod"
import { Chart } from "view/pages/analytics/DashboardPage/Chart"

import * as S from "./styled"

export const DashboardPage = () => {
  const [showDemoData, setShowDemoData] = useState(false)
  const [gid, setGid] = useState("all")
  const [interval, setInterval] = useState(DateIntervalEnum.Day)
  const [presetRange, setPresetRange] = useState(DateFilterEnum.Week)
  const [dateRange, setDateRange] = useState(getRangeByFilter(presetRange))

  const pid = useSelector(projectSelectors.current)
  const isTrial = useContextSelector(BillingContext, billingSelectors.isTrial)

  useEffect(() => {
    if (!presetRange) return

    const dr = getRangeByFilter(presetRange)
    setInterval(getIntervalByRange(dr))
    setDateRange(dr)
  }, [presetRange])

  const handlerDemoData = (e, value) => {
    setShowDemoData(value)
  }

  const showDemoCheckbox = isTrial

  if (!pid) return null

  return (
    <div>
      <PageHeader title="Dashboard" />

      <SummaryCurrentPeriod pid={pid} />

      <S.Filter>
        <S.LeftFilter>
          <DateFilter
            dateRange={dateRange}
            setDateRange={setDateRange}
            presetRange={presetRange}
            setPresetRange={setPresetRange}
            setInterval={setInterval}
          />
          <ProductTourFilter
            pid={pid}
            gid={gid}
            setGid={setGid}
            disabled={showDemoData}
          />
          <GroupBy
            dateRange={dateRange}
            interval={interval}
            setInterval={setInterval}
          />
        </S.LeftFilter>
        <S.RightFilter>
          {showDemoCheckbox && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    value={showDemoData}
                    onChange={handlerDemoData}
                  />
                }
                label="Show demo data"
              />
            </FormGroup>
          )}
        </S.RightFilter>
      </S.Filter>

      <Chart
        gid={gid}
        interval={interval}
        dateRange={dateRange}
        showDemoData={showDemoData}
      />
    </div>
  )
}
