import React, { useCallback, useState } from "react"
import { Form } from "react-final-form"

import { pmAuth, pmHandler } from "domain/application/postMessages"
import { toFinalFormErrors } from "infrastructure/utils"
import { validate } from "./validate"

const initialValues = {}

export const Container = ({ component: Component }) => {
  const [success, setSuccess] = useState(false)

  const handlerSubmit = useCallback(async (values) => {
    try {
      await pmHandler(pmAuth, "auth:resetPassword", {
        email: values.email,
      })
      setSuccess(true)
    } catch (e) {
      return toFinalFormErrors(e)
    }
    return {}
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} success={success} />}
    />
  )
}
