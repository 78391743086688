import { useEffect, useState } from "react"
import { apiService } from "infrastructure/services/ApiService"
import { clearSearchParam } from "infrastructure/utils/url"

export const ActivateAccountContainer = ({ children }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const success = params.get("success")
    const stripeSessionId = params.get("session_id")

    clearSearchParam(["success", "session_id"])

    if (success !== "true") {
      setChecked(true)
      return
    }

    const checkSession = async () => {
      const result = await apiService.post("/billing/stripe/check-session", {
        stripeSessionId,
      })

      return result.data.result
    }

    checkSession().then(() => {
      setChecked(true)
    })
  }, [setChecked])

  return checked ? children : null
}
