import { getRandomInt } from "infrastructure/utils/math"

export const DemoDataBuilder = {
  getGuideChartData(labels) {
    const rangeMau = [20, 100] // in calc period
    const countGuides = 4 // in calc period
    const rangeCompletedRate = [20, 30] // in percent
    const rangeCompletedRateGrowth = [70, 80] // in percent

    const total = {
      started: 0,
      completed: 0,
      dismissed: 0,
      mau: 0,
    }

    const diffGrowthMin =
      (rangeCompletedRateGrowth[0] - rangeCompletedRate[0]) / labels.length
    const diffGrowthMax =
      (rangeCompletedRateGrowth[1] - rangeCompletedRate[1]) / labels.length

    const res = labels.reduce((acc, label, i) => {
      const mau = getRandomInt(rangeMau[0], rangeMau[1])
      const guidePerUser = Math.ceil(Math.random() * countGuides)
      const completedRate =
        getRandomInt(
          rangeCompletedRate[0] + diffGrowthMin * i,
          rangeCompletedRate[1] + diffGrowthMax * i
        ) / 100

      const started = mau * guidePerUser
      const completed = Math.round(started * completedRate)
      const dismissed = started - completed

      total.started += started
      total.completed += completed
      total.dismissed += dismissed
      total.mau += mau

      acc[label] = {
        started,
        completed,
        dismissed,
        mau,
      }

      return acc
    }, {})

    const prevTotal = {
      started: Math.floor((total.started * getRandomInt(0, 70)) / 100),
      completed: Math.floor((total.completed * getRandomInt(0, 70)) / 100),
      mau: Math.floor((total.mau * getRandomInt(0, 70)) / 100),
    }

    prevTotal.dismissed = prevTotal.started - prevTotal.completed

    return [res, total, prevTotal]
  },
}
