import moment from "moment"

import { langOptions } from "data/mocks/langOptions"

export const langNameByCode = (code) => {
  const option = langOptions.find((o) => o.value === code)
  if (!option) return "N/A"
  return option.label
}

export const numFormat = (num, fixed = 2) => {
  if (!num || Number.isNaN(num)) return 0

  return new Intl.NumberFormat("en-EN", {
    style: "decimal",
  }).format(num.toFixed(fixed))
}

export const percentFormat = (num) => {
  return `${numFormat(num, 1)}%`
}

export const dateFormat = (date) => {
  return moment(date).format("DD.MM.YYYY")
}

export const formatLimit = (value) => {
  if (value === -1) return "∞"
  if (value === true) return "Yes"
  if (value === false) return "No"
  return numFormat(value)
}
