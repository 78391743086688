import React from "react"
import moment from "moment"

import {
  getDateEndOfMonth,
  getDateStartOfMonth,
} from "infrastructure/utils/date"
import { useQuery } from "infrastructure/_hooks/useQuery"

import { Box } from "view/uikit/base/Box"
import { MauWidget } from "view/pages/analytics/DashboardPage/SummaryCurrentPeriod/MauWidget"
import { numFormat } from "view/helpers"
import { TopWidget } from "view/pages/analytics/DashboardPage/SummaryCurrentPeriod/TopWidget"
import * as S from "./styled"

export const SummaryCurrentPeriod = ({ pid }) => {
  const params = {
    pid,
    dateStart: getDateStartOfMonth(),
    dateEnd: getDateEndOfMonth(),
  }
  const { data } = useQuery("/analytics/summary", params)

  return (
    <Box padding={0.5}>
      <S.Header>
        {moment(new Date()).format("MMMM")}{" "}
        <span>(Current payment period)</span>
      </S.Header>
      <S.Widgets>
        <MauWidget value={numFormat(data?.mau)} />
        <TopWidget
          label="Product tour started"
          value={numFormat(data?.started)}
        />
        {/* <Widget label="Completion rate" value={rate} /> */}
      </S.Widgets>
    </Box>
  )
}
