import { useEffect } from "react"
import { useDispatch } from "react-redux"

import {
  setApplicationLoaded,
  setClientState,
} from "data/entities/application/actions"

import { userCreateByUid, userGetByUid } from "data/entities/user/thunks"
import { errorService } from "infrastructure/services/ErrorService"
import { authFrame, pmAuth } from "domain/application/postMessages"
import { organizationThunks } from "data/entities/organization/thunks"

export const AuthContainer = ({ errorNotice }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    authFrame.src = process.env.REACT_APP_HOST_AUTH

    pmAuth.subscribe("auth:changed", async (type, fbUser) => {
      try {
        if (!fbUser) {
          await sessionStorage.removeItem("auth")
          dispatch(setApplicationLoaded())
          return
        }

        // setToken
        const token = fbUser.stsTokenManager.accessToken
        if (!token) {
          await sessionStorage.removeItem("auth")
          dispatch(setApplicationLoaded())
          return
        }

        await sessionStorage.setItem("auth", token)

        // проверяем есть ли уже такой юзер на беке
        const result = await dispatch(userGetByUid(fbUser.uid))

        let user

        if (result.type === userGetByUid.fulfilled().type) {
          user = result.payload
        } else {
          if (result.payload.response?.status === 500) {
            if (errorNotice) errorNotice("Server is not available! Try later.")
            dispatch(setApplicationLoaded())
            return
          }

          if (result.payload.response?.status === 404) {
            const createResult = await dispatch(
              userCreateByUid({
                fbUid: fbUser.uid,
                name: fbUser.displayName,
                email: fbUser.email,
              })
            )

            user = createResult.payload
          }
        }

        if (!user) {
          dispatch(setApplicationLoaded())
          if (errorNotice) errorNotice("Server is not available! Try later.")
          return
        }

        if (user?.clientState) dispatch(setClientState(user.clientState))

        const org = await dispatch(
          organizationThunks.fetchById(user?.clientState?.currentOrg)
        )

        dispatch(setApplicationLoaded())

        // if (!user.signupCompleted) {
        //   // gaService.sendEvent("event", "conversion", {
        //   //   send_to: "AW-389992691/iWblCK_395ECEPOh-7kB",
        //   // })
        //   // window.ym(75137779, "reachGoal", "signup_1")
        //
        //   appHistory.push(paths.signupComplete())
        // }

        // TODO проверка на неактивный биллинг аккаунт, сделать по новой схеме
        // if (!user?.plan) {
        //   appHistory.push(paths.billing())
        //   return
        // }
      } catch (e) {
        errorService.send(e)
        dispatch(setApplicationLoaded())
        if (errorNotice) errorNotice("Server is not available! Try later.")
      }
    })
  }, [])

  return null
}
