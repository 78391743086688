import React from "react"
import { useSelector } from "react-redux"

import { applicationSelectors } from "data/entities/application/selectors"

import { Loader } from "view/uikit/base/Loader"

export const InitLoader = ({ children }) => {
  const isLoaded = useSelector(applicationSelectors.isLoaded)

  return !isLoaded ? <Loader /> : children
}
