import { themeSelectors } from "data/entities/theme/selectors"
import { ThunksFabric } from "data/entities/ThunksFabric"

import { createAsyncThunk } from "@reduxjs/toolkit"
import { errorService } from "infrastructure/services/ErrorService"
import { apiService } from "infrastructure/services/ApiService"
import { projectSelectors } from "data/entities/project/selectors"
import { entityName } from "../consts"

export const themeThunks = new ThunksFabric({
  entityName: entityName.themes,
  selectors: themeSelectors,
}).build()

export const themePublish = createAsyncThunk(
  `${entityName.themes}/publish`,
  async ({ themeId }, { rejectWithValue, getState }) => {
    try {
      const state = getState()

      const pid = projectSelectors.current(state)

      if (!pid) return rejectWithValue(new Error("PID not found"))

      const result = await apiService.post(
        `/themes/${themeId}/publish?pid=${pid}`
      )

      return result.data
    } catch (e) {
      errorService.send(e)
      return rejectWithValue(e)
    }
  }
)

export const themeSetDefault = createAsyncThunk(
  `${entityName.themes}/setDefault`,
  async ({ themeId }, { rejectWithValue, getState }) => {
    try {
      const state = getState()

      const pid = projectSelectors.current(state)

      if (!pid) return rejectWithValue(new Error("PID not found"))

      const result = await apiService.post(`/themes/setDefault?pid=${pid}`, {
        themeId,
      })

      return {
        pid,
        themeId,
      }
    } catch (e) {
      errorService.send(e)
      return rejectWithValue(e)
    }
  }
)

export const themeSetProperty = createAsyncThunk(
  `${entityName.themes}/setProperty`,
  async ({ themeId, property, value }, { rejectWithValue, getState }) => {
    try {
      const state = getState()

      const pid = projectSelectors.current(state)

      if (!pid) return rejectWithValue(new Error("PID not found"))

      const result = await apiService.put(`/themes/${themeId}?pid=${pid}`, {
        property,
        value,
      })

      return {
        id: themeId,
        [property]: value,
        themeUpdated: result?.data?.updated,
      }
    } catch (e) {
      errorService.send(e)
      return rejectWithValue(e)
    }
  }
)
