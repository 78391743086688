export const GuidePropertyEnum = {
  Active: "active",
  Audience: "audience",
  PageTargeting: "pageTargeting",
  Steps: "steps",
}

export const GuideStatusEnum = {
  Draft: "draft",
  Published: "published",
  Archived: "archived",
}
