import React, { useState, useCallback } from "react"

export const createProvider = (Context, initState = {}) => {
  return ({ children }) => {
    const [state, setState] = useState(initState)

    const update = useCallback(
      (parts) => {
        setState((innerState) => ({
          ...innerState,
          ...parts,
        }))
      },
      [setState]
    )

    const reset = useCallback(() => {
      setState(initState)
    }, [setState])

    return (
      <Context.Provider
        value={{
          ...state,
          update,
          reset,
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}
