import React from "react"
import { useSelector } from "react-redux"

import AddOutlinedIcon from "@material-ui/icons/AddOutlined"

import { PageHeader } from "view/uikit/common/PageHeader"
import { Button } from "view/uikit/base/Button"
import { billingSelectors } from "data/entities/billing/selectors"
import { GridHeader } from "./GridHeader"
import { GridFooter } from "./GridFooter"

export const Grid = ({
  title,
  backLink = null,
  buttonCreateTitle,
  handlerOpenItem,
  onButtonCreate,
  filter,
  hideSearch = false,
  sortOptions,
  GridRows,
  selectors,
  actions,
}) => {
  const items = useSelector(selectors.selectAll)
  const isLoading = useSelector(selectors.isLoading)
  const subscription = useSelector(billingSelectors.subscription)

  const disabled = !items.length || isLoading
  const disabledButton = subscription?.trialEnded

  const button = buttonCreateTitle ? (
    <Button
      onClick={onButtonCreate}
      disabled={disabledButton}
      startIcon={<AddOutlinedIcon />}
    >
      {buttonCreateTitle}
    </Button>
  ) : null

  return (
    <>
      <PageHeader title={title} button={button} backLink={backLink} />
      <GridHeader
        disabled={disabled}
        actions={actions}
        hideSearch={hideSearch}
        selectors={selectors}
        filter={filter}
        sortOptions={sortOptions}
      />

      <GridRows
        items={items}
        isLoading={isLoading}
        actions={actions}
        selectors={selectors}
        onCreate={onButtonCreate}
        onItemClick={handlerOpenItem}
      />

      {!disabled && (
        <GridFooter
          selector={selectors}
          setPage={actions.setPage}
          setLimit={actions.setLimit}
        />
      )}
    </>
  )
}
