import React, { useState } from "react"

import { Loader } from "view/uikit/base/Loader"
import { Button } from "view/uikit/base/Button"
import { SubMenu } from "view/pages/entities/ThemeListPage/GridRows/SubMenu"

import { useDispatch, useSelector } from "react-redux"
import { themeSelectors } from "data/entities/theme/selectors"
import { formatDate } from "infrastructure/utils/date"
import { Chip } from "@material-ui/core"
import { GuideStatusEnum } from "data/entities/guide/enums"
import { ConfirmModal } from "view/modals/application/ConfirmModal"
import { useModal } from "view/modals/useModal"
import { ModalEnum } from "data/entities/modal"
import { themeThunks } from "data/entities/theme/thunks"
import { projectSelectors } from "data/entities/project/selectors"
import NotMatch from "./not-match.svg"

import * as S from "./styled"

export const GridRows = ({ items, isLoading, onItemClick }) => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState()
  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  const filter = useSelector(themeSelectors.filter)
  const project = useSelector(projectSelectors.currentFull)

  const handlerDelete = (e, id) => {
    e.stopPropagation()

    setDeleteId(id)
    openConfirm(e)
  }

  const confirmedDelete = async (e) => {
    e.stopPropagation()
    await dispatch(themeThunks.delete(deleteId))
    setDeleteId()
  }

  if (isLoading)
    return (
      <S.Empty>
        <Loader />
      </S.Empty>
    )

  if (!items.length && filter.search)
    return (
      <S.Empty>
        <img src={NotMatch} alt="No themes match your filters." />
        <div>
          Filter applied, some themes are hidden.
          <Button variant="text">Clear filter</Button>
        </div>
      </S.Empty>
    )
  //
  // if (!items.length && !filter.search)
  //   return (
  //     <S.Empty>
  //       <img src={NotMatch} alt="No themes match your filters." />
  //       <div>
  //         Create theme
  //         <Button variant="contained">Create first theme</Button>
  //       </div>
  //     </S.Empty>
  //   )

  return (
    <>
      <S.List>
        {items.map((i) => (
          <S.Item key={i._id} onClick={(e) => onItemClick(e, i)}>
            <S.Left>
              <S.Name>
                <span>{i.name}</span>

                {project?.defaultThemeId === i._id && (
                  <Chip label="default" variant="outlined" color="primary" />
                )}

                {i.type === "system" && (
                  <Chip label="system" variant="default" color="default" />
                )}

                {i.type !== "system" && (
                  <Chip
                    label={i.status || "Draft"}
                    variant="default"
                    color={
                      i.status === GuideStatusEnum.Published
                        ? "primary"
                        : "default"
                    }
                  />
                )}
              </S.Name>
              <S.Created>
                <span>Created: {formatDate(i.created)}</span>
                <span>Last updated: {formatDate(i.updated)}</span>
              </S.Created>
            </S.Left>
            <S.Right>
              {i.type !== "system" && (
                <SubMenu theme={i} onDelete={(e) => handlerDelete(e, i._id)} />
              )}
            </S.Right>
          </S.Item>
        ))}
      </S.List>

      <ConfirmModal
        title="Delete theme?"
        text="Are you sure you want to delete this theme?"
        onAccept={confirmedDelete}
        labelAccept="Delete"
      />
    </>
  )
}
