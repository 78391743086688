import styled from "styled-components"
import {
  Input as MuiInput,
  InputAdornment as MuiInputAdornment,
} from "@material-ui/core"

export const Input = styled(MuiInput)`
  background: ${({ theme }) => theme.grid.search.bg};
  border-radius: 8px;

  svg {
    fill: ${({ theme }) => theme.grid.search.icon};
  }

  &:after,
  &:before,
  &:hover {
    border-bottom: none !important;
  }
`

export const InputAdornment = styled(MuiInputAdornment)`
  padding-left: 0.5rem;
`
