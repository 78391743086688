export const palette = {
  white: "#fff",
  gray: "#C4C4C4",
  grayLight: "#F8F7F7",
  main: "#08C5A3", // "#523ddb",
  // success: "green",
  action: "#1CC8EE",

  errorAccent: "red",
  successAccent: "green",

  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey400: "#bdbdbd",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey800: "#424242",
  grey900: "#212121",
}
