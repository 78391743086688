import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import Popover from "@material-ui/core/Popover"
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined"
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined"

import { logout } from "data/entities/identity/thunks"
import { identitySelectors } from "data/entities/identity/selectors"

import { paths } from "view/router/paths"
import { useOpenable } from "view/hooks/application/useOpenable"
import { Avatar } from "view/uikit/common/Avatar"

import * as S from "./styled"

export const UserInfo = () => {
  const dispatch = useDispatch()

  const identity = useSelector(identitySelectors.one)

  const { anchorEl, open, onOpen, onClose } = useOpenable()

  const userName = useMemo(() => {
    if (identity.name) return identity.name
    if (identity.email) return identity.email

    return "Anonymous"
  }, [identity])

  return (
    <>
      <S.Wrapper onClick={onOpen}>
        <S.TopProfile>
          <Avatar name={identity.name} email={identity.email} />

          <S.Name>
            <S.UserName>{userName}</S.UserName>
          </S.Name>

          <S.IconButton>
            {open ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}
          </S.IconButton>
        </S.TopProfile>
      </S.Wrapper>

      <Popover
        id="user-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <S.SubTitle>
          <Avatar name={identity.name} email={identity.email} />
          <S.Text>{userName}</S.Text>
        </S.SubTitle>

        <S.GroupLink>
          <S.MenuItem component={Link} to={paths.billing()} onClick={onClose}>
            Billing
          </S.MenuItem>
          <S.MenuItem component={Link} to={paths.profile()} onClick={onClose}>
            Profile
          </S.MenuItem>
          {/* <S.MenuItem */}
          {/*  component={React.forwardRef(({ children, ...props }, ref) => { */}
          {/*    return ( */}
          {/*      <a {...props} ref={ref}> */}
          {/*        {children} */}
          {/*      </a> */}
          {/*    ) */}
          {/*  })} */}
          {/*  target="_blank" */}
          {/*  href="https://docs.user-onboarding.app" */}
          {/*  onClick={onClose} */}
          {/* > */}
          {/*  Docs & Guides */}
          {/* </S.MenuItem> */}
          <S.MenuItem onClick={() => dispatch(logout())}>Logout</S.MenuItem>
        </S.GroupLink>
      </Popover>
    </>
  )
}
