import React, { memo, useCallback } from "react"

import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import { appHistory } from "infrastructure/application/history"

import { GuideStatusEnum } from "data/entities/guide/enums"

import { Button } from "view/uikit/base/Button"
import { Grid } from "view/uikit/common/Grid"
import { GridRows } from "view/pages/entities/HotspotListPage/GridRows"
import { GridFilter } from "view/uikit/common/Grid/GridFilter"
import { useOpenable } from "view/hooks/application/useOpenable"
import { paths } from "view/router/paths"
import { useDispatch, useSelector } from "react-redux"
import { HotspotRefetchContainer } from "domain/hotspot/HotspotRefetchContainer"
import { HotspotCreateModal } from "view/pages/entities/HotspotListPage/HotspotCreateModal"
import { hotspotSelectors } from "data/entities/hotspotGroup/selectors"
import { hotspotGroupActions } from "data/entities/hotspotGroup/actions"

const statusOptions = [
  {
    label: "Any",
    value: "",
  },
  {
    label: "Draft",
    value: GuideStatusEnum.Draft,
  },
  {
    label: "Published",
    value: GuideStatusEnum.Published,
  },
  // {
  //   label: "Archived",
  //   value: GuideStatusEnum.Archived,
  // },
]

export const HotspotListPage = memo(() => {
  const dispatch = useDispatch()

  const filter = useSelector(hotspotSelectors.filter)

  const dirtFilter = filter.status || filter.search

  const clearFilter = async () => {
    await dispatch(hotspotGroupActions.clearFilter())
  }

  const modal = useOpenable(false)

  const handlerOpenItem = useCallback((e, item) => {
    appHistory.push(paths.productTour(item._id))
  }, [])

  const Filter = (
    <>
      <GridFilter
        name="status"
        label="Status"
        value={statusOptions.find((o) => o.value === filter.status)}
        options={statusOptions}
        setFilter={hotspotGroupActions.setFilter}
      />

      {dirtFilter && (
        <Button
          color="secondary"
          variant="text"
          onClick={clearFilter}
          startIcon={<HighlightOffIcon />}
        >
          Clear filters
        </Button>
      )}
    </>
  )

  return (
    <>
      <HotspotRefetchContainer />

      <Grid
        title="Hotspots"
        buttonCreateTitle="New hotspot"
        handlerOpenItem={handlerOpenItem}
        onButtonCreate={modal.onOpen}
        filter={Filter}
        GridRows={GridRows}
        selectors={hotspotSelectors}
        actions={hotspotGroupActions}
      />

      <HotspotCreateModal {...modal} />
    </>
  )
})
