import React, { memo, useEffect, useState } from "react"

import { Select } from "view/uikit/base/Select"
import { DateIntervalEnum } from "view/pages/analytics/DashboardPage/enums"
import { getDiffDays } from "infrastructure/utils/date"

export const GroupBy = memo(({ dateRange, interval, setInterval }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const diff = getDiffDays(dateRange)

    if (diff <= 3) {
      // setInterval(DateIntervalEnum.Hour)
      setOptions([
        {
          label: "15 Minutes",
          value: DateIntervalEnum.Minutes_15,
        },
        {
          label: "Hour",
          value: DateIntervalEnum.Hour,
        },
        {
          label: "4 Hours",
          value: DateIntervalEnum.Hours_4,
        },
      ])
      return
    }

    if (diff > 3 && diff <= 7) {
      // setInterval(DateIntervalEnum.Day)
      setOptions([
        {
          label: "4 Hours",
          value: DateIntervalEnum.Hours_4,
        },
        {
          label: "Day",
          value: DateIntervalEnum.Day,
        },
      ])
      return
    }

    if (diff > 7 && diff <= 32) {
      // setInterval(DateIntervalEnum.Day)
      setOptions([
        {
          label: "Day",
          value: DateIntervalEnum.Day,
        },
        {
          label: "Week",
          value: DateIntervalEnum.Week,
        },
      ])
      return
    }

    if (diff > 32 && diff <= 100) {
      // setInterval(DateIntervalEnum.Week)
      setOptions([
        {
          label: "Day",
          value: DateIntervalEnum.Day,
        },
        {
          label: "Week",
          value: DateIntervalEnum.Week,
        },
        {
          label: "Month",
          value: DateIntervalEnum.Month,
        },
      ])
      return
    }

    // setInterval(DateIntervalEnum.Month)
    setOptions([
      {
        label: "Month",
        value: DateIntervalEnum.Month,
      },
      {
        label: "Quarter",
        value: DateIntervalEnum.Quarter,
      },
    ])
  }, [dateRange])

  return (
    <Select
      label="Group by"
      value={interval}
      options={options}
      onChange={setInterval}
      variant="outlined"
    />
  )
})
