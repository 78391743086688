import React, { forwardRef } from "react"
import MuiButton from "@material-ui/core/Button"

export const Button = forwardRef(
  (
    {
      children,
      variant = "contained",
      size = "small",
      color = "primary",
      ...rest
    },
    ref
  ) => {
    return (
      <MuiButton
        variant={variant}
        size={size}
        color={color}
        ref={ref}
        {...rest}
      >
        {children}
      </MuiButton>
    )
  }
)
