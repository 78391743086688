import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 40px;
`

export const IFrame = styled.iframe`
  width: 100%;
  border: none;
`
