import { adapter } from "data/entities/segment/selectors"
import { SortEnum } from "data/enums/SortEnum"

export const initialState = adapter.getInitialState({
  isLoading: false,
  current: null,
  sort: SortEnum.DateUpdate,
  filter: {},
  pagging: {
    total: 1,
    page: 1,
    limit: 10,
  },
})
