import React, { forwardRef, useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { IconButton } from "@material-ui/core"
import ContentCopyIcon from "@material-ui/icons/FileCopyOutlined"
import { Tooltip } from "view/uikit/base/Tooltip"

export const CopyButton = forwardRef(
  (
    {
      children,
      variant = "contained",
      size = "small",
      color = "primary",
      copyText,
      ...rest
    },
    ref
  ) => {
    const [copied, setCopied] = useState(false)

    useEffect(() => {
      if (!copied) return undefined

      const timer = setTimeout(() => {
        setCopied(false)
      }, 1000)

      return () => {
        if (timer) clearTimeout(timer)
      }
    }, [copied])

    return (
      <Tooltip open={copied} title="Copied!">
        <CopyToClipboard text={copyText} onCopy={() => setCopied(true)}>
          <IconButton
            variant={variant}
            size={size}
            color={color}
            ref={ref}
            {...rest}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </CopyToClipboard>
      </Tooltip>
    )
  }
)
