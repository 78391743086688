import styled from "styled-components"

export const Wrapper = styled.div`
  h2 {
    font-weight: normal;
    margin-top: 0;
  }
`

export const Code = styled.textarea`
  width: 100%;
  margin: 0;
  font-size: 14px;
  border: none;
  background: white;
  resize: none;
  margin-bottom: 1rem;
`
