import { createReducer } from "@reduxjs/toolkit"

import { initialState } from "data/entities/guide/consts"
import { adapter } from "data/entities/guide/selectors"
import { guideSetProperty, guideThunks } from "data/entities/guide/thunks"
import { ReducersFabric } from "data/entities/ReducersFabric"
import { guideActions } from "data/entities/guide/actions"
import { logout } from "data/entities/identity/thunks"

export const guideReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: guideActions,
    thunks: guideThunks,
    initState: initialState,
  }).build()

  builder.addCase(guideSetProperty.fulfilled, (state, action) => {
    const { id, ...rest } = action.payload
    adapter.updateOne(state, { id, changes: rest })
  })

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
