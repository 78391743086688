import { entityName } from "data/entities/consts"

export const identitySelectors = {
  one: (state) => state[entityName.identity],
  current: (state) => state[entityName.identity],
  init: (state) => state[entityName.identity].init,
  email: (state) => state[entityName.identity].email,
  clientState: (state) => state[entityName.identity].clientState,
  role: (state) => {
    const userId = state[entityName.identity]._id
    const org =
      state[entityName.organizations].entities[
        state[entityName.organizations].current
      ]

    if (!userId || !org) return null

    const user = org.users.find((u) => u.userId === userId)

    if (!user) return null

    return user.role
  },
}
