import styled from "styled-components"
import { boxShadow } from "view/themes/mixins"
import { palette } from "view/themes/palette"

export const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 2rem;

  & > h3 {
    font-weight: normal;
    font-size: 1.4rem;
    margin: 0;
    margin-right: 2rem;
  }
`

export const LoaderWrapper = styled.div`
  height: 300px;
`

export const PlanWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 3rem;
`

export const WrapperPlan = styled.div`
  width: 20%;
  min-width: 280px;

  &:first-child {
    & > div {
      margin-left: 0;
    }
  }

  &:last-child {
    & > div {
      margin-right: 0;
    }
  }
`

export const Price = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin-bottom: 2rem;
  color: ${palette.grey500};

  span {
    font-size: 1.3rem;
    margin-right: 0.25rem;
    color: ${palette.dark};
  }
`

export const Annual = styled.div`
  position: absolute;
  bottom: -1.5rem;
`

export const Name = styled.div`
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
`

export const ButtonWrapper = styled.div``

export const Plan = styled.div`
  background: ${palette.white};
  border: 1px solid ${({ current }) => (current ? palette.main : palette.white)};
  padding: 2rem 1rem;
  margin: 0 1rem;
  text-align: center;
  border-radius: 1rem;
  ${boxShadow()}

  &:hover {
    z-index: 100;
  }
`
