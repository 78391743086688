import React, { useEffect, useState } from "react"
import { useContextSelector } from "use-context-selector"

import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"
import { paths } from "view/router/paths"
import { Link } from "view/uikit/base/Link"
import { AccountTypeEnum } from "domain/billing/enums"
import * as S from "./styled"

export const TopNotification = () => {
  const billingAccount = useContextSelector(
    BillingContext,
    billingSelectors.billingAccount
  )

  const [dayLeft, setDayLeft] = useState()

  useEffect(() => {
    if (!billingAccount.expiredDate) return

    const days = Math.round(
      (new Date(billingAccount.expiredDate) - new Date()) /
        (1000 * 60 * 60 * 24)
    )

    setDayLeft(days)
  }, [billingAccount, setDayLeft])

  if (!billingAccount.expiredDate) return null
  if (billingAccount.type !== AccountTypeEnum.Trial) return null

  if (dayLeft > 0) {
    return (
      <S.Wrapper>
        You have {dayLeft} days left of your free trial.{" "}
        <Link to={paths.billing()}>Subscribe now</Link>
      </S.Wrapper>
    )
  }

  if (dayLeft === 0) {
    return (
      <S.Wrapper>
        Your free trial expires today.{" "}
        <Link to={paths.billing()}>Subscribe now</Link>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      Your free trial is over. <Link to={paths.billing()}>Subscribe now</Link>
    </S.Wrapper>
  )
}
