import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { IconButton, InputAdornment } from "@material-ui/core"
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"

import { useDebounce } from "infrastructure/_hooks/useDebounce"
import { usePrev } from "infrastructure/_hooks/usePrev"

import * as S from "./styled"

export const GridSearch = ({ actions }) => {
  const dispatch = useDispatch()

  const [search, setSearch] = useState("")
  const debouncedValue = useDebounce(search, 300)
  const prevDebouncedValue = usePrev(debouncedValue)

  const changeValue = useCallback((e) => {
    setSearch(e.target.value)
  }, [])

  useEffect(() => {
    if (!debouncedValue && prevDebouncedValue === undefined) return

    dispatch(actions.setFilter({ search: debouncedValue }))
  }, [debouncedValue, dispatch, prevDebouncedValue, actions])

  const clearValue = useCallback(() => {
    setSearch("")
  }, [])

  return (
    <S.Input
      id="search"
      value={search}
      placeholder="Search"
      onChange={changeValue}
      startAdornment={
        <S.InputAdornment position="start">
          <SearchOutlinedIcon />
        </S.InputAdornment>
      }
      endAdornment={
        search ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={clearValue}>
              <CloseOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ) : null
      }
    />
  )
}
