import React from "react"

import { useQuery } from "infrastructure/_hooks/useQuery"

import { Select } from "view/uikit/base/Select"

export const ProductTourFilter = ({ pid, gid, setGid, disabled }) => {
  const { isLoading, data } = useQuery("/guides/options", { pid })

  let options = [
    {
      label: "All tours",
      value: "all",
    },
  ]

  const handlerChange = (newValue) => {
    setGid(newValue)
  }

  if (data) options = [...options, ...data]

  return (
    <Select
      label="Product tour"
      value={gid}
      options={options}
      onChange={handlerChange}
      variant="outlined"
      disabled={isLoading || disabled}
    />
  )
}
