import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { organizationSelectors } from "data/entities/organization/selectors"
import { projectSelectors } from "data/entities/project/selectors"
import { hotspotSelectors } from "data/entities/hotspotGroup/selectors"
import { hotspotThunks } from "data/entities/hotspotGroup/thunks"

import { useNotice } from "view/hooks/application/useNotice"

export const HotspotRefetchContainer = () => {
  const dispatch = useDispatch()
  const { errorNotice } = useNotice()

  const pid = useSelector(projectSelectors.current)
  const organization = useSelector(organizationSelectors.current)

  const sort = useSelector(hotspotSelectors.sort)
  const filter = useSelector(hotspotSelectors.filter)
  const pagging = useSelector(hotspotSelectors.pagging)

  useEffect(() => {
    if (!organization) return null

    const promise = dispatch(hotspotThunks.fetchList())

    ;(async () => {
      const result = await promise

      if (result.type === hotspotThunks.fetchList.rejected().type) {
        if (result.error.message !== "Aborted") {
          errorNotice("Server error!")
        }
      }
    })()

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    pid,
    organization?._id,
    sort,
    filter.status,
    filter.search,
    pagging.limit,
    pagging.page,
  ])

  return null
}
