import React, { memo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import { appHistory } from "infrastructure/application/history"

import { GuideRefetchContainer } from "domain/guide/GuideRefetchContainer"

import { guideSelectors } from "data/entities/guide/selectors"
import { guideActions } from "data/entities/guide/actions"
import { GuideStatusEnum } from "data/entities/guide/enums"

import { useOpenable } from "view/hooks/application/useOpenable"
import { paths } from "view/router/paths"
import { Grid } from "view/uikit/common/Grid"
import { GridFilter } from "view/uikit/common/Grid/GridFilter"
import { Button } from "view/uikit/base/Button"
import { GuideCreateModal } from "./GuideCreateModal"
import { GridRows } from "./GridRows"

const statusOptions = [
  {
    label: "Any",
    value: "",
  },
  {
    label: "Draft",
    value: GuideStatusEnum.Draft,
  },
  {
    label: "Published",
    value: GuideStatusEnum.Published,
  },
  // {
  //   label: "Archived",
  //   value: GuideStatusEnum.Archived,
  // },
]

export const GuideListPage = memo(() => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const filter = useSelector(guideSelectors.filter)

  const dirtFilter = filter.status || filter.search

  const clearFilter = async () => {
    await dispatch(guideActions.clearFilter())
  }

  const modal = useOpenable(false)

  const handlerOpenItem = useCallback((e, item) => {
    appHistory.push(paths.productTour(item._id))
  }, [])

  const Filter = (
    <>
      <GridFilter
        name="status"
        label="Status"
        value={statusOptions.find((o) => o.value === filter.status)}
        options={statusOptions}
        setFilter={guideActions.setFilter}
      />

      {dirtFilter && (
        <Button
          color="secondary"
          variant="text"
          onClick={clearFilter}
          startIcon={<HighlightOffIcon />}
        >
          Clear filters
        </Button>
      )}
    </>
  )

  return (
    <>
      <GuideRefetchContainer />

      <Grid
        title={t("productTours.list.title")}
        buttonCreateTitle={t("productTours.button.create")}
        handlerOpenItem={handlerOpenItem}
        onButtonCreate={modal.onOpen}
        filter={Filter}
        GridRows={GridRows}
        selectors={guideSelectors}
        actions={guideActions}
      />

      <GuideCreateModal {...modal} />
    </>
  )
})
