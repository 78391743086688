export const MuiChip = (theme) => ({
  root: {
    height: 20,
  },

  label: {
    fontSize: 11,
    lineHeight: "20px",
  },

  colorPrimary: {
    color: "white",
  },
})
