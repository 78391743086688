import { createReducer } from "@reduxjs/toolkit"

import { ReducersFabric } from "data/entities/ReducersFabric"
import { initialState } from "data/entities/transaction/consts"
import { transactionActions } from "data/entities/transaction/actions"
import { transactionThunks } from "data/entities/transaction/thunks"
import { logout } from "data/entities/identity/thunks"

export const transactionReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: transactionActions,
    thunks: transactionThunks,
    initState: initialState,
  }).build()

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
