import React, { useCallback, useMemo, useState } from "react"
import { useContextSelector } from "use-context-selector"

import { errorService } from "infrastructure/services/ErrorService"

import { StripeService } from "domain/billing/services/StripeService"
import { PlanTypeEnum } from "domain/billing/enums"
import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"

import { Button } from "view/uikit/base/Button"
import { ConfirmUpgradeModal } from "view/modals/billing/ConfirmUpgradeModal"
import { useOpenable } from "view/hooks/application/useOpenable"
import { useNotice } from "view/hooks/application/useNotice"

export const StripeSubscribeButton = ({ plan, currentPlan }) => {
  const totalDisabled = useContextSelector(
    BillingContext,
    billingSelectors.totalDisabled
  )

  const updateBilling = useContextSelector(
    BillingContext,
    billingSelectors.update
  )

  const [isProcessing, setProcessing] = useState(false)

  const confirmModal = useOpenable(false)
  const { errorNotice } = useNotice()

  const isCurrent = currentPlan?.id === plan.id

  const subscribePlan = useCallback(
    async (e) => {
      updateBilling({
        totalDisabled: true,
      })
      setProcessing(true)

      const result = await StripeService.createSession({
        planId: plan.id,
        successUrl: `${process.env.REACT_APP_HOST}/billing?success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${process.env.REACT_APP_HOST}/billing?canceled=true`,
      })

      if (result?.redirectUrl) {
        window.open(result.redirectUrl, "_self")
      }
    },
    [plan]
  )

  const upgradePlan = confirmModal.onOpen

  const handlerUpgradePlan = useCallback(
    async (e) => {
      updateBilling({
        totalDisabled: true,
      })
      setProcessing(true)

      try {
        const response = await StripeService.updateSubscription({
          planId: plan.id,
        })

        if (!response) {
          errorNotice("Server error!")
          updateBilling({
            totalDisabled: false,
          })
          return
        }

        updateBilling({
          totalDisabled: false,
          billingAccount: response.billingAccount,
          subscription: response?.subscription || null,
        })
        setProcessing(false)
      } catch (err) {
        errorService.send(err)
        errorNotice("Server error!")
        updateBilling({
          totalDisabled: false,
        })
        setProcessing(false)
      }
    },
    [updateBilling]
  )

  const btnParams = useMemo(() => {
    if (currentPlan?.id === plan.id) {
      return {
        label: "Current plan",
        onClick: undefined,
      }
    }

    if (isProcessing) {
      return {
        label: "Processing...",
        onClick: undefined,
      }
    }

    if (plan.type === PlanTypeEnum.OneTime) {
      return {
        label: "Buy",
        onClick: subscribePlan,
      }
    }

    if (
      currentPlan?.type === PlanTypeEnum.Subscription &&
      currentPlan?.id !== plan.id
    ) {
      return {
        label: "Upgrade",
        onClick: upgradePlan,
      }
    }

    return {
      label: "Subscribe",
      onClick: subscribePlan,
    }
  }, [currentPlan, plan, isProcessing, subscribePlan, upgradePlan])

  return (
    <>
      <Button
        size="large"
        color={isCurrent ? "default" : "primary"}
        variant="contained"
        disabled={totalDisabled || isCurrent}
        onClick={btnParams.onClick}
      >
        {btnParams.label}
      </Button>

      <ConfirmUpgradeModal
        modal={confirmModal}
        title="Upgrade subscription"
        text="The tariff difference will be automatically paid from your card!"
        onAccept={handlerUpgradePlan}
        labelAccept="Upgrade"
        labelCancel="Keep subscription"
      />
    </>
  )
}
