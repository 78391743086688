import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined"
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined"
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined"
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined"
import AssistantPhotoOutlinedIcon from "@material-ui/icons/AssistantPhotoOutlined"
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined"
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined"
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined"
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined"
import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined"
import {
  closeLeftSidebar,
  openLeftSidebar,
} from "data/entities/application/thunks"

import { UserInfo } from "view/components/application/UserInfo"
import { paths } from "view/router/paths"
import { ReactComponent as Logo } from "view/components/application/LeftSidebar/logo.svg"

import { Button } from "view/uikit/base/Button"
import { appHistory } from "infrastructure/application/history"
import { UpgradeButton } from "view/components/application/LeftSidebar/UpgradeButton"
import { ProjectSelector } from "view/components/application/LeftSidebar/ProjectSelector"
import { Badge } from "@material-ui/core"
import * as S from "./styled"

export const LeftSidebar = ({ open = true }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const menu1 = [
    {
      link: paths.home(),
      label: t("menu.dashboard"),
      icon: <DashboardOutlinedIcon />,
    },
  ]
  // <Badge badgeContent={4} color="primary">
  // <MailIcon color="action" />
  // </Badge>
  const menu2 = [
    {
      link: paths.productTours(),
      label: "Product tours",
      icon: <AssistantPhotoOutlinedIcon />,
    },
    {
      link: paths.hotspots(),
      label: t("menu.hotspots"),
      icon: <LocationOnOutlinedIcon />,
    },
    // {
    //   link: paths.checklists(),
    //   label: "Checklists",
    //   icon: <FormatListBulletedOutlinedIcon />,
    //   soon: true,
    // },
    // {
    //   link: paths.checklists(),
    //   label: "NPS",
    //   icon: <ChatBubbleOutlineOutlinedIcon />,
    //   soon: true,
    // },
  ]

  const menu3 = [
    // {
    //   link: paths.segments(),
    //   label: t("menu.segments"),
    //   icon: <PeopleAltOutlinedIcon />,
    // },
    {
      link: paths.themes(),
      label: t("menu.themes"),
      icon: <PaletteOutlinedIcon />,
    },
    // {
    //   link: paths.themes(),
    //   label: "Segments",
    //   icon: <PieChartOutlinedIcon />,
    //   soon: true,
    // },
  ]

  const menu4 = [
    {
      link: paths.settings(),
      label: "Settings",
      icon: <SettingsOutlinedIcon />,
    },
  ]

  const bottomMenu = useMemo(() => {
    return [
      // {
      //   link: paths.settings(),
      //   label: "Settings",
      //   icon: <SettingsOutlinedIcon />,
      // },
      // {
      //   link: paths.feedback(),
      //   label: "Feedback",
      //   icon: <SettingsOutlinedIcon />,
      // },
    ]
  }, [])

  const handlerOpen = () => {
    dispatch(openLeftSidebar())
  }

  const handlerClose = () => {
    dispatch(closeLeftSidebar())
  }

  return (
    <S.Drawer variant="permanent" open={open}>
      <div>
        <S.Link to={paths.home()}>
          <S.Img>
            <Logo />
          </S.Img>
          <S.Text>
            <i>Mint</i>Like
          </S.Text>
        </S.Link>

        <ProjectSelector />

        <S.List component="nav" aria-label="guides">
          {menu1.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
              exact
            >
              <S.Icon>{m.icon}</S.Icon>
              <S.ListItemText primary={m.label} />
            </S.ListItem>
          ))}
          <S.Delimiter />
          {menu2.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
            >
              <S.Icon>{m.icon}</S.Icon>
              {m.soon ? (
                <Badge badgeContent="soon" color="primary">
                  <S.ListItemText primary={m.label} />
                </Badge>
              ) : (
                <S.ListItemText primary={m.label} />
              )}
            </S.ListItem>
          ))}
          <S.Delimiter />
          {menu3.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
            >
              <S.Icon>{m.icon}</S.Icon>
              {m.soon ? (
                <Badge badgeContent="soon" color="primary">
                  <S.ListItemText primary={m.label} />
                </Badge>
              ) : (
                <S.ListItemText primary={m.label} />
              )}
            </S.ListItem>
          ))}
          <S.Delimiter />
          {menu4.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
            >
              <S.Icon>{m.icon}</S.Icon>
              {m.soon ? (
                <Badge badgeContent="soon" color="primary">
                  <S.ListItemText primary={m.label} />
                </Badge>
              ) : (
                <S.ListItemText primary={m.label} />
              )}
            </S.ListItem>
          ))}
        </S.List>
      </div>
      <div>
        <UpgradeButton open={open} />

        <S.List component="nav" aria-label="guides">
          {bottomMenu.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
              exact
            >
              <S.Icon>{m.icon}</S.Icon>
              <S.ListItemText primary={m.label} />
            </S.ListItem>
          ))}
        </S.List>
        <UserInfo />
        <S.Collapse onClick={open ? handlerClose : handlerOpen}>
          <IconButton size="medium">
            {open ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}
          </IconButton>
          Collapse
        </S.Collapse>
      </div>
    </S.Drawer>
  )
}
