import { RoleEnum } from "data/enums/RoleEnum"

export const useRole = (role) => {
  return {
    isOwner: role === RoleEnum.Owner,
    isAdmin: role === RoleEnum.Admin,
    isManager: role === RoleEnum.Manager,
    isDeveloper: role === RoleEnum.Developer,
    isTranslator: role === RoleEnum.Translator,
  }
}
