import { createAsyncThunk } from "@reduxjs/toolkit"

import { userRepository } from "data/repositories/userRepository"
import { identitySelectors } from "data/entities/identity/selectors"
import { entityName } from "data/entities/consts"
import {
  leftSidebarClosed,
  leftSidebarOpened,
} from "data/entities/application/actions"

export const closeLeftSidebar = createAsyncThunk(
  `${entityName.application}/closeLeftSidebar`,
  async (values, { getState, dispatch, rejectWithValue }) => {
    dispatch(leftSidebarClosed())

    const userId = identitySelectors.uid(getState())

    try {
      return await userRepository.clientStateSet(
        userId,
        "leftSidebarOpen",
        false
      )
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const openLeftSidebar = createAsyncThunk(
  `${entityName.application}/openLeftSidebar`,
  async (values, { getState, dispatch, rejectWithValue }) => {
    dispatch(leftSidebarOpened())

    const userId = identitySelectors.uid(getState())

    try {
      return await userRepository.clientStateSet(
        userId,
        "leftSidebarOpen",
        true
      )
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
