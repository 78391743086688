import React from "react"

import * as S from "./styled"

export const Box = ({ title, secondary = false, children, padding = 2 }) => {
  return (
    <S.Wrapper secondary={secondary} padding={padding}>
      {title && <S.Title secondary={secondary}>{title}</S.Title>}
      {children}
    </S.Wrapper>
  )
}
