import { createAction } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const unsetSubscription = createAction(
  `${entityName.billing}/unsetSubscription`
)

export const setUpdatingSubscription = createAction(
  `${entityName.billing}/setUpdatingSubscription`
)
