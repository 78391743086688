import React from "react"
import { Field } from "react-final-form"

import {
  Button,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

import { Container } from "view/forms/identity/SignupForm/container"
import { InputField } from "view/uikit/formFields/InputField"
import { paths } from "view/router/paths"
import { SocialButtons } from "view/components/identity/SocialButtons"
import { Link } from "view/uikit/base/Link"

import { FormError } from "view/uikit/common/FormError"
import * as S from "view/forms/identity/SignupForm/styled"

const Component = ({ disabled, handleSubmit, submitError, submitting }) => {
  return (
    <S.Wrapper>
      <S.Leftside>
        <S.Title>Sign up</S.Title>
        <S.SubTitle>You can sign up via your email or Gmail account</S.SubTitle>

        <SocialButtons title="Sign up" />

        <S.SubTitle>Or sign up with your email</S.SubTitle>

        <S.Form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <Field
              component={InputField}
              disabled={disabled}
              name="email"
              label="Email"
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Field
              component={InputField}
              disabled={disabled}
              name="password"
              label="Password"
              type="password"
            />
          </FormControl>

          {submitError && (
            <FormControl fullWidth margin="normal">
              <FormError>{submitError}</FormError>
            </FormControl>
          )}

          <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            type="submit"
          >
            Sign up
          </Button>
        </S.Form>

        <S.Text>
          Already have an account? <Link to={paths.login()}>Login.</Link>
        </S.Text>
      </S.Leftside>

      <S.Rightside>
        <S.BenefitsTitle>Get started today</S.BenefitsTitle>
        <S.BenefitsSubtitle>
          No code. No credit card required.
        </S.BenefitsSubtitle>

        <List>
          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Setup in minutes" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Onboarding new users" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Announce new features" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Guide existing users" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="No-code integration" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Customize for your application" />
          </ListItem>
        </List>
      </S.Rightside>
    </S.Wrapper>
  )
}

export const SignupForm = () => {
  return <Container component={Component} />
}
