import { ThunksFabric } from "data/entities/ThunksFabric"
import { projectSelectors } from "data/entities/project/selectors"
import { ParentEnum } from "data/entities/enums"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiService } from "infrastructure/services/ApiService"
import { entityName } from "../consts"

export const projectThunks = new ThunksFabric({
  entityName: entityName.projects,
  selectors: projectSelectors,
  parent: ParentEnum.Organization,
}).build()

export const setCurrentProject = createAsyncThunk(
  `${entityName.projects}/setCurrent`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      const result = await apiService.put(`/state`, { currentProject: id })

      return id
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
