import { fontFamily } from "view/themes/overrideMui/fontFamily"

export const MuiTypography = (theme) => ({
  body1: {
    fontFamily,
  },
  body2: {
    fontFamily,
  },
  h4: {
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
  h5: {
    fontFamily,
  },
})
