import React, { memo, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { appHistory } from "infrastructure/application/history"

import { themeSelectors } from "data/entities/theme/selectors"
import { themeActions } from "data/entities/theme/actions"

import { ThemeRefetchContainer } from "domain/theme/ThemeRefetchContainer"

import { useOpenable } from "view/hooks/application/useOpenable"
import { Grid } from "view/uikit/common/Grid"

import { paths } from "view/router/paths"
import { GridRows } from "./GridRows"
import { ThemeCreateModal } from "./CreateModal"

export const ThemeListPage = memo(() => {
  const { t } = useTranslation()

  const modal = useOpenable(false)

  const handlerOpenItem = useCallback((e, item) => {
    appHistory.push(paths.themeItem(item._id))
  }, [])

  return (
    <>
      <ThemeRefetchContainer />
      <Grid
        title={t("themes.list.title")}
        buttonCreateTitle={t("themes.button.create")}
        handlerOpenItem={handlerOpenItem}
        onButtonCreate={modal.onOpen}
        GridRows={GridRows}
        actions={themeActions}
        selectors={themeSelectors}
      />

      <ThemeCreateModal {...modal} />
    </>
  )
})
