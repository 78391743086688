import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch } from "react-redux"
import { FORM_ERROR } from "final-form"
import { useSnackbar } from "notistack"

import { errorService } from "infrastructure/services/ErrorService"

import { themeThunks } from "data/entities/theme/thunks"

import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"
import { validate } from "./validate"

const initialValues = {}

export const Container = ({ component: Component, componentProps }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handlerSubmit = useCallback(
    async (values, { reset }) => {
      try {
        const result = await dispatch(themeThunks.create(values))

        if (result.type === themeThunks.create.fulfilled().type) {
          setTimeout(reset, 0)
          componentProps.cbCancel()
          enqueueSnackbar("Theme has been created!", {
            variant: "success",
          })
          appHistory.push(paths.themeItem(result.payload._id))
          return {}
        }

        if (result.type === themeThunks.create.rejected().type) {
          const { response } = result.payload

          if (response?.status === 400) {
            if (
              response.data.code === "not-unique" &&
              response.data.param === "slug"
            ) {
              return {
                name: "Name already used",
              }
            }
          }

          if (
            response?.status === 403 &&
            response.data.code === "account-is-not-active"
          ) {
            return {
              [FORM_ERROR]: "Subscription invalid upgrade your plan!",
            }
          }
        }

        return {
          [FORM_ERROR]: "Server error",
        }
      } catch (e) {
        errorService.send(e)
        return {
          [FORM_ERROR]: "Server error",
        }
      }
    },
    [componentProps, dispatch, enqueueSnackbar]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} {...componentProps} />}
    />
  )
}
