import React, { createRef } from "react"
import { useSelector } from "react-redux"
import { Router } from "react-router"
import { SnackbarProvider } from "notistack"

import { IconButton, CssBaseline } from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import { ThemeProvider as ThemeProviderSC } from "styled-components"
import { ThemeProvider as ThemeProviderMui } from "@material-ui/styles"

import { appHistory } from "infrastructure/application/history"

import { ContainersAfterInit } from "domain/ContainersAfterInit"
import { Containers } from "domain/Containers"

import { applicationSelectors } from "data/entities/application/selectors"

import { ErrorBoundary } from "view/ErrorBoundary"
import { InitLoader } from "view/components/application/InitLoader"
import { Routes } from "view/router/routes"
import { RootModal } from "view/modals/RootModal"
import { getOverriddenMuiTheme } from "view/themes/overrideMui"
import { ThemeName } from "view/themes/enums"
import { lightTheme } from "view/themes/light"
import { darkTheme } from "view/themes/dark"

import "react-datepicker/dist/react-datepicker.css"
import "view/themes/overrideLibs/react-datepicker.css"
import { Providers } from "domain/Providers"

export const App = () => {
  const themeName = useSelector(applicationSelectors.theme)

  const notistackRef = createRef()

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <ErrorBoundary>
      <Providers>
        <CssBaseline />
        <ThemeProviderMui theme={getOverriddenMuiTheme(themeName)}>
          <ThemeProviderSC
            theme={themeName === ThemeName.Light ? lightTheme : darkTheme}
          >
            <SnackbarProvider
              maxSnack={3}
              ref={notistackRef}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)} size="small">
                  <CloseOutlinedIcon style={{ fill: "#fff" }} size="small" />
                </IconButton>
              )}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Router history={appHistory}>
                <Containers />
                <RootModal />

                <InitLoader>
                  <ContainersAfterInit />
                  <Routes />
                </InitLoader>
              </Router>
            </SnackbarProvider>
          </ThemeProviderSC>
        </ThemeProviderMui>
      </Providers>
    </ErrorBoundary>
  )
}
