import styled from "styled-components"

export const Empty = styled.div`
  height: 25rem;
  text-align: center;

  img {
    height: 20rem;
  }
`

export const EmptyText = styled.div`
  margin-bottom: 16px;
`

export const Table = styled.div`
  display: table;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 1px 0 rgb(15 51 77 / 25%), 0 2px 10px 0 rgb(51 51 79 / 2%);
  background-color: #fff;
  color: #515151;
`

export const Row = styled.div`
  display: table-row;

  & > div {
    border-bottom: 1px solid #f2f2ff;
  }
`

export const HeadRow = styled.div`
  display: table-row;
  font-weight: bold;

  & > div {
    background: #fafafa;
    border-bottom: 1px solid #f2f2ff;
  }
`

export const Cell = styled.div`
  display: table-cell;
  padding: 12px 24px;
`

export const CellId = styled(Cell)`
  width: 5%;
`

export const Status = styled.div`
  font-size: 1rem;
  flex-grow: 1;
  padding-left: 2rem;
`
export const LinkWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const Created = styled.div`
  font-size: 10px;

  & > span:first-of-type {
    margin-right: 1rem;
  }
`

export const Id = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`
