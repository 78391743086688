import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"

import { Button, ButtonGroup, Popover } from "@material-ui/core"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"

import { enumToOptions } from "infrastructure/utils"

import { DateFilterEnum } from "view/pages/analytics/DashboardPage/enums"
import { useOpenable } from "view/hooks/application/useOpenable"
import {
  formatEndDate,
  formatStartDate,
  getIntervalByRange,
  rangeToString,
} from "infrastructure/utils/date"
import * as S from "view/pages/analytics/DashboardPage/DateFilter/styled"

export const DateFilter = ({
  presetRange,
  setPresetRange,
  dateRange,
  setDateRange,
  setInterval,
}) => {
  const [start, setStart] = useState(new Date(dateRange[0]))
  const [end, setEnd] = useState(new Date(dateRange[1]))
  const { open, anchorEl, onOpen, onClose } = useOpenable(false)

  const groupOptions = enumToOptions(DateFilterEnum)

  useEffect(() => {
    setStart(new Date(dateRange[0]))
    setEnd(new Date(dateRange[1]))
  }, [dateRange])

  const onChange = (dates) => {
    setStart(dates[0])
    setEnd(dates[1])
  }

  const handlerChange = () => {
    const dr = [formatStartDate(start), formatEndDate(end)]
    setPresetRange(null)
    setDateRange(dr)
    setInterval(getIntervalByRange(dr))
    onClose()
  }

  const handlerClose = () => {
    setStart(new Date(dateRange[0]))
    setEnd(new Date(dateRange[1]))
    onClose()
  }

  return (
    <S.Wrapper>
      <ButtonGroup size="small">
        {groupOptions.map((o) => (
          <Button
            key={o.value}
            color={o.value === presetRange ? "primary" : "default"}
            variant={o.value === presetRange ? "contained" : "outlined"}
            onClick={() => setPresetRange(o.value)}
          >
            {o.label}
          </Button>
        ))}
      </ButtonGroup>

      <Button
        startIcon={<CalendarTodayIcon />}
        color="default"
        variant="outlined"
        size="small"
        onClick={onOpen}
      >
        {rangeToString(dateRange)}
      </Button>

      <Popover
        id="date-range"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DatePicker
          selected={start}
          startDate={start}
          endDate={end}
          monthsShown={3}
          disabledKeyboardNavigation
          calendarStartDay={1}
          maxDate={new Date()}
          onChange={onChange}
          selectsRange
          inline
        />
        <S.PickerButtons>
          <Button
            size="small"
            onClick={handlerClose}
            variant="outlined"
            color="default"
          >
            Close
          </Button>
          <Button
            size="small"
            onClick={handlerChange}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </S.PickerButtons>
      </Popover>
    </S.Wrapper>
  )
}
