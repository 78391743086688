import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"

// import { organizationSelectors } from "data/entities/organization/selectors"
import { entityName } from "data/entities/consts"
import { identitySelectors } from "data/entities/identity/selectors"
// import { identitySelectors } from "data/entities/identity/selectors"

export const userFetchList = createAsyncThunk(
  `${entityName.user}/fetchList`,
  async (props, { getState, rejectWithValue }) => {
    // try {
    // const state = getState()
    // const organization = organizationSelectors.current(state)
    // if (!organization?._id)
    //   return rejectWithValue(new Error("OrgID not found"))
    // const result = await apiService.get(`/users?orgId=${organization._id}`)
    // return result.data.map(({ _id, ...rest }) => ({
    //   id: _id,
    //   ...rest,
    // }))
    // } catch (e) {
    //   return rejectWithValue(e)
    // }
  }
)

export const userCreateByUid = createAsyncThunk(
  `${entityName.user}/createByUid`,
  async (userData, { rejectWithValue }) => {
    try {
      const result = await apiService.post(`/user-by-uid`, {
        ...userData,
      })

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const userGetByUid = createAsyncThunk(
  `${entityName.user}/getByUid`,
  async (uid, { rejectWithValue }) => {
    try {
      const result = await apiService.get(`/user-by-uid/?fbUid=${uid}`)

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const userUpdateProfile = createAsyncThunk(
  `${entityName.user}/updateProfile`,
  async (props, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const identity = identitySelectors.current(state)

      const result = await apiService.put(`/users/${identity._id}`, {
        ...props,
      })

      return {
        ...props,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
