import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

export const Search = styled.div`
  flex-shrink: 0;
  margin-right: 1rem;
`

export const Filter = styled.div`
  flex-grow: 1;
`

export const Sort = styled.div``
