import styled from "styled-components"
import { boxShadow } from "view/themes/mixins"
import { palette } from "view/themes/palette"
import { Button } from "view/uikit/base/Button"

export const Wrapper = styled.div`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
`

export const RowFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  row-gap: 16px;
  column-gap: 16px;
`

export const Left = styled.div`
  width: 600px;
  margin-right: 2rem;
`

export const Block = styled.div``

export const SubBlock = styled.div`
  margin-bottom: 1rem;
`

export const Right = styled.div`
  flex-grow: 1;

  & iframe {
    width: 100%;
    height: 620px;
  }
`

export const Box = styled.div`
  position: sticky;
  top: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: white;
  border-radius: 8px;
  ${boxShadow}
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`

export const Iframe = styled.iframe`
  //border-radius: 0.5rem;
`

export const ButtonsLeft = styled.div`
  & > div {
    margin-right: 2rem;
  }
`

export const ButtonsRight = styled.div``

export const SaveBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1rem;
  background: #2b343a;
  //width: 500px;
  padding: 2rem;
  padding-top: 1rem;
  border-radius: 8px;
  ${boxShadow}
`

export const Text = styled.div`
  color: #ffffff;
  margin-right: 2rem;
`

export const SetDefault = styled(Button)`
  margin-left: 1rem;
`

export const DefaultText = styled.div`
  color: ${palette.grey500};
  margin-left: 16px;
`
