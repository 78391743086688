import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form } from "react-final-form"

import { identitySelectors } from "data/entities/identity/selectors"
import { userUpdateProfile } from "data/entities/user/thunks"

import { useSnackbar } from "notistack"
import { validate } from "./validate"

export const Container = ({ component: Component }) => {
  const dispatch = useDispatch()

  const identity = useSelector(identitySelectors.current)
  const { enqueueSnackbar } = useSnackbar()

  const initialValues = useMemo(
    () => ({
      name: identity.name || "",
      email: identity.email || "",
    }),
    [identity]
  )

  const handlerSubmit = useCallback(
    async (values) => {
      await dispatch(userUpdateProfile(values))

      enqueueSnackbar("Personal data has been updated!", { variant: "success" })

      return {}
    },
    [dispatch, enqueueSnackbar]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => (
        <Component {...props} emailVerified={identity.emailVerified} />
      )}
    />
  )
}
