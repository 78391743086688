import styled from "styled-components"
import { palette } from "view/themes/palette"

export const Benefits = styled("div")`
  margin-bottom: 2rem;
`

export const Benefit = styled("div")`
  margin-bottom: 1rem;
`

export const BenefitLabel = styled("span")`
  margin-right: 0.3rem;
  color: ${palette.grey500};
`

export const BenefitValue = styled("span")`
  font-weight: 500;
  color: ${palette.dark};
`
