import { createBrowserHistory } from "history"
import queryString from "query-string"

const history = createBrowserHistory()

export const appHistory = {
  ...history,
  getSearch: () => {
    return queryString.parse(history.location.search.slice(1))
  },
}
