import styled from "styled-components"
import {
  IconButton as MuiIconButton,
  MenuItem as MiuMenuItem,
} from "@material-ui/core"
import { truncate } from "view/themes/mixins"

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  padding-right: 0;
  color: #d5d6d7;
`
export const TopProfile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Name = styled.div`
  width: 8rem;
  margin-left: 1rem;
`

export const UserName = styled.div`
  ${truncate}
`

export const OrgName = styled.div`
  font-size: 0.7rem;
  color: #7c828d;
  ${truncate}
`

export const IconButton = styled(MuiIconButton)`
  svg {
    fill: #7c828d;
  }
`

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`

export const Text = styled.div`
  margin-left: 0.5em;
  font-weight: 500;
  ${truncate}
`

export const GroupLink = styled.div`
  padding-bottom: 0.5rem;
`

export const MenuItem = styled(MiuMenuItem)`
  min-width: 13rem;
  font-size: 0.9rem;
  background: ${({ $active }) => ($active ? "#f5f5f5" : "white")};
`

export const OrgSwitcher = styled(MenuItem)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;

  svg {
    fill: #7c828d;
  }
`
