export const palette = {
  white: "#fff",
  gray: "#C4C4C4",
  grayLight: "#F8F7F7",
  main: "#00BA88",
  dark: "#0A0A0A",
  darkLight: "#1C1C1E",

  sidebar: "#20262b",
}
// 0A0A0A
