import React, { useCallback } from "react"

import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import { appHistory } from "infrastructure/application/history"

import * as S from "view/uikit/common/PageHeader/styled"
import { SvgIcon } from "view/uikit/base/SvgIcon"

export const PageHeader = ({ title, button, backLink }) => {
  const handlerBack = useCallback(() => {
    if (!backLink) return

    appHistory.push(backLink)
  }, [backLink])

  return (
    <S.Wrapper>
      {backLink && <SvgIcon component={ArrowBackIcon} onClick={handlerBack} />}
      <S.Typography variant="h5">{title}</S.Typography>
      {button}
    </S.Wrapper>
  )
}
