import React from "react"
import * as S from "./styled"

export const TopWidget = ({ label, value = 0 }) => {
  return (
    <S.Wrapper>
      <S.Title>{label}</S.Title>
      <S.Value>{value}</S.Value>
    </S.Wrapper>
  )
}
