import { pmLocal, PMTypeEnum } from "domain/application/postMessages"

const TIMEOUT = 1000 // сколько времени ждем пока ответит расширение

export const extensionService = {
  checkInstall: () => {
    return new Promise((resolve, reject) => {
      try {
        const timer = setTimeout(() => {
          clear()
          resolve(false)
        }, TIMEOUT)

        const handler = () => {
          clear()
          resolve(true)
        }

        const clear = () => {
          if (timer) clearTimeout(timer)
          pmLocal.unsubscribe(PMTypeEnum.ExtensionInstalled, handler)
        }

        pmLocal.subscribe(PMTypeEnum.ExtensionInstalled, handler)
        pmLocal.send(PMTypeEnum.CheckExtension)
      } catch (e) {
        reject(e)
      }
    })
  },
}
