import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"

export const StripeService = {
  async createSession(values) {
    try {
      const response = await apiService.post("/billing/stripe/sessions", values)

      return response.data
    } catch (e) {
      errorService.send(e)
      return null
    }
  },

  async updateSubscription(values) {
    try {
      const response = await apiService.put(
        "/billing/stripe/subscriptions",
        values
      )

      return response.data
    } catch (e) {
      errorService.send(e)
      return null
    }
  },

  async cancelSubscription() {
    try {
      const response = await apiService.post(`/billing/stripe/subscriptions`)

      return response.data
    } catch (e) {
      errorService.send(e)
      return null
    }
  },
}
