export const paths = {
  home: () => "/",

  login: () => "/login",
  signup: () => "/signup",
  signupComplete: () => "/signup-complete",
  forgot: () => "/forgot",

  billing: () => "/billing",
  transactions: () => "/transactions",
  invoices: () => "/invoices",

  profile: () => "/profile",
  users: () => "/users",

  feedback: () => "/feedback",
  inviteConfirm: () => "/invite-confirm",
  orgSettings: (id) => `/organization/${id}/settings`,

  productTours: () => "/product-tours",
  productTour: (id) => `/product-tours/${id}`,

  hotspots: () => "/hotspots",
  checklists: () => "/checklists",

  segments: () => "/segments",
  segmentCreate: () => "/segments/create",
  segmentItem: (id) => `/segments/${id}`,

  themes: () => "/themes",
  themeItem: (id) => `/themes/${id}`,

  // projects: () => "/projects",
  settings: () => "/settings",
}
