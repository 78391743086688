export const mockTooltip = {
  active: true,
  alignment: "bottom",
  backdrop: true,
  backdropPadding: 8,
  branding: { enabled: true, alignment: "left" },
  closeButton: true,
  content: {
    title: {
      enabled: true,
      text: "Title",
      type: "h1",
      alignment: "left",
    },
    buttons: { enabled: true, goBack: true },
    text: {
      enabled: true,
      value:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
      alignment: "left",
    },
  },
  placement: {
    type: "xpath",
    xpath: {
      path: "//HTML/BODY/DIV/BUTTON",
    },
    cssSelector: {
      selector: "",
    },
    timeout: 30,
  },
  skippable: true,
  type: "tooltip",
  waitElement: 4,
  width: 350,
  _id: "test-id",
}

export const mockModal = {
  active: true,
  alignment: "topLeft",
  backdrop: false,
  branding: { enabled: true, alignment: "left" },
  closeButton: true,
  content: {
    title: {
      enabled: true,
      text: "Welcome aboard, new buddy! ",
      type: "h1",
      alignment: "center",
    },
    media: { type: "image", enabled: true },
    image: {
      alignment: "center",
      fullWidth: true,
      height: 100,
      heightAuto: true,
      heightUnits: "%",
      hero: false,
      position: "top",
      url: "https://sun9-37.userapi.com/impf/c836338/v836338643/87535/GAQVvNgZjJs.jpg?size=600x282&quality=96&proxy=1&sign=7f7f92b1c9181620da370eb7f63dff80&type=album",
      width: 100,
      widthAuto: true,
      widthUnits: "%",
    },
    buttons: { enabled: true, goBack: true },
    text: {
      enabled: true,
      value:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
      alignment: "center",
    },
  },
  skippable: true,
  type: "modal",
  width: 500,
  _id: "test-id",
}
