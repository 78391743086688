import React, { useMemo } from "react"
import { Line } from "react-chartjs-2"
import merge from "deepmerge"

import "chartjs-adapter-moment"

import {
  CategoryScale,
  Chart as ChartJS,
  BarController,
  BarElement,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js"
import { getIntervalPeriod } from "infrastructure/utils/date"
import { DateIntervalEnum } from "view/pages/analytics/DashboardPage/enums"

ChartJS.register(
  BarController,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
)

const getFormat = (interval) => {
  switch (interval) {
    case DateIntervalEnum.Minute:
      return "HH:mm, DD MMM"
    case DateIntervalEnum.Minutes_15:
      return "HH:mm, DD MMM"
    case DateIntervalEnum.Hour:
      return "HH:mm, DD MMM"
    case DateIntervalEnum.Hours_4:
      return "HH:mm, DD MMM"
    case DateIntervalEnum.Day:
      return "DD MMM"
    case DateIntervalEnum.Week:
      return "DD MMM"
    case DateIntervalEnum.Month:
      return "MMM YYYY"
    case DateIntervalEnum.Quarter:
      return "MMM YYYY"
    default:
      return "DD MMM"
  }
}

export const ChartLine = ({ data, interval, options = {} }) => {
  const opts = useMemo(() => {
    const base = {
      responsive: true,
      maintainAspectRatio: false,

      spanGaps: false, // 1000 * 60 * 60 * 24 * 3,
      scales: {
        x: {
          type: "time",
          display: true,
          time: {
            tooltipFormat: getFormat(interval),
            unit: getIntervalPeriod(interval)[1],
            stepSize: getIntervalPeriod(interval)[0],
          },
          stacked: true,
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            major: {
              enabled: true,
            },
            // forces step size to be 50 units
            // stepSize: 50,
          },
        },
        // y: {
        //   type: "linear",
        //   display: true,
        //   position: "left",
        //   stacked: true,
        // },
      },
      tooltips: {
        mode: "index",
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        // title: {
        //   display: true,
        //   text: (ctx) => {
        //     const { axis = "xy", intersect, mode } = ctx.chart.options.interaction
        //     return `Mode: ${mode}, axis: ${axis}, intersect: ${intersect}`
        //   },
        // },
        legend: {
          display: false,
          position: "bottom",
        },
        filler: {
          propagate: true,
        },
      },
    }

    return merge(base, options)
  }, [interval, options])

  return <Line options={opts} data={data} type="line" />
}
