import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import merge from "deepmerge"

import {
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@material-ui/core"

import { themeSelectors } from "data/entities/theme/selectors"
import {
  themePublish,
  themeSetDefault,
  themeSetProperty,
  themeThunks,
} from "data/entities/theme/thunks"

import { Button } from "view/uikit/base/Button"
import { Loader } from "view/uikit/base/Loader"
import { PageHeader } from "view/uikit/common/PageHeader"
import { Accordion } from "view/uikit/base/Accordion"
import {
  mockModal,
  mockTooltip,
} from "view/pages/entities/ThemeItemPage/consts"
import { InputEOP } from "view/uikit/base/InputEOP"
import { ColorEOP } from "view/uikit/base/ColorEOP"
import { paths } from "view/router/paths"

import { PMService } from "infrastructure/services/PMService"
import { PMTypeEnum } from "domain/application/postMessages"
import { SaveIndicator } from "view/components/application/SaveIndicator"
import { projectSelectors } from "data/entities/project/selectors"
import * as S from "./styled"

const StepModeEnum = {
  Tooltip: "tooltip",
  Modal: "modal",
}

export const ThemeItemPage = () => {
  const dispatch = useDispatch()

  const [stepMode, setStepMode] = useState(StepModeEnum.Tooltip)
  const [backdrop, setBackdrop] = useState(true)

  const ref = useRef(null)

  const { id } = useParams()
  const theme = useSelector(themeSelectors.current)
  const isLoading = useSelector(themeSelectors.isLoading)
  const project = useSelector(projectSelectors.currentFull)

  useEffect(() => {
    if (!id) return

    dispatch(themeThunks.fetchById(id))
  }, [id])

  useEffect(() => {
    if (!theme) return
    if (!ref.current) return

    const pmFrame = new PMService(ref.current.contentWindow)

    pmFrame.send(PMTypeEnum.ThemeApply, theme)
  }, [theme])

  useEffect(() => {
    if (!ref.current) return

    const pmFrame = new PMService(ref.current.contentWindow)

    pmFrame.send(
      PMTypeEnum.RenderStep,
      stepMode === StepModeEnum.Tooltip
        ? merge.all([mockTooltip, { backdrop }])
        : merge.all([mockModal, { backdrop }])
    )
  }, [stepMode, backdrop, theme])

  const trimPx = useCallback((value) => {
    return value.replace("px", "")
  }, [])

  const onIframeLoad = () => {
    if (!ref.current) return

    const pmFrame = new PMService(ref.current.contentWindow)

    pmFrame.send(PMTypeEnum.SetPID, {
      pid: project._id,
    })

    pmFrame.send(PMTypeEnum.RenderStep, {
      ...mockTooltip,
      backdrop,
    })

    pmFrame.send(PMTypeEnum.ThemeApply, theme)
  }

  const handlerFocus = (mode) => {
    if (mode !== stepMode) setStepMode(mode)
  }

  const saveProps = (props, v, suffix = "") => {
    const newValue = typeof v === "boolean" ? v : `${v}${suffix}`

    dispatch(
      themeSetProperty({
        themeId: theme._id,
        property: props,
        value: newValue,
      })
    )
  }

  const handlerPublish = () => {
    dispatch(themePublish({ themeId: theme._id }))
  }

  const handlerSetDefault = () => {
    dispatch(themeSetDefault({ themeId: theme._id }))
  }

  if (!theme || !project) return <Loader />

  return (
    <S.Wrapper>
      <PageHeader
        title={theme.name}
        button={
          <>
            <Button
              disabled={theme.updated === theme.published}
              onClick={handlerPublish}
            >
              Publish
            </Button>

            {project.defaultThemeId !== theme._id ? (
              <S.SetDefault
                disabled={isLoading}
                onClick={handlerSetDefault}
                variant="outlined"
              >
                Set default
              </S.SetDefault>
            ) : (
              <S.DefaultText>Default theme</S.DefaultText>
            )}
          </>
        }
        backLink={paths.themes()}
      />
      <S.Content>
        <S.Left>
          <Accordion title="Common">
            <S.Block>
              <S.RowFields>
                {/* <FormControlLabel */}
                {/*  control={ */}
                {/*    <Switch */}
                {/*      color="primary" */}
                {/*      checked={theme.type === "dark"} */}
                {/*      onChange={(e, v) => saveProps("type", v)} */}
                {/*    /> */}
                {/*  } */}
                {/*  label={theme.type === "dark" ? "Dark theme" : "Light theme"} */}
                {/* /> */}
                {/* <FormControlLabel */}
                {/*  control={ */}
                {/*    <Switch */}
                {/*      color="primary" */}
                {/*      checked */}
                {/*      onChange={(e, v) => { */}
                {/*        console.log("set default") */}
                {/*      }} */}
                {/*    /> */}
                {/*  } */}
                {/*  label="Default" */}
                {/* /> */}
                <ColorEOP
                  label="Background color"
                  color={theme.common.bg}
                  onSave={(v) => saveProps("common.bg", v)}
                />
                <InputEOP
                  label="Radius"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.common.radius)}
                  onSave={(v) => saveProps("common.radius", v, "px")}
                />
                <InputEOP
                  label="Margin between block"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.common.blockMarginBottom)}
                  onSave={(v) => saveProps("common.blockMarginBottom", v, "px")}
                />
              </S.RowFields>
            </S.Block>
          </Accordion>

          <Accordion title="Backdrop">
            <S.Block>
              <S.RowFields>
                <FormControlLabel
                  label={backdrop ? "Hide backdrop" : "Show backdrop"}
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={backdrop}
                      onChange={() => setBackdrop(!backdrop)}
                    />
                  }
                />
              </S.RowFields>
              <S.RowFields>
                <ColorEOP
                  label="Background color"
                  color={theme.backdrop.color}
                  onSave={(v) => saveProps("backdrop.color", v)}
                />
                <InputEOP
                  label="Opacity"
                  onlyDigit
                  value={theme.backdrop.opacity}
                  onSave={(v) => saveProps("backdrop.opacity", v)}
                />
              </S.RowFields>
            </S.Block>
          </Accordion>

          <Accordion title="Typography">
            <S.SubBlock>
              <h4>Body</h4>
              <S.RowFields>
                <ColorEOP
                  label="Text color"
                  color={theme.typography.body.color}
                  onSave={(v) => saveProps("typography.body.color", v)}
                />
                <InputEOP
                  label="Font size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.typography.body.fontSize)}
                  onSave={(v) => saveProps("typography.body.fontSize", v, "px")}
                />
              </S.RowFields>
            </S.SubBlock>
            <S.SubBlock>
              <h4>H1</h4>
              <S.RowFields>
                <ColorEOP
                  label="Text color"
                  color={theme.typography.h1.color}
                  onSave={(v) => saveProps("typography.h1.color", v)}
                />
                <InputEOP
                  label="Font size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.typography.h1.fontSize)}
                  onSave={(v) => saveProps("typography.h1.fontSize", v, "px")}
                />
              </S.RowFields>
            </S.SubBlock>
            <S.SubBlock>
              <h4>H2</h4>
              <S.RowFields>
                <ColorEOP
                  label="Text color"
                  color={theme.typography.h2.color}
                  onSave={(v) => saveProps("typography.h2.color", v)}
                />
                <InputEOP
                  label="Font size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.typography.h2.fontSize)}
                  onSave={(v) => saveProps("typography.h2.fontSize", v, "px")}
                />
              </S.RowFields>
            </S.SubBlock>
            <S.SubBlock>
              <h4>H3</h4>
              <S.RowFields>
                <ColorEOP
                  label="Text color"
                  color={theme.typography.h3.color}
                  onSave={(v) => saveProps("typography.h3.color", v)}
                />
                <InputEOP
                  label="Font size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.typography.h3.fontSize)}
                  onSave={(v) => saveProps("typography.h3.fontSize", v, "px")}
                />
              </S.RowFields>
            </S.SubBlock>
            <S.SubBlock>
              <h4>H4</h4>
              <S.RowFields>
                <ColorEOP
                  label="Text color"
                  color={theme.typography.h4.color}
                  onSave={(v) => saveProps("typography.h4.color", v)}
                />
                <InputEOP
                  label="Font size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.typography.h4.fontSize)}
                  onSave={(v) => saveProps("typography.h4.fontSize", v, "px")}
                />
              </S.RowFields>
            </S.SubBlock>
          </Accordion>

          <Accordion title="Button">
            <S.SubBlock>
              <h4>Common</h4>
              <S.RowFields>
                <InputEOP
                  label="Font size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.button.fontSize)}
                  onSave={(v) => saveProps("button.fontSize", v, "px")}
                />
                <InputEOP
                  label="Radius"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.button.radius)}
                  onSave={(v) => saveProps("button.radius", v, "px")}
                />
              </S.RowFields>
            </S.SubBlock>
            <S.SubBlock>
              <h4>Primary</h4>
              <S.RowFields>
                <ColorEOP
                  label="Background color"
                  color={theme.button.primary.bg}
                  onSave={(v) => saveProps("button.primary.bg", v)}
                />
                <ColorEOP
                  label="Text color"
                  color={theme.button.primary.color}
                  onSave={(v) => saveProps("button.primary.color", v)}
                />
                <ColorEOP
                  label="Hover background color"
                  color={theme.button.primary.hover.bg}
                  onSave={(v) => saveProps("button.primary.hover.bg", v)}
                />
                <ColorEOP
                  label="Hover text color"
                  color={theme.button.primary.hover.color}
                  onSave={(v) => saveProps("button.primary.hover.color", v)}
                />
                <ColorEOP
                  label="Border color"
                  color={theme.button.primary.border}
                  onSave={(v) => saveProps("button.primary.border", v)}
                />
              </S.RowFields>
            </S.SubBlock>
            <S.SubBlock>
              <h4>Secondary</h4>
              <S.RowFields>
                <ColorEOP
                  label="Background color"
                  color={theme.button.secondary.bg}
                  onSave={(v) => saveProps("button.secondary.bg", v)}
                />
                <ColorEOP
                  label="Text color"
                  color={theme.button.secondary.color}
                  onSave={(v) => saveProps("button.secondary.color", v)}
                />
                <ColorEOP
                  label="Hover background color"
                  color={theme.button.secondary.hover.bg}
                  onSave={(v) => saveProps("button.secondary.hover.bg", v)}
                />
                <ColorEOP
                  label="Hover text color"
                  color={theme.button.secondary.hover.color}
                  onSave={(v) => saveProps("button.secondary.hover.color", v)}
                />
                <ColorEOP
                  label="Border color"
                  color={theme.button.secondary.border}
                  onSave={(v) => saveProps("button.secondary.border", v)}
                />
              </S.RowFields>
            </S.SubBlock>
          </Accordion>

          <Accordion title="Tooltip">
            <S.Block>
              <S.RowFields>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={theme.tooltip.shadow}
                      onChange={(e, v) => {
                        handlerFocus(StepModeEnum.Tooltip)
                        saveProps("tooltip.shadow", v)
                      }}
                    />
                  }
                  label="Shadow"
                />
                <InputEOP
                  label="Padding"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.tooltip.padding)}
                  onSave={(v) => saveProps("tooltip.padding", v, "px")}
                  onFocus={() => handlerFocus(StepModeEnum.Tooltip)}
                />
                <InputEOP
                  label="Arrow size"
                  end="px"
                  onlyDigit
                  value={trimPx(theme.tooltip.arrow.size)}
                  onSave={(v) => saveProps("tooltip.arrow.size", v, "px")}
                  onFocus={() => handlerFocus(StepModeEnum.Tooltip)}
                />
                <InputEOP
                  label="Z-Index"
                  onlyDigit
                  value={theme.tooltip.zIndex}
                  onSave={(v) => saveProps("tooltip.zIndex", v)}
                  onFocus={() => handlerFocus(StepModeEnum.Tooltip)}
                />
              </S.RowFields>
            </S.Block>
          </Accordion>

          <Accordion title="Modal">
            <S.RowFields>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={theme.modal.shadow}
                    onChange={(e, v) => {
                      handlerFocus(StepModeEnum.Modal)
                      saveProps("modal.shadow", v)
                    }}
                  />
                }
                label="Shadow"
              />
              <InputEOP
                label="Padding"
                end="px"
                onlyDigit
                value={trimPx(theme.modal.padding)}
                onSave={(v) => saveProps("modal.padding", v, "px")}
                onFocus={() => handlerFocus(StepModeEnum.Modal)}
              />
              <InputEOP
                label="Margin to window border"
                end="px"
                onlyDigit
                value={trimPx(theme.modal.margin)}
                onSave={(v) => saveProps("modal.margin", v, "px")}
                onFocus={() => handlerFocus(StepModeEnum.Modal)}
              />
              <InputEOP
                label="Z-Index"
                onlyDigit
                value={theme.modal.zIndex}
                onSave={(v) => saveProps("modal.zIndex", v)}
                onFocus={() => handlerFocus(StepModeEnum.Modal)}
              />
            </S.RowFields>
          </Accordion>
        </S.Left>

        <S.Right>
          <S.Box>
            <S.Buttons>
              <S.ButtonsLeft>
                <ButtonGroup variant="contained" size="small">
                  <Button
                    onClick={() => {
                      setStepMode(StepModeEnum.Tooltip)
                    }}
                    color={
                      stepMode === StepModeEnum.Tooltip ? "primary" : "default"
                    }
                  >
                    Tooltip
                  </Button>
                  <Button
                    onClick={() => {
                      setStepMode(StepModeEnum.Modal)
                    }}
                    color={
                      stepMode === StepModeEnum.Modal ? "primary" : "default"
                    }
                  >
                    Modal
                  </Button>
                </ButtonGroup>

                {/* <FormControlLabel */}
                {/*  label="Backdrop" */}
                {/*  control={ */}
                {/*    <Checkbox */}
                {/*      size="small" */}
                {/*      color="primary" */}
                {/*      checked={backdrop} */}
                {/*      onChange={() => setBackdrop(!backdrop)} */}
                {/*    /> */}
                {/*  } */}
                {/* /> */}
              </S.ButtonsLeft>
              <S.ButtonsRight>
                <SaveIndicator />
                {/* <Button>Save</Button> */}
                {/* <Button>Publish</Button> */}
              </S.ButtonsRight>
            </S.Buttons>

            <S.Iframe
              ref={ref}
              src={`${process.env.REACT_APP_HOST_THEME}?uoThemePreview`}
              title="Theme preview"
              onLoad={onIframeLoad}
              frameBorder="0"
            />
          </S.Box>
        </S.Right>
      </S.Content>
    </S.Wrapper>
  )
}
