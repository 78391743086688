import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"

import { entityName } from "data/entities/consts"
import { billingSelectors } from "data/entities/billing/selectors"
import { errorService } from "infrastructure/services/ErrorService"
import { organizationSelectors } from "data/entities/organization/selectors"

export const billingFetchPlanList = createAsyncThunk(
  `${entityName.billing}/fetchPlanList`,
  async (langCode, { rejectWithValue }) => {
    try {
      const result = await apiService.get(`/billing/plans`)

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateSubscription = createAsyncThunk(
  `${entityName.billing}/updateSubscription`,
  async (paddlePlanId, { rejectWithValue, getState }) => {
    try {
      const state = getState()

      const subscription = billingSelectors.subscription(state)

      const result = await apiService.put(`/subscriptions`, {
        subscriptionId: subscription._id,
        paddlePlanId,
      })

      return result.data
    } catch (e) {
      errorService.send(e)
      return rejectWithValue(e)
    }
  }
)

export const refetchSubscription = createAsyncThunk(
  `${entityName.billing}/refetchSubscription`,
  async (paddlePlanId, { rejectWithValue, getState }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      const result = await apiService.get(
        `/subscriptions?orgId=${organization._id}`
      )

      return result.data
    } catch (e) {
      errorService.send(e)
      return rejectWithValue(e)
    }
  }
)
