import { FORM_ERROR } from "final-form"
import moment from "moment"

export const nameToKey = (name) => {
  return name.toLowerCase().replaceAll(" ", "-").replaceAll(".", "-")
}

export const enumToOptions = (obj) => {
  return Object.keys(obj).map((key) => {
    return {
      value: obj[key],
      label: key,
    }
  })
}

export const mark = (key, search) => {
  return key.replace(search, `<i>${search}</i>`)
}

export const uniq = (array) => {
  return [...new Set([...array])]
}

export const delay = (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time)
  })
}

export const genId = () => {
  return Math.floor(Math.random() * 100000)
}

export const isEmpty = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}

export const inFrame = () => {
  let isFramed
  try {
    isFramed =
      window !== window.top ||
      document !== window.top.document ||
      window.self.location !== window.top.location
  } catch (e) {
    isFramed = true
  }
  return isFramed
}

export const toFinalFormErrors = (e) => {
  let errors = {}

  if (typeof e === "string") {
    errors[FORM_ERROR] = e
  } else {
    if (e) errors = e
    if (e.common) errors[FORM_ERROR] = e.common
  }
  return errors
}

export const setByPath = (root, path, value) => {
  const parts = path.split(".")
  const end = parts.pop()

  const obj = parts.reduce((acc, part) => {
    if (!acc[part]) acc[part] = {}
    return acc[part]
  }, root)

  obj[end] = value
}

export const getByPath = (root, path) => {
  const parts = path.split(".")
  const end = parts.pop()

  const obj = parts.reduce((acc, part) => {
    if (!acc[part]) acc[part] = {}
    return acc[part]
  }, root)

  return obj[end]
}
