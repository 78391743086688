import React, { useCallback } from "react"
import { Form } from "react-final-form"

import { toFinalFormErrors } from "infrastructure/utils"
import { pmAuth, pmHandler } from "domain/application/postMessages"
import { validate } from "view/forms/identity/LoginForm/validate"

const initialValues = {}

export const Container = ({ component: Component, componentProps }) => {
  const handlerSubmit = useCallback(async (values) => {
    try {
      await pmHandler(pmAuth, "auth:login", {
        email: values.email,
        password: values.password,
      })
    } catch (e) {
      return toFinalFormErrors(e)
    }
    return {}
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} {...componentProps} />}
    />
  )
}
