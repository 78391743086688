import styled from "styled-components"
import { Box as MuiBox } from "view/uikit/base/Box"

export const Text = styled.div`
  margin-bottom: 1rem;
`

export const Box = styled(MuiBox)`
  border: 1px solid red;
`
