import { useEffect, useState } from "react"
import { WebSocketClient } from "infrastructure/services/WebSocketClient"
import { WsTypeEnum } from "domain/enums"
import { useContextSelector } from "use-context-selector"
import { BillingContext } from "domain/billing/BillingContext"
import { billingSelectors } from "domain/billing/billingSelectors"

export const WebSocketContainer = () => {
  const [wsClient, setWsClient] = useState(null)

  const updateBilling = useContextSelector(
    BillingContext,
    billingSelectors.update
  )

  useEffect(() => {
    const token = sessionStorage.getItem("auth")
    const ws = new WebSocketClient(token)
    setWsClient(ws)
  }, [])

  useEffect(() => {
    if (!wsClient) return

    wsClient.subscribe(WsTypeEnum.SubscriptionUpdated, (payload) => {
      updateBilling({
        billingAccount: payload.billingAccount,
        subscription: payload.subscription,
      })
    })
  }, [wsClient, updateBilling])

  return null
}
