import { useSnackbar } from "notistack"

export const useNotice = () => {
  const { enqueueSnackbar } = useSnackbar()

  const errorNotice = (msg) => {
    enqueueSnackbar(msg, {
      variant: "error",
    })
  }

  const successNotice = (msg) => {
    enqueueSnackbar(msg, {
      variant: "success",
    })
  }

  return { errorNotice, successNotice }
}
