import { createReducer } from "@reduxjs/toolkit"

import { ReducersFabric } from "data/entities/ReducersFabric"
import { initialState } from "data/entities/theme/consts"
import { themeActions } from "data/entities/theme/actions"
import {
  themePublish,
  themeSetDefault,
  themeSetProperty,
  themeThunks,
} from "data/entities/theme/thunks"
import { setByPath } from "infrastructure/utils"
import { adapter } from "data/entities/theme/selectors"
import { logout } from "data/entities/identity/thunks"

export const themeReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: themeActions,
    thunks: themeThunks,
    initState: initialState,
  }).build()

  builder.addCase(themeSetProperty.pending, (state, action) => {
    const { themeId, property, value } = action.meta.arg

    const prevValue = state.entities[themeId][property]

    state.reqCache[action.meta.requestId] = {
      themeId,
      property,
      value: prevValue,
    }

    setByPath(state.entities[themeId], property, value)
  })

  builder.addCase(themeSetProperty.fulfilled, (state, action) => {
    const { id, themeUpdated } = action.payload

    state.reqCache[action.meta.requestId] = undefined

    adapter.updateOne(state, {
      id,
      changes: {
        updated: themeUpdated,
      },
    })
  })

  builder.addCase(themeSetProperty.rejected, (state, action) => {
    const { themeId, property } = action.meta.arg

    setByPath(
      state.entities[themeId],
      property,
      state.reqCache[action.meta.requestId].value
    )
  })

  builder.addCase(themePublish.fulfilled, (state, action) => {
    const { themeId, ...rest } = action.payload

    adapter.updateOne(state, {
      id: themeId,
      changes: rest,
    })
  })

  builder.addCase(themeSetDefault.pending, (state, action) => {
    state.isLoading = true
  })

  builder.addCase(themeSetDefault.fulfilled, (state, action) => {
    state.isLoading = false
  })

  builder.addCase(themeSetDefault.rejected, (state, action) => {
    state.isLoading = false
  })

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
