import { AccountTypeEnum } from "domain/billing/enums"

export const billingSelectors = {
  billingAccount: (state) => state.billingAccount,
  subscription: (state) => state.subscription,

  limit: (state) => state.billingAccount?.plan?.limit || {},
  isTrial: (state) => {
    return state.billingAccount.type === AccountTypeEnum.Trial
  },

  currenPlan: (state) => state.billingAccount?.plan || null,

  invoices: (state) => state.invoices,

  totalDisabled: (state) => state.totalDisabled,

  update: (state) => state.update,
}
