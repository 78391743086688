export const AccountTypeEnum = {
  None: "None",
  Free: "Free",
  Trial: "Trial",
  OneTime: "OneTime",
  Subscription: "Subscription",
  LTD: "LTD",
}

export const SubscriptionPeriodEnum = {
  Monthly: "Monthly",
  Yearly: "Yearly",
}

export const PlanGroupEnum = {
  Monthly: "Monthly",
  Yearly: "Yearly",
  LTD: "Ltd",
}

export const PlanTypeEnum = {
  Free: "Free",
  OneTime: "OneTime",
  Subscription: "Subscription",
}
