import isURL from "validator/es/lib/isURL"

export const validate = ({ url, name }) => {
  const errors = {}

  if (!name) errors.name = "Required"

  if (!url) {
    errors.url = "Required"
  } else {
    if (!(url.startsWith("http://") || url.startsWith("https://")))
      errors.url = "Please use a valid Url starting with http:// or https://"

    if (
      !isURL(url, {
        protocols: ["http", "https"],
      })
    )
      errors.url = "Please enter a valid URL"
  }

  return errors
}
