import { createReducer } from "@reduxjs/toolkit"

import { ReducersFabric } from "data/entities/ReducersFabric"
import { initialState } from "data/entities/invoice/consts"
import { invoiceThunks } from "data/entities/invoice/thunks"
import { invoiceActions } from "data/entities/invoice/actions"
import { logout } from "data/entities/identity/thunks"

export const invoiceReducer = createReducer(initialState, (builder) => {
  new ReducersFabric({
    builder,
    actions: invoiceActions,
    thunks: invoiceThunks,
    initState: initialState,
  }).build()

  builder.addCase(logout.fulfilled, (state, action) => {
    return initialState
  })
})
