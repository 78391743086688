// import { createAction } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"
import { ActionsFabric } from "data/entities/ActionsFabric"

export const organizationActions = {
  ...new ActionsFabric({ entityName: entityName.organizations }).build(),
}

// export const switchOrganization = createAction(
//   `${entityName.organizations}/switchOrganization`
// )
