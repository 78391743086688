import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import { store } from "data/store"

import { App } from "view/App"
import { ErrorBoundary } from "view/ErrorBoundary"
import "view/themes/index.css"
import { WebSocketClient } from "infrastructure/services/WebSocketClient"

const en = require("locales/en.json")
const ru = require("locales/ru.json")

if (process.env && process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://b8b515cdbf89430ba8666cda138e1414@o987967.ingest.sentry.io/5945060",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
}

i18next
  .use(initReactI18next)
  .init({
    lng: "en",
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
        spareLocation: en,
      },
    },
    keySeparator: false,
    fallbackNS: "spareLocation",
    returnEmptyString: false,
    returnNull: false,
    react: {
      transSupportBasicHtmlNodes: true,
    },
  })
  .then(() => {
    ReactDOM.render(
      <ErrorBoundary>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>,
      document.getElementById("root")
    )
  })
