import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { dataProvider } from "domain/core/dataProvider"

export const FetchListContainer = ({
  url,
  context,
  selectors,
  params,
  options = {},
}) => {
  const list = useContextSelector(context, selectors.list)
  const update = useContextSelector(context, selectors.update)

  const hashParams = JSON.stringify({
    ...params,
  })

  useEffect(() => {
    update({
      list: {
        ...list,
        isLoading: true,
      },
    })

    dataProvider
      .getList(url, params, options)
      .then((response) => {
        update({
          list: {
            data: response.data,
            error: null,
            isLoading: false,
          },
        })
      })
      .catch((err) => {
        update({
          list: {
            ...list,
            error: err,
            isLoading: false,
          },
        })
      })
  }, [hashParams])

  return null
}
