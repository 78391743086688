import styled from "styled-components"
import {
  InputAdornment as MuiInputAdornment,
  Typography as MuiTypography,
} from "@material-ui/core"

export const Wrapper = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 1rem;
`

export const Typography = styled(MuiTypography)`
  margin-bottom: 1rem;
`

export const Form = styled.form``

export const SubTitle = styled.div`
  padding: 1rem 0;
  font-size: 0.9rem;
  color: #adadad;
`

export const Text = styled.div`
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: #adadad;
`

export const InputAdornment = styled(MuiInputAdornment)`
  span {
    height: 1.5rem;
  }

  svg {
    fill: ${({ $emailVerified }) => ($emailVerified ? "green" : "red")};
  }
`
