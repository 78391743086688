import React from "react"
import MuiTextField from "@material-ui/core/TextField"
import { InputAdornment } from "@material-ui/core"

export const TextField = ({ children, end, ...props }) => {
  return (
    <MuiTextField
      variant="filled"
      InputProps={{
        endAdornment: end ? (
          <InputAdornment position="end">{end}</InputAdornment>
        ) : null,
        disableUnderline: true,
      }}
      {...props}
    />
  )
}
