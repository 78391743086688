import styled from "styled-components"
import { DialogActions as MuiDialogActions } from "@material-ui/core"

export const DialogActions = styled(MuiDialogActions)`
  padding: 1rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
    width: 100px;
    margin-bottom: 32px;
  }
`

export const ButtonBlock = styled.div`
  text-align: center;
  padding-top: 24px;
`

export const Button = styled.button`
  font-size: 16px;
`
