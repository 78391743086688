import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

import { Transition } from "view/modals/Transition"

import { Button } from "view/uikit/base/Button"
import Image from "./img.png"
import * as S from "./styled"

export const ExtensionInstallModal = ({ open, onClose }) => {
  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xs"
      onClose={onClose}
    >
      <DialogTitle>Install chrome extension</DialogTitle>
      <DialogContent>
        <S.Content>
          <img src={Image} width={100} height={100} alt="chrome logo" />
          You have to install the the Chrome extension to work with the app.
          <S.ButtonBlock>
            <Button
              size="large"
              onClick={() =>
                window.open(process.env.REACT_APP_EXTENSION_URL, "__blank")
              }
            >
              Install chrome extension
            </Button>
          </S.ButtonBlock>
        </S.Content>
      </DialogContent>
    </Dialog>
  )
}
