import { palette } from "view/themes/light/palette"

export const lightTheme = {
  primary: palette.main,
  mainBg: palette.grayLight,
  error: {
    color: palette.errorAccent,
  },
  button: {
    bg: palette.main,
    color: palette.white,
    shadowColor: palette.gray,

    contained: {
      disabled: {
        color: palette.gray,
      },
    },
  },
  avatar: {
    bg: palette.main,
    color: palette.white,
  },
  grid: {
    search: {
      bg: palette.white,
      icon: palette.gray,
    },
    content: {
      color: palette.gray,
      date: {
        color: palette.grey600,
      },
    },
    item: {
      hover: {
        bg: palette.grey50,
      },
    },
  },
  saveIndicator: {
    color: palette.grey400,
    activeColor: palette.main,
  },
}
