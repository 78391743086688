import React from "react"
import { Field } from "react-final-form"
import FormControl from "@material-ui/core/FormControl"

import { InputField } from "view/uikit/formFields/InputField"
import { FormButtons } from "view/uikit/common/FormButtons"
import { FormError } from "view/uikit/common/FormError"

import { Container } from "./container"
import * as S from "./styled"

const Component = ({ handleSubmit, submitError, submitting, cbCancel }) => {
  return (
    <S.Form onSubmit={handleSubmit}>
      {/* <div> */}
      {/*  Projects allow you to scope translation strings to a specific */}
      {/*  application in your organization. */}
      {/* </div> */}
      {/* <br /> */}
      <FormControl fullWidth margin="normal">
        <Field
          component={InputField}
          name="name"
          label="Name"
          helperText="Example: Google"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={InputField}
          name="url"
          label="Url"
          helperText="Example: https://google.com"
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        cancelBtn={{
          disabled: submitting,
          title: "Cancel",
        }}
        cbCancel={cbCancel}
        submitBtn={{
          disabled: submitting,
          title: "Create",
        }}
      />
    </S.Form>
  )
}

export const ProjectCreateForm = ({ cbCancel }) => (
  <Container component={Component} componentProps={{ cbCancel }} />
)
