import styled from "styled-components"

import { Avatar as MuiAvatar } from "@material-ui/core"

export const Wrapper = styled(MuiAvatar)`
  width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.avatar.bg};
  color: ${({ theme }) => theme.avatar.color};
`
