import * as Sentry from "@sentry/react"

export const errorService = {
  send: (e) => {
    if (process.env && process.env.NODE_ENV === "production") {
      Sentry.captureException(e)
    } else {
      // eslint-disable-next-line no-console
      console.error("[ErrorService]", e)
    }
  },
}
