import styled, { keyframes } from "styled-components"

const foldCubeAngle = keyframes`
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${({ dark }) => (dark ? "#101012" : "transparent")};
`

export const WrapperCube = styled.div`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);

  & > div {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${({ color }) => color};
      animation: ${foldCubeAngle} 2.4s infinite linear both;
      transform-origin: 100% 100%;
    }
  }
`

export const Cube1 = styled.div``
export const Cube2 = styled.div`
  transform: scale(1.1) rotateZ(90deg) !important;

  &:before {
    animation-delay: 0.3s !important;
  }
`

export const Cube3 = styled.div`
  transform: scale(1.1) rotateZ(180deg) !important;

  &:before {
    animation-delay: 0.6s !important;
  }
`
export const Cube4 = styled.div`
  transform: scale(1.1) rotateZ(270deg) !important;

  &:before {
    animation-delay: 0.9s !important;
  }
`
