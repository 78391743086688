import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  //padding-bottom: 1rem;

  button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`
