import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"

import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import { Grid } from "view/uikit/common/Grid"
import { GridFilter } from "view/uikit/common/Grid/GridFilter"
import { Button } from "view/uikit/base/Button"

import { paths } from "view/router/paths"
import { invoiceThunks } from "data/entities/invoice/thunks"
import { invoiceSelectors } from "data/entities/invoice/selectors"
import { invoiceActions } from "data/entities/invoice/actions"
import { InvoicesRefetchContainer } from "domain/billing/containers/InvoicesRefetchContainer"
import { SortEnum } from "data/enums/SortEnum"
import { GridRows } from "./GridRows"

const statusOptions = [
  {
    label: "Any",
    value: "",
  },
  {
    label: "Draft",
    value: 1,
  },
  {
    label: "Published",
    value: 2,
  },
  // {
  //   label: "Archived",
  //   value: GuideStatusEnum.Archived,
  // },
]

export const InvoiceListPage = memo(() => {
  const dispatch = useDispatch()

  const filter = useSelector(invoiceSelectors.filter)

  const dirtFilter = filter.status || filter.search

  const clearFilter = async () => {
    await dispatch(invoiceActions.clearFilter())
  }

  const Filter = (
    <>
      <GridFilter
        name="status"
        label="Status"
        value={statusOptions.find((o) => o.value === filter.status)}
        options={statusOptions}
        setFilter={invoiceActions.setFilter}
      />

      {dirtFilter && (
        <Button
          color="secondary"
          variant="text"
          onClick={clearFilter}
          startIcon={<HighlightOffIcon />}
        >
          Clear filters
        </Button>
      )}
    </>
  )

  const sortOptions = [
    {
      label: "ID",
      value: SortEnum.Id,
    },
    {
      label: "Date create",
      value: SortEnum.DateCreate,
    },
    {
      label: "Date update",
      value: SortEnum.DateUpdate,
    },
  ]

  return (
    <>
      <InvoicesRefetchContainer />

      <Grid
        title="Invoices"
        backLink={paths.billing()}
        // filter={Filter}
        hideSearch
        GridRows={GridRows}
        sortOptions={sortOptions}
        thunks={invoiceThunks}
        selectors={invoiceSelectors}
        actions={invoiceActions}
      />
    </>
  )
})
