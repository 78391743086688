import styled, { css, keyframes } from "styled-components"

const rotating = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 75px;

  svg {
    fill: ${({ theme, saving }) =>
      saving ? theme.saveIndicator.color : theme.saveIndicator.activeColor};

    ${({ saving }) =>
      saving
        ? css`
            animation: ${rotating} 1s infinite linear both;
          `
        : ""};
  }
`

export const Label = styled.div`
  margin-left: 4px;
  font-size: 14px;
`
