import React from "react"
import { Field } from "react-final-form"

import { Button, FormControl, Typography } from "@material-ui/core"

import { InputField } from "view/uikit/formFields/InputField"
import { paths } from "view/router/paths"
import { Link } from "view/uikit/base/Link"
import { FormError } from "view/uikit/common/FormError"

import { Container } from "./container"

import * as S from "./styled"

const Component = ({ handleSubmit, submitError, submitting, success }) => {
  return (
    <S.Wrapper>
      <Typography variant="h4" component="h3" align="center">
        {success ? "Recovery link sent" : "Forgot"}
      </Typography>

      {!success ? (
        <S.Form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <Field component={InputField} name="email" label="Email" />
          </FormControl>

          {submitError && (
            <FormControl fullWidth margin="normal">
              <FormError>{submitError}</FormError>
            </FormControl>
          )}

          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            type="submit"
          >
            Send me the link
          </Button>
        </S.Form>
      ) : (
        <div>go to your inbox</div>
      )}

      <S.Text>
        Back to <Link to={paths.login()}>Login</Link>
      </S.Text>
    </S.Wrapper>
  )
}

export const ForgotForm = () => {
  return <Container component={Component} />
}
