import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch } from "react-redux"
import { FORM_ERROR } from "final-form"

import { errorService } from "infrastructure/services/ErrorService"
import { useNotice } from "view/hooks/application/useNotice"
import { projectThunks, setCurrentProject } from "data/entities/project/thunks"
import { validate } from "./validate"

const initialValues = {}

export const Container = ({ component: Component, componentProps }) => {
  const dispatch = useDispatch()
  const { successNotice } = useNotice()

  const handlerSubmit = useCallback(
    async (values, { reset }) => {
      try {
        const result = await dispatch(projectThunks.create(values))

        if (result.type === projectThunks.create.fulfilled().type) {
          componentProps.cbCancel()
          successNotice("Project has been created!")

          await dispatch(setCurrentProject(result.payload._id))

          return {}
        }

        if (result.type === projectThunks.create.rejected().type) {
          const { response } = result.payload

          if (response?.status === 400) {
            if (
              response.data.code === "not-unique" &&
              response.data.param === "slug"
            ) {
              return {
                name: "Name already used",
              }
            }
          }

          if (
            response?.status === 403 &&
            response.data.code === "account-is-not-active"
          ) {
            return {
              [FORM_ERROR]: "Subscription invalid upgrade your plan!",
            }
          }
        }

        return {
          [FORM_ERROR]: "Server error",
        }
      } catch (e) {
        errorService.send(e)
        return {
          [FORM_ERROR]: "Server error",
        }
      }
    },
    [componentProps]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} {...componentProps} />}
    />
  )
}
