import React from "react"
import { useDispatch, useSelector } from "react-redux"

import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import { MenuItem, Popover } from "@material-ui/core"

import { SortEnum } from "data/enums/SortEnum"

import { useOpenable } from "view/hooks/application/useOpenable"
import { Button } from "view/uikit/base/Button"

import * as S from "./styled"

const options = [
  {
    label: "Name",
    value: SortEnum.Name,
  },
  {
    label: "Date create",
    value: SortEnum.DateCreate,
  },
  {
    label: "Date update",
    value: SortEnum.DateUpdate,
  },
]

export const GridSort = ({ selectors, setSort, disabled, sortOptions }) => {
  const dispatch = useDispatch()
  const sort = useSelector(selectors.sort)
  const { open, onOpen, onClose, anchorEl } = useOpenable(false)

  const handleOpen = (e) => {
    if (disabled) return

    onOpen(e)
  }

  const handleChange = async (value) => {
    await dispatch(setSort(value))
    onClose()
  }

  const opts = sortOptions || options

  return (
    <>
      <Button
        color="inherit"
        variant="text"
        disabled={disabled}
        onClick={handleOpen}
        endIcon={<KeyboardArrowDownOutlinedIcon />}
      >
        Sort: <S.Value>{opts.find((s) => s.value === sort)?.label}</S.Value>
      </Button>
      <Popover
        id="grid-sort"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {opts.map((o) => (
          <MenuItem key={o.value} onClick={() => handleChange(o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
