import { entityName } from "data/entities/consts"

export const applicationSelectors = {
  theme: (state) => state[entityName.application].theme,
  clientState: (state) => state[entityName.application].clientState,
  initApp: (state) => state[entityName.application].initApp,
  isLoaded: (state) => state[entityName.application].isLoaded,
  browser: (state) => state[entityName.application].browser,
  saveIndicator: (state) => state[entityName.application].saveIndicator,
  extensionInstalled: (state) =>
    state[entityName.application].extensionInstalled,
}
