export const MuiFilledInput = (theme) => ({
  root: {
    backgroundColor: "#f8f8f8",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    "&$focused": {
      backgroundColor: "#f2f2f2",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.04);",
    },
  },
})
