import styled from "styled-components"

export const Filter = styled.div`
  display: flex;
  padding: 2rem 0;
  align-items: center;
  justify-content: space-between;
`

export const LeftFilter = styled.div`  
  display: flex;
  align-items: center;
  flex-grow: 1;
  
  & > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  
  
`

export const RightFilter = styled.div``
