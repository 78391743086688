export const extendSearchParams = (url, params) => {
  if (typeof url !== "string")
    throw Error(
      `Invalid url type, must be a string. Receive type ${typeof url}`
    )

  if (typeof params !== "object")
    throw Error(
      `Invalid params type, must be a string. Receive type ${typeof params}`
    )

  const u = new URL(url)

  const newParams = new URLSearchParams([
    ...Array.from(new URLSearchParams(u.search).entries()),
    ...Object.entries(params),
  ]).toString()

  u.search = newParams

  return u.toString()
}

export const getSearchParam = (key) => {
  const params = new URLSearchParams(window.location.search)

  return params.get(key)
}

export const clearSearchParam = (keys) => {
  const u = new URL(window.location)

  const params = new URLSearchParams(u.search)

  keys.forEach((key) => {
    params.delete(key)
  })

  u.search = params.toString()

  window.history.pushState("", "", u.toString())
}

export const makeUrl = ({ host, path, params }) => {
  const currentPath = path || "/"
  const currentParams = params || {}
  const originHost = host || window.location.origin

  const currentSearchParams = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  )

  return extendSearchParams(`${originHost}${currentPath}`, {
    ...currentSearchParams,
    ...currentParams,
  })
}
