import React from "react"
import styled from "styled-components"
import { Link } from "@material-ui/core"

export const StyledLink = styled(Link)`
  margin: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.primary};

  &:hover {
    text-decoration: underline;
  }
`

export const LinkExt = React.forwardRef((props, ref) => {
  return <StyledLink {...props} ref={ref} href={props.to} />
})
