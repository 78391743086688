import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  border-radius: 1rem;
  box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);
  width: 1000px;
  text-align: center;
`

export const Title = styled.div`
  font-size: 1.7rem;
`

export const Leftside = styled.div`
  width: 50%;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 1rem 0 0 1rem;
`

export const Rightside = styled.div`
  width: 50%;
  color: #fff;
  padding: 2rem;
  border-radius: 0 1rem 1rem 0;

  background-color: #00ba88;

  svg {
    fill: #fff;
  }
`

export const Form = styled.form`
  margin-bottom: 1rem;
`

export const SubTitle = styled.div`
  padding: 1rem 0;
  font-size: 0.9rem;
  color: #adadad;
`

export const Text = styled.div`
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: #adadad;
`

export const BenefitsTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
`

export const BenefitsSubtitle = styled.div`
  margin-bottom: 1rem;
`
