import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { FORM_ERROR } from "final-form"

// import { appHistory } from "infrastructure/application/history"
// import { nameToKey } from "infrastructure/utils"
//
// import { projectUpdate } from "data/entities/projects/thunks"
// import { organizationSelectors } from "data/entities/organization/selectors"
//
// import { paths } from "view/router/paths"
// import { organizationUpdate } from "data/entities/organization/thunks"
import { apiService } from "infrastructure/services/ApiService"
import { userGetByUid } from "data/entities/user/thunks"
import { identitySelectors } from "data/entities/identity/selectors"
import { useNotice } from "view/hooks/application/useNotice"
import { projectSelectors } from "data/entities/project/selectors"
import { projectThunks } from "data/entities/project/thunks"
import { validate } from "./validate"

export const Container = ({ component: Component }) => {
  const dispatch = useDispatch()

  const { successNotice, errorNotice } = useNotice()

  const identity = useSelector(identitySelectors.current)
  const project = useSelector(projectSelectors.currentFull)

  const initialValues = {
    name: project?.name || "",
    url: project?.url || "https://",
  }

  const handlerSubmit = useCallback(
    async (values, { reset }) => {
      try {
        const result = await dispatch(
          projectThunks.update({ id: project?._id, values })
        )

        if (result.type === projectThunks.update.fulfilled().type) {
          setTimeout(reset, 0)
          // componentProps.cbCancel()
          successNotice("Settings updated!")
          return {}
        }

        // const result = await apiService.put(`/projects/${project?._id}`, values)

        // if (result.status === 200) {
        //   dispatch(userGetByUid(identity.fbUid))
        //
        //   successNotice("Settings updated")
        //
        //   // gaService.sendEvent("event", "conversion", {
        //   //   send_to: "AW-389992691/8OPICP7gspMCEPOh-7kB",
        //   // })
        //   //
        //   // window.ym(75137779, "reachGoal", "signup_2")
        // }
        // return {}

        if (result.type === projectThunks.update.rejected().type) {
          const { response } = result.payload

          if (response?.status === 400) {
            if (
              response.data.code === "not-unique" &&
              response.data.param === "slug"
            ) {
              return {
                name: "Name already used",
              }
            }
          }

          if (
            response?.status === 403 &&
            response.data.code === "account-is-not-active"
          ) {
            return {
              [FORM_ERROR]: "Subscription invalid upgrade your plan!",
            }
          }
        }

        return {
          [FORM_ERROR]: "Server error",
        }
      } catch (e) {
        return {
          [FORM_ERROR]: "Internal error",
        }
      }
    },
    [dispatch, identity, project]
  )
  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} />}
    />
  )
}
