import React from "react"
import { useDispatch } from "react-redux"

import { MenuItem, Popover } from "@material-ui/core"
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"

import { useOpenable } from "view/hooks/application/useOpenable"
import { Button } from "view/uikit/base/Button"
import * as S from "./styled"

export const GridFilter = ({ name, label, value, options, setFilter }) => {
  const dispatch = useDispatch()
  const { open, onOpen, onClose, anchorEl } = useOpenable(false)

  const handleChange = async (newValue) => {
    await dispatch(
      setFilter({
        [name]: newValue,
      })
    )
    onClose()
  }

  return (
    <>
      <Button
        color="default"
        variant="text"
        onClick={onOpen}
        endIcon={<KeyboardArrowDownOutlinedIcon />}
      >
        {label}: <S.Value>{value?.label || ""}</S.Value>
      </Button>
      <Popover
        id={`grid-filter-${name}`}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((o) => (
          <MenuItem key={o.value} onClick={() => handleChange(o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
