import React from "react"
import MuiSvgIcon from "@material-ui/core/SvgIcon"
import styled from "styled-components"

export const Icon = styled(MuiSvgIcon)`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`

export const SvgIcon = ({
  children,
  component,
  width = "24",
  height = "24",
  viewBox = `0 0 ${width} ${height}`,
  ...rest
}) => {
  return (
    <Icon
      component={component}
      width={width}
      height={height}
      viewBox={viewBox}
      {...rest}
    />
  )
}
