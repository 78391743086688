import React from "react"
import { useSelector } from "react-redux"
import { useContextSelector } from "use-context-selector"

import {
  getDateEndOfMonth,
  getDateStartOfMonth,
} from "infrastructure/utils/date"
import { useQuery } from "infrastructure/_hooks/useQuery"

import { projectSelectors } from "data/entities/project/selectors"

import { GuideRefetchContainer } from "domain/guide/GuideRefetchContainer"
import { ThemeRefetchContainer } from "domain/theme/ThemeRefetchContainer"
import { billingSelectors } from "domain/billing/billingSelectors"
import { BillingContext } from "domain/billing/BillingContext"
import { AccountTypeEnum } from "domain/billing/enums"
import { guideSelectors } from "data/entities/guide/selectors"
import { themeSelectors } from "data/entities/theme/selectors"

import { formatLimit, numFormat } from "view/helpers"
import { StripeManageButtons } from "view/pages/billing/BillingPage/CurrentPlan/StripeManageButtons"

import * as S from "./styled"

export const CurrentPlan = React.memo(() => {
  const pid = useSelector(projectSelectors.current)

  const plan = useContextSelector(BillingContext, billingSelectors.currenPlan)
  const billingAccount = useContextSelector(
    BillingContext,
    billingSelectors.billingAccount
  )

  const guideIds = useSelector(guideSelectors.selectIds)
  const themeIds = useSelector(themeSelectors.selectIds)

  const params = {
    pid,
    dateStart: getDateStartOfMonth(),
    dateEnd: getDateEndOfMonth(),
  }

  const { data } = useQuery("/analytics/summary", params)

  if (!plan) return null

  return (
    <S.Wrapper>
      {!guideIds.length && <GuideRefetchContainer />}
      {!themeIds.length && <ThemeRefetchContainer />}

      <S.Box>
        <S.Name>
          Your current plan — <span>{plan.name}</span>
        </S.Name>

        <S.Block>
          <S.Card>
            <S.Title>MAU</S.Title>
            <S.Value>
              {numFormat(data?.mau)}{" "}
              <span>/ {formatLimit(plan.limit.mau)}</span>
            </S.Value>
          </S.Card>

          <S.Card>
            <S.Title>Projects</S.Title>
            <S.Value>
              0 <span>/ {formatLimit(plan.limit.projects)}</span>
            </S.Value>
          </S.Card>

          <S.Card>
            <S.Title>Themes</S.Title>
            <S.Value>
              {themeIds.length} <span>/ {formatLimit(plan.limit.themes)}</span>
            </S.Value>
          </S.Card>

          <S.Card>
            <S.Title>Remove branding</S.Title>
            <S.Value>{formatLimit(plan.limit.removeBranding)}</S.Value>
          </S.Card>
        </S.Block>

        {billingAccount.type === AccountTypeEnum.Subscription && (
          <StripeManageButtons />
        )}
      </S.Box>
    </S.Wrapper>
  )
})
