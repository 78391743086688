import { useCallback, useEffect, useMemo, useState } from "react"
import { apiService } from "infrastructure/services/ApiService"

export const useQuery = (url, params = null, options = {}) => {
  const { debug, ignoreRequest } = options

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const queryParams = useMemo(() => {
    if (!params) return ""

    let emptyCount = 0
    const keys = Object.keys(params)

    keys.forEach((key) => {
      if (params[key] === undefined) {
        emptyCount += 1
        delete params[key]
      }
    })

    // если есть пустые параметры не отправляем
    if (emptyCount) return ""

    return new URLSearchParams(params).toString()
  }, [params])

  const request = useCallback(async () => {
    try {
      // eslint-disable-next-line no-console
      if (debug) console.log("REQUEST", queryParams)

      let baseUrl = `${url}`
      if (queryParams) baseUrl += `?${queryParams}`

      setLoading(true)
      const r = await apiService.get(baseUrl)
      // eslint-disable-next-line no-console
      if (debug) console.log("r", r)
      setData(r.data)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [url, params])

  useEffect(() => {
    if (ignoreRequest) return

    // если запрос вообще без параметров, то запускаем
    // если запрос с параметрами, но они пустые, не запускаем
    if (!params) request()
    if (params !== null && queryParams) request()
  }, [url, queryParams, ignoreRequest])

  // eslint-disable-next-line no-console
  if (debug) console.log("data", data)

  return { isLoading, data, error }
}
