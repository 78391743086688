import React from "react"

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.log("ERROR", error)
    return { hasError: true }
  }

  componentDidUpdate(prevProps, prevState) {
    const { children } = this.props

    if (prevProps.children !== children) {
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log("ERROR", error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return children
  }
}
