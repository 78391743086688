import React from "react"

import { InviteSendModal } from "view/modals/user/InviteSendModal"
import { UpgradeModal } from "view/modals/application/UpgradeModal"

export const RootModal = () => {
  return (
    <>
      <InviteSendModal />
      <UpgradeModal />
    </>
  )
}
