import { fontFamily } from "view/themes/overrideMui/fontFamily"

export const MuiButton = (theme) => ({
  root: {
    fontFamily,
    // padding: "0.5rem 1rem",
    padding: "0.5rem 2.5rem",
    borderRadius: "0.25rem",
    textTransform: "none",
  },
  textPrimary: {
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  contained: {
    boxShadow: "none",
  },
  containedPrimary: {
    color: theme.button.color,
    backgroundColor: theme.button.bg,
    border: `1px solid ${theme.button.bg}`,
    // backgroundImage:
    //   "linear-gradient(140deg, rgba(45,129,185,1) 0%, rgba(116,101,217,1) 72%, rgba(82,61,219,1) 100%)",
    "&.Mui-disabled": {
      color: theme.button.contained.disabled.color,
      border: `1px solid ${theme.button.contained.disabled.color}`,
      backgroundImage: "none",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  sizeSmall: {
    fontWeight: 400,
  },
})
