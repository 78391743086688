import styled from "styled-components"

export const Wrapper = styled.div`
  & > div:first-child {
    margin-right: 1rem;
  }
`

export const PickerButtons = styled.div`
  text-align: right;
  padding: 0.5rem 1rem;

  & > button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`
