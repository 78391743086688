import { ThunksFabric } from "data/entities/ThunksFabric"
import { invoiceSelectors } from "data/entities/invoice/selectors"
import { ParentEnum } from "data/entities/enums"

import { entityName } from "../consts"

export const invoiceThunks = new ThunksFabric({
  entityName: entityName.invoices,
  selectors: invoiceSelectors,
  parent: ParentEnum.Organization,
}).build()
