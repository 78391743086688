import styled from "styled-components"
import { boxShadow } from "view/themes/mixins"
import { palette } from "view/themes/dark/palette"

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  background: #fff;
  padding: 1rem;

  //border-top-left-radius: 8px;
  //border-top-right-radius: 8px;

  border-radius: 8px;

  & > h2 {
    font-weight: normal;
    font-size: 1.1rem;
    margin: 0;
  }
`

export const Icon = styled.div`
  display: flex;

  svg {
    fill: ${palette.gray};
  }
`

export const Content = styled.div`
  padding: 1.5rem;
  padding-top: 0.25rem;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > h2 {
    font-weight: normal;
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  & h4 {
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 1rem;
  }
`

export const Wrapper = styled.div`
  // margin: ${({ open }) => (open ? "1rem 0" : 0)};
  margin-bottom: 1rem;
  border-radius: 8px;
  ${boxShadow}

  ${Title} {
    border-bottom-left-radius: ${({ open }) => (open ? 0 : "8px")}
    border-bottom-right-radius: ${({ open }) => (open ? 0 : "8px")}
  }

  ${Icon} {
    svg {
      transform: rotate(${({ open }) => (open ? "180deg" : 0)});
      transition: transform 200ms;
    }
  }
`
