import React from "react"

import { GridSort } from "../GridSort"
import { GridSearch } from "../GridSearch"
import * as S from "./styled"

export const GridHeader = ({
  disabled,
  actions,
  selectors,
  hideSearch,
  filter = null,
  sortOptions,
}) => {
  return (
    <S.Wrapper>
      {!hideSearch && (
        <S.Search>
          <GridSearch actions={actions} />
        </S.Search>
      )}
      <S.Filter>{filter}</S.Filter>
      <S.Sort>
        <GridSort
          sortOptions={sortOptions}
          selectors={selectors}
          setSort={actions.setSort}
          disabled={disabled}
        />
      </S.Sort>
    </S.Wrapper>
  )
}
