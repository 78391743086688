import { adapter } from "data/entities/guide/selectors"

export class ReducersFabric {
  actions

  builder

  initState

  thunks

  constructor({ builder, actions, thunks, initState }) {
    this.actions = actions
    this.builder = builder
    this.initState = initState
    this.thunks = thunks
  }

  build() {
    this.builder.addCase(this.actions.setLimit, (state, action) => {
      state.pagging = {
        ...state.pagging,
        limit: action.payload,
        page: 1,
      }
    })

    this.builder.addCase(this.actions.setPage, (state, action) => {
      state.pagging = {
        ...state.pagging,
        page: action.payload,
      }
    })

    this.builder.addCase(this.actions.setFilter, (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    })

    this.builder.addCase(this.actions.clearFilter, (state, action) => {
      state.filter = this.initState.filter
    })

    this.builder.addCase(this.actions.setSort, (state, action) => {
      state.sort = action.payload
    })

    // ----

    this.builder.addCase(this.thunks.fetchList.pending, (state) => {
      state.isLoading = true
    })

    this.builder.addCase(this.thunks.fetchList.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload.items)

      state.isLoading = false
      state.pagging = {
        limit: action.payload.limit,
        total: action.payload.total,
        page: action.payload.page,
      }
    })

    this.builder.addCase(this.thunks.fetchList.rejected, (state) => {
      state.isLoading = false
    })

    this.builder.addCase(this.thunks.fetchById.pending, (state) => {
      state.isLoading = true
      state.current = null
    })

    this.builder.addCase(this.thunks.fetchById.fulfilled, (state, action) => {
      // при получении 1 итема, надо чтобы в списке он тоже был, чтобы получить его по ИД в редюсере
      adapter.addOne(state, action.payload)

      state.isLoading = false
      state.current = action.payload._id
    })

    this.builder.addCase(this.thunks.fetchById.rejected, (state) => {
      state.isLoading = false
    })

    this.builder.addCase(this.thunks.create.fulfilled, (state, action) => {
      adapter.addOne(state, action.payload)
      // пока убрал, при создании нового проекта, сбрасывается, если ставить заного то моргает все
      // state.current = null ???
    })

    this.builder.addCase(this.thunks.update.fulfilled, (state, action) => {
      const { _id, ...rest } = action.payload
      adapter.updateOne(state, {
        id: _id,
        changes: rest,
      })
    })

    // this.builder.addCase(this.thunks.clone.fulfilled, (state, action) => {
    //   adapter.addOne(state, action.payload)
    // })

    this.builder.addCase(this.thunks.delete.fulfilled, (state, action) => {
      adapter.removeOne(state, action.payload.id)
    })
  }
}
