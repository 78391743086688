import React from "react"

import { MenuItem, Popover } from "@material-ui/core"
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"

import { useOpenable } from "view/hooks/application/useOpenable"
import { Button } from "view/uikit/base/Button"
import * as S from "./styled"

export const Select = ({
  name,
  label,
  value,
  options,
  onChange,
  disabled,
  variant = "text",
}) => {
  const { open, onOpen, onClose, anchorEl } = useOpenable(false)

  const handleChange = async (newValue) => {
    onChange(newValue)
    onClose()
  }

  const labelDisplay = label ? `${label}:` : ""
  const currentOption = options.find((o) => o.value === value)

  return (
    <>
      <Button
        color="default"
        variant={variant}
        disabled={disabled}
        onClick={onOpen}
        endIcon={<KeyboardArrowDownOutlinedIcon />}
      >
        {labelDisplay} <S.Value>{currentOption?.label || ""}</S.Value>
      </Button>
      <Popover
        id={`select-${name}`}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((o) => (
          <MenuItem key={o.value} onClick={() => handleChange(o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
