import styled from "styled-components"
import { palette } from "view/themes/palette"

export const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f64900;
  color: ${palette.white};
  text-align: center;

  a {
    text-decoration: underline;
    color: ${palette.white};

    &:hover {
      text-decoration: none;
    }
  }
`
