export const entityName = {
  application: "application",
  billing: "billing",
  blocks: "blocks",
  guides: "guides",
  hotspots: "hotspots",
  hotspotGroups: "hotspotGroups",
  identity: "identity",
  invite: "invite",
  invoices: "invoices",
  modal: "modal",
  organizations: "organizations",
  projects: "projects",
  segments: "segments",
  steps: "steps",
  themes: "themes",
  transactions: "transactions",
  user: "user",
}
