import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { invoiceThunks } from "data/entities/invoice/thunks"
import { invoiceSelectors } from "data/entities/invoice/selectors"

import { organizationSelectors } from "data/entities/organization/selectors"

import { useNotice } from "view/hooks/application/useNotice"

export const InvoicesRefetchContainer = () => {
  const dispatch = useDispatch()
  const { errorNotice } = useNotice()

  const organization = useSelector(organizationSelectors.current)
  const sort = useSelector(invoiceSelectors.sort)
  const filter = useSelector(invoiceSelectors.filter)
  const pagging = useSelector(invoiceSelectors.pagging)

  useEffect(() => {
    const promise = dispatch(invoiceThunks.fetchList())

    ;(async () => {
      const result = await promise

      if (result.type === invoiceThunks.fetchList.rejected().type) {
        if (result.error.message !== "Aborted") {
          errorNotice("Server error!")
        }
      }
    })()

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    organization?._id,
    sort,
    filter.status,
    filter.search,
    pagging.limit,
    pagging.page,
  ])

  return null
}
