import React, { memo, useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { Button } from "@material-ui/core"

import { errorService } from "infrastructure/services/ErrorService"

import { billingSelectors } from "domain/billing/billingSelectors"
import { BillingContext } from "domain/billing/BillingContext"
import { StripeService } from "domain/billing/services/StripeService"

import { ModalEnum } from "data/entities/modal"

import { useModal } from "view/modals/useModal"
import { ConfirmModal } from "view/modals/application/ConfirmModal"
import { dateFormat } from "view/helpers"

import * as S from "./styled"

export const StripeManageButtons = memo(() => {
  const subscription = useContextSelector(
    BillingContext,
    billingSelectors.subscription
  )
  const totalDisabled = useContextSelector(
    BillingContext,
    billingSelectors.totalDisabled
  )
  const updateBilling = useContextSelector(
    BillingContext,
    billingSelectors.update
  )

  const { onOpen: openConfirm } = useModal(ModalEnum.ConfirmCancelSubscription)

  const handlerCancel = useCallback(async () => {
    updateBilling({
      totalDisabled: true,
    })

    try {
      const result = await StripeService.cancelSubscription()

      updateBilling({
        totalDisabled: false,
        billingAccount: result.billingAccount,
        subscription: result.subscription,
      })
    } catch (e) {
      errorService.send(e)
      updateBilling({
        totalDisabled: false,
      })
    }
  }, [updateBilling])

  if (!subscription) return null

  return (
    <S.Wrapper>
      <S.NextBill>
        The next {subscription.nextPayment.amount / 100}{" "}
        {subscription.nextPayment.currency} will be charged on{" "}
        {dateFormat(subscription.nextBillDate)}
      </S.NextBill>

      <>
        <S.Buttons>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={openConfirm}
            disabled={totalDisabled}
          >
            Cancel subscription
          </Button>
        </S.Buttons>

        <ConfirmModal
          type={ModalEnum.ConfirmCancelSubscription}
          title="We're sorry to see you go"
          text={<>Are you sure you want to cancel your subscription?</>}
          onAccept={handlerCancel}
          labelAccept="Cancel subscription"
          labelCancel="Keep subscription"
        />
      </>
    </S.Wrapper>
  )
})
