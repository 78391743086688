import { createSlice } from "@reduxjs/toolkit"
import { entityName } from "data/entities/consts"

export const ModalEnum = {
  Confirm: "Confirm",
  ConfirmCancelSubscription: "ConfirmCancelSubscription",
  ConfirmUpgradeSubscription: "ConfirmUpgradeSubscription",
  FlowCreate: "FlowCreate",
  InviteSend: "InviteSend",
  Upgrade: "Upgrade",
  ExtensionInstall: "ExtensionInstall",
}

const initialState = {
  name: null,
  props: null,
}

export const modalSlice = createSlice({
  name: entityName.modal,
  initialState,
  reducers: {
    openModal: {
      reducer: (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      },
      prepare: (name, props = null) => {
        return { payload: { name, props } }
      },
    },
    closeModal: () => initialState,
  },
  extraReducers: {},
})

export const { openModal, closeModal } = modalSlice.actions
