import isEmail from "validator/es/lib/isEmail"
import isURL from "validator/es/lib/isURL"

export const validate = ({ email, name, projectUrl }) => {
  const errors = {}

  if (!name) {
    errors.name = "Name required!"
  }

  if (!email) {
    errors.email = "Email required!"
  } else if (!isEmail(email)) {
    errors.email = "This email is invalid!"
  }

  if (!projectUrl) {
    errors.organization = "Organization required!"
  }

  if (!projectUrl) {
    errors.projectUrl = "Required"
  } else {
    if (
      !(projectUrl.startsWith("http://") || projectUrl.startsWith("https://"))
    )
      errors.projectUrl =
        "Please use a valid Url starting with http:// or https://"

    if (
      !isURL(projectUrl, {
        protocols: ["http", "https"],
      })
    )
      errors.projectUrl = "Please enter a valid URL"
  }

  return errors
}
