import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

const initState = {
  billingAccount: {},
  subscription: null,
  planList: {
    data: [],
    isLoading: false,
    error: null,
  },
  invoices: {
    data: [],
    isLoading: false,
    error: null,
  },
  totalDisabled: false,
}

export const BillingContext = createContext(initState)
export const BillingProvider = createProvider(BillingContext, initState)
