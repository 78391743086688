import { apiService } from "infrastructure/services/ApiService"
import { ServerError } from "infrastructure/application/errors"

export const userRepository = {
  getById: async (id) => {
    try {
      const result = await apiService.get(`/user-by-uid/?fbUid=${id}`)

      return result.data
    } catch (e) {
      if (e.response?.status === 404) return null
      throw new ServerError(e)
    }
  },

  createByUid: async (userData) => {
    try {
      const result = await apiService.post(`/user-by-uid`, {
        ...userData,
      })

      return result.data
    } catch (e) {
      throw new Error(e)
    }
  },

  clientStateSet: async (key, value) => {
    try {
      return await apiService.post(`/client-states`, {
        key,
        value,
      })
    } catch (e) {
      throw new Error(e)
    }
  },
}
