import React, { useMemo, useState } from "react"
import { numFormat, percentFormat } from "view/helpers"
import { Box } from "view/uikit/base/Box"
import { ChartLine } from "view/uikit/common/ChartLine"
import { useQuery } from "infrastructure/_hooks/useQuery"
import {
  getIntervalPeriod,
  getPrevPeriod,
  getRange,
} from "infrastructure/utils/date"
import { palette } from "view/themes/palette"
import { DemoDataBuilder } from "domain/analytics/DemoDataBuilder"
import { useSelector } from "react-redux"
import { projectSelectors } from "data/entities/project/selectors"
import { getDiffPercent } from "domain/analytics/utils"
import * as S from "./styled"

export const Chart = ({ gid, interval, dateRange, showDemoData }) => {
  const pid = useSelector(projectSelectors.current)

  const [showMau, setShowMau] = useState(false)
  const [showRate, setShowRate] = useState(true)
  const [showStarted, setShowStarted] = useState(true)
  const [showCompleted, setShowCompleted] = useState(true)
  const [showDismissed, setShowDismissed] = useState(false)

  const dataParams = useMemo(() => {
    const p = {
      interval,
      dateStart: dateRange[0],
      dateEnd: dateRange[1],
      pid,
    }

    if (gid !== "all") p.gid = gid

    return p
  }, [gid, interval, dateRange, pid])

  const { data } = useQuery("/analytics/chart-data", dataParams, {
    ignoreRequest: showDemoData,
  })

  const labels = useMemo(
    () => getRange(dateRange, getIntervalPeriod(interval)),
    [dateRange, interval]
  )

  const demoData = useMemo(() => {
    if (!showDemoData) return {}

    return DemoDataBuilder.getGuideChartData(labels)
  }, [labels, showDemoData])

  const chartData = showDemoData ? demoData[0] : data

  const summaryParams = useMemo(() => {
    const p = {
      dateStart: dateRange[0],
      dateEnd: dateRange[1],
      pid,
    }

    if (gid !== "all") p.gid = gid

    return p
  }, [gid, interval, dateRange, pid])

  const summaryRes = useQuery("/analytics/summary", summaryParams, {
    ignoreRequest: showDemoData,
  })

  const prevDateRange = useMemo(() => getPrevPeriod(dateRange), [dateRange])

  const summaryPrevParams = useMemo(() => {
    const p = {
      dateStart: prevDateRange[0],
      dateEnd: prevDateRange[1],
      pid,
    }

    if (gid !== "all") p.gid = gid

    return p
  }, [gid, interval, dateRange, pid])

  const summaryPrevRes = useQuery("/analytics/summary", summaryPrevParams, {
    ignoreRequest: showDemoData,
  })

  const summary = showDemoData ? demoData[1] : summaryRes.data
  const summaryPrev = showDemoData ? demoData[2] : summaryPrevRes.data

  const dataset = useMemo(() => {
    const res = {
      startedData: [],
      completedData: [],
      dismisedData: [],
      completionRate: [],
      mauData: [],
    }
    if (!chartData) return res

    Object.keys(chartData).forEach((key) => {
      const x = new Date(key)

      res.startedData.push({
        x,
        y: chartData[key].started,
      })

      res.completedData.push({
        x,
        y: chartData[key].completed,
      })

      res.dismisedData.push({
        x,
        y: chartData[key].dismissed,
      })

      res.mauData.push({
        x,
        y: chartData[key].mau,
      })

      res.completionRate.push({
        x,
        y: ((chartData[key].completed / chartData[key].started) * 100).toFixed(
          1
        ),
      })
    })
    return res
  }, [chartData])

  const datasetCompletionRate = {
    label: "Completion Rate",
    data: dataset.completionRate,
    borderColor: palette.chart3_border,
    backgroundColor: palette.chart3,
    borderWidth: 3,
    radius: 0,
    tension: 0.3,
  }

  const datasetMau = {
    label: "Unique users",
    data: dataset.mauData,
    borderColor: palette.chart3_border,
    backgroundColor: palette.chart3,
    borderWidth: 3,
    radius: 0,
    type: "bar",
  }

  // const datasetDismissed = {
  //   label: "Guides Dismissed",
  //   data: dataset.dismisedData,
  //   borderColor: palette.chart3_border,
  //   backgroundColor: palette.chart3,
  //   borderWidth: 1,
  //   radius: 0,
  //   fill: true,
  //   type: "bar",
  // }

  const datasetCompleted = {
    label: "Guides Completed",
    data: dataset.completedData,
    borderColor: palette.chart1_border,
    backgroundColor: palette.chart1,
    borderWidth: 1,
    radius: 0,
    fill: true,
    stack: "Stack 0",
    type: "bar",
  }

  const datasetStarted = {
    label: "Guides Started",
    data: dataset.startedData,
    borderColor: palette.chart3_border,
    backgroundColor: palette.chart3,
    borderWidth: 3,
    radius: 0,
    fill: true,
    stack: "Stack 0",
    type: "bar",
  }

  const rate = useMemo(() => {
    if (!summary?.started) return 0

    return (summary?.completed / summary?.started) * 100
  }, [summary])

  const ratePrev = useMemo(() => {
    if (!summaryPrev?.started) return 0

    return (summaryPrev?.completed / summaryPrev?.started) * 100
  }, [summaryPrev])

  const diffRate = useMemo(
    () => getDiffPercent(ratePrev, rate),
    [rate, ratePrev]
  )

  const diffMau = useMemo(
    () => getDiffPercent(summaryPrev?.mau, summary?.mau),
    [summaryPrev?.mau, summary?.mau]
  )

  const diffStarted = useMemo(
    () => getDiffPercent(summaryPrev?.started, summary?.started),
    [summaryPrev?.started, summary?.started]
  )

  return (
    <S.Wrapper>
      <S.Row>
        <S.Cell>
          <Box padding={1}>
            <S.ChartHeader>
              <S.ChartTitle>Started & Completed Product Tours</S.ChartTitle>
              <S.Values>
                <div>
                  <S.TotalPeriod>{numFormat(summary?.started)}</S.TotalPeriod>
                  <S.PrevPeriod>
                    from {numFormat(summaryPrev?.started)}
                  </S.PrevPeriod>
                </div>
                <S.Diff diff={diffStarted}>{percentFormat(diffStarted)}</S.Diff>
              </S.Values>
            </S.ChartHeader>
            <S.ChartBody>
              <ChartLine
                interval={interval}
                data={{
                  labels,
                  datasets: [datasetCompleted, datasetStarted],
                }}
              />
            </S.ChartBody>
          </Box>
        </S.Cell>
        <S.Cell>
          <Box padding={1}>
            <S.ChartHeader>
              <S.ChartTitle>Completion Rate</S.ChartTitle>
              <S.Values>
                <div>
                  <S.TotalPeriod>{percentFormat(rate)}</S.TotalPeriod>
                  <S.PrevPeriod>from {percentFormat(ratePrev)}</S.PrevPeriod>
                </div>
                <S.Diff diff={diffRate}>{percentFormat(diffRate)}</S.Diff>
              </S.Values>
            </S.ChartHeader>
            <S.ChartBody>
              <ChartLine
                interval={interval}
                data={{
                  labels,
                  datasets: [datasetCompletionRate],
                }}
                options={{
                  scales: {
                    y: {
                      type: "linear",
                      display: true,
                      position: "left",
                      min: 0,
                      max: 100,

                      // // grid line settings
                      // grid: {
                      //   drawOnChartArea: false, // only want the grid lines for one axis to show up
                      // },
                    },
                  },
                }}
              />
            </S.ChartBody>
          </Box>
        </S.Cell>

        <S.Cell>
          <Box padding={1}>
            <S.ChartHeader>
              <S.ChartTitle>Monthly Active Users</S.ChartTitle>
              <S.Values>
                <div>
                  <S.TotalPeriod>{numFormat(summary?.mau)}</S.TotalPeriod>
                  <S.PrevPeriod>
                    from {numFormat(summaryPrev?.mau)}
                  </S.PrevPeriod>
                </div>
                <S.Diff diff={diffMau}>{percentFormat(diffMau)}</S.Diff>
              </S.Values>
            </S.ChartHeader>
            <S.ChartBody>
              <ChartLine
                interval={interval}
                data={{
                  labels,
                  datasets: [datasetMau],
                }}
              />
            </S.ChartBody>
          </Box>
        </S.Cell>
      </S.Row>
      {/* <S.Row> */}
      {/*  <S.Cell> */}
      {/*    <Box padding={1}> */}
      {/*      <S.ChartHeader> */}
      {/*        <S.ChartTitle>Dismissed Guides</S.ChartTitle> */}
      {/*        <S.TotalPeriod>{numFormat(summary?.dismissed)}</S.TotalPeriod> */}
      {/*      </S.ChartHeader> */}
      {/*      <S.ChartBody> */}
      {/*        <ChartLine */}
      {/*          interval={interval} */}
      {/*          data={{ */}
      {/*            labels, */}
      {/*            datasets: [datasetDismissed], */}
      {/*          }} */}
      {/*        /> */}
      {/*      </S.ChartBody> */}
      {/*    </Box> */}
      {/*  </S.Cell> */}
      {/*  <S.Cell> */}
      {/*    <Box padding={1}> */}
      {/*      <S.ChartHeader> */}
      {/*        <S.ChartTitle>Monthly Active Users</S.ChartTitle> */}
      {/*        <S.TotalPeriod>{numFormat(summary?.mau)}</S.TotalPeriod> */}
      {/*      </S.ChartHeader> */}
      {/*      <S.ChartBody> */}
      {/*        <ChartLine */}
      {/*          interval={interval} */}
      {/*          data={{ */}
      {/*            labels, */}
      {/*            datasets: [datasetMau], */}
      {/*          }} */}
      {/*        /> */}
      {/*      </S.ChartBody> */}
      {/*    </Box> */}
      {/*  </S.Cell> */}
      {/* </S.Row> */}
    </S.Wrapper>
  )
}
